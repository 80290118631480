/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./../home-page.scss";
import "./../standard-page.scss";
import "./kreator-page.scss";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setActivePanel } from "../../../redux/creatorState/creatorState.actions";
import { selectCakeBottom, selectCakeCover, selectCakeCoverDetails, selectCakeHasSmoothTop, selectCakeSide, selectCakeSize, selectCakeTastes, selectCakeTopAddons } from "../../../redux/creatorState/creatorState.selectors";

interface Props {
}

const CreatorSummaryNotFinishedMsg = (props: Props) => {

  const cakeSize = useSelector(selectCakeSize, shallowEqual) as number;
  const cakeBottom = useSelector(selectCakeBottom, shallowEqual) as number;
  const cakeCover = useSelector(selectCakeCover, shallowEqual) as number;
  const cakeCoverDetails = useSelector(selectCakeCoverDetails, shallowEqual) as number;
  const cakeSide = useSelector(selectCakeSide, shallowEqual) as number;
  const cakeHasSmoothTop = useSelector(selectCakeHasSmoothTop, shallowEqual) as boolean;
  const cakeTastes = useSelector(selectCakeTastes, shallowEqual) as Array<number>;
  const cakeTopAddons = useSelector(selectCakeTopAddons, shallowEqual) as Array<number>;

  const dispatch = useDispatch();

  const handleSetActivePanel = (active: number) => {
    dispatch(setActivePanel(active))
  }
  
  return (
    <div className="kreator__container">
      <h4 className="kreator__subTitle">
        Nie skonigurowano wszystkich kroków
    </h4>
      <div className="kreator__subTitle noFinishedInfo">
        Aby ukończyć kreowanie, zdefiniuj jeszcze &nbsp;&nbsp;&nbsp;&nbsp;

      {
          !(cakeSize > 0) ? (
            <div onClick={() => handleSetActivePanel(0)} className={`btn btn-outline-dark`} style={{ marginTop: -5 }}>&nbsp;&lsaquo;&nbsp;&nbsp;&nbsp;Rozmiar&nbsp;&nbsp;</div>
          ) : (
              !(cakeBottom >= 0) ? (
                <div onClick={() => handleSetActivePanel(1)} className={`btn btn-outline-dark`} style={{ marginTop: -5 }}>&nbsp;&lsaquo;&nbsp;&nbsp;&nbsp;Spód&nbsp;&nbsp;</div>
              ) : (
                  !(cakeTastes && cakeTastes.length > 0) ? (
                    <div onClick={() => handleSetActivePanel(2)} className={`btn btn-outline-dark`} style={{ marginTop: -5 }}>&nbsp;&lsaquo;&nbsp;&nbsp;&nbsp;Smaki&nbsp;&nbsp;</div>
                  ) : (
                      !(cakeCover >= 0) ? (
                        <div onClick={() => handleSetActivePanel(3)} className={`btn btn-outline-dark`} style={{ marginTop: -5 }}>&nbsp;&lsaquo;&nbsp;&nbsp;&nbsp;Pokrycie&nbsp;&nbsp;</div>
                      ) : (
                          !(cakeCoverDetails >= 0) ? (
                            <div onClick={() => handleSetActivePanel(4)} className={`btn btn-outline-dark`} style={{ marginTop: -5 }}>&nbsp;&lsaquo;&nbsp;&nbsp;&nbsp;Szczegóły pokrycia&nbsp;&nbsp;</div>
                          ) : (
                              !(cakeSide >= 0) ? (
                                <div onClick={() => handleSetActivePanel(5)} className={`btn btn-outline-dark`} style={{ marginTop: -5 }}>&nbsp;&lsaquo;&nbsp;&nbsp;&nbsp;boki tortu&nbsp;&nbsp;</div>
                              ) : (
                                  !(cakeTopAddons.length > 0 || cakeHasSmoothTop) ? (
                                    <div onClick={() => handleSetActivePanel(6)} className={`btn btn-outline-dark`} style={{ marginTop: -5 }}>&nbsp;&lsaquo;&nbsp;&nbsp;&nbsp;Wierzch tortu&nbsp;&nbsp;</div>
                                  ) : (
                                      <>WSZYSTKO OK</>
                                    )
                                )
                            )))))
        }
      </div>
    </div>
  )
}

export default CreatorSummaryNotFinishedMsg;