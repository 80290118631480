import React from "react";
import "./cart-steps.scss";
import "./../home-page.scss";
import "./../standard-page.scss";

interface Props {
  activeCartStep: number;
  setActiveCartStepWithValidator: (step: number) => void;
}

const CartSteps: React.FC<Props> = (props: Props) => {
  // const cartItems = useSelector(selectCartItems, shallowEqual) as Array<CartItemDto>
  const { activeCartStep, setActiveCartStepWithValidator } = props;
  return (
    <div className="cart-steps">
      <div className="stepsWrapper">
        <div className={`step${activeCartStep < (0 - 1) ? ' isDisabled' : ''}${activeCartStep > 0 ? ' isFinished' : (activeCartStep === 0 ? ' isActive' : '')}`} onClick={() => setActiveCartStepWithValidator(0)}>
          <div className="num">1</div>
          <div className="title">Produkty
          <div className="underline"></div>
          </div>
        </div>
        <div className="break"></div>
        <div className={`step${activeCartStep < (1 - 1) ? ' isDisabled' : ''}${activeCartStep > 1 ? ' isFinished' : (activeCartStep === 1 ? ' isActive' : '')}`} onClick={() => { setActiveCartStepWithValidator(1) }}>
          <div className="num">2</div>
          <div className="title">
            Odbiór
            <div className="underline"></div>
          </div>
        </div>
        <div className="break"></div>
        <div className={`step${activeCartStep < (2 - 1) ? ' isDisabled' : ''}${activeCartStep > 2 ? ' isFinished' : (activeCartStep === 2 ? ' isActive' : '')}`} onClick={() => setActiveCartStepWithValidator(2)}>
          <div className="num">3</div>
          <div className="title">Dane
          <div className="underline"></div>
          </div>
        </div>
        <div className="break"></div>
        <div className={`step${activeCartStep < (3 - 1) ? ' isDisabled' : ''}${activeCartStep > 3 ? ' isFinished' : (activeCartStep === 3 ? ' isActive' : '')}`} onClick={() => setActiveCartStepWithValidator(3)}>
          <div className="num">4</div>
          <div className="title">Płatność
          <div className="underline"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CartSteps;