/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { } from "react";
import "./home-page.scss";
import Helmet from "react-helmet";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MaleChef from "./assets/male-chef.svg";

import HomeNav from "./home-nav";
import HomeContactSection from "./home-contact-section";
import HomeFooter from "./home-footer";
import SweetDream from "./sweet-dream";
import HomeInsideNav from "./home-inside-nav";

const OfertaPage: React.FC<any> = () => {

  return (
    <div className="home-page">
      <Helmet>
        <title>{'Oferta | TORTY.COM'}</title>
        {/* <meta name="description" content="Blog interaktywny. Praca kreatywna od kuchni, ukazana z różnych perspektyw. Programowanie, Design, Biznes, Seo, Teamwork, Strony internetowe, Aplikacje webowe i wiele innych." />
          <meta name="keywords" content="narzędzia,design,komunikacja,teamwork,responsive" /> */}
      </Helmet>
      <HomeNav />
      <div className="overlayLoader"></div>
      <section className="trustSection">
        <Container>
          <br />
          <br />
          <h1>U nas kreujesz do woli!</h1>
          <p>Upieczemy dla Ciebie dowolny tort, który stworzysz w kreatorze.</p>
          <br />
          <br />
          <div className={"btn btn btn-dark"}>&nbsp;&nbsp;&nbsp;Użyj kreatora!&nbsp;&nbsp;&nbsp;</div>
        </Container>
      </section>

      <section className="togetherSection">
        <Container>
          <Row>
            <Col xl={{ span: 5, offset: 1, order: 1 }} sm={{ order: 1 }} xs={{ order: 2 }} className="col">
              <div className="togetherJumbDescr">
                <h3>Możesz też postawić na coś sprawdzonego!</h3>
                <p>Przez 30 lat zebraliśmy najlepsze przepisy, które umieścilismy dla Ciebie w ofercie standardowej. Realizujemy też torty okolicznościowe i dla firm.</p>
                <p><strong>Zobacz tradycyjną ofertę &#8628;</strong></p>
              </div>
            </Col>
            <Col xl={{ span: 3, offset: 1, order: 2 }} sm={{ order: 2 }} xs={{ order: 1 }} className="col">
              <img src={MaleChef} alt="" />
            </Col>
          </Row>
          <Row>
            <Col xl={{ span: 5, offset: 1 }} className="col arrowRollDown">
              <div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <HomeInsideNav excluded={'kreator'} />
      <SweetDream />
      <HomeContactSection />
      <HomeFooter />
    </div>
  )
}

export default OfertaPage;