import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga';
import rootReducer from './root-reducer';

import { persistStore } from 'redux-persist';
import rootSaga from '../redux/rootSaga'
import urlReduxSyncMiddleware from './middleware/urlReduxSyncMiddleware';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, urlReduxSyncMiddleware];

if (process.env.NODE_ENV === 'development') {
  // middlewares.push(logger);
}

export const store = createStore(rootReducer, applyMiddleware(...middlewares));

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);