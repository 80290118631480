import React, { } from "react";
import "./../cart-page.scss";
import "./../../home-page.scss";
import "./../../standard-page.scss";
import { useSelector, shallowEqual } from "react-redux";
import { CartItemDto, DeliveryPanelDto, ClientDataDto, ReceiverDataDto, BillTypeEnum, ReceiverTypeEnum, DeliveryTypeEnum } from "../../../../dto/cartItemDto";
import { selectCartItems, selectDeliveryPanel, selectClientData, selectReceiverData } from "../../../../redux/cartState/cartState.selectors";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { CartShopsList } from "./cartData/cart.data";
import { CreatorPrices } from "../../creator-page/creator-assets/creatorData/creator.data";

interface Props {
  isValidatorActive: boolean;
}

const CartSummaryPanel: React.FC<Props> = () => {

  const cartItems = useSelector(selectCartItems, shallowEqual) as Array<CartItemDto>;
  const deliveryPanel = useSelector(selectDeliveryPanel, shallowEqual) as DeliveryPanelDto;
  const clientData = useSelector(selectClientData, shallowEqual) as ClientDataDto;
  const receiverData = useSelector(selectReceiverData, shallowEqual) as ReceiverDataDto;
  var dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

  return (
    <div className="summaryPanel">
      {cartItems && cartItems.length > 0 ?
        <>
          <div className="cartItem">
            <div className="cartItem__product">
              <div className="cartItem__product__title">Podsumowanie zamówienia</div>
            </div>
          </div>
          <Row>
            <Col lg={6}>
              <div className="cartItem">
                <div className="cartItem__product">
                  <p><strong>Dane zamawiającego:</strong></p>
                  <hr />
                  <p>{clientData.name + ' ' + clientData.surname}</p>
                  {clientData.billType === BillTypeEnum.CompanyBill ? <p>{clientData.company}, NIP: {clientData.nip}</p> : null}
                  <p>{clientData.streetAndHomeNumber}</p>
                  <p>{clientData.city + ', ' + clientData.postCode}</p>
                  <p>{clientData.phone + ', ' + clientData.email}</p>
                  <p>{clientData.message}</p>
                  <hr />
                  {clientData.billType === BillTypeEnum.CompanyBill ? <p>Typ rachunku: Faktura VAT</p> : <p>Typ rachunku: Paragon fiskalny</p>}
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="cartItem">
                <div className="cartItem__product">
                  <p><strong>Dane odbiorcy:</strong></p>
                  <hr />
                  {receiverData.receiverType === ReceiverTypeEnum.Other ?
                    <>
                      <p>{receiverData.name + ' ' + receiverData.surname}</p>
                      {deliveryPanel.deliveryType === DeliveryTypeEnum.Delivery ?
                        <>
                          <p>{receiverData.streetAndHomeNumber}</p>
                          <p>{receiverData.city + ', ' + receiverData.postCode}</p>
                        </> : null}
                      <p>{receiverData.phone + ', ' + receiverData.email}</p>
                    </> :
                    <p>Takie same jak zamawiającego</p>}
                  <hr />
                  {deliveryPanel.deliveryType === DeliveryTypeEnum.Delivery ?
                    <>
                      <p>Dzień dostawy:&nbsp;
                        {deliveryPanel.deliveryTime ? new Date(deliveryPanel.deliveryTime).toLocaleDateString('pl-PL', dateOptions) : null}</p>
                      <p>Koszt dostawy: 30zł</p>
                    </>
                    :
                    <>
                      <p>Dzień odbioru:&nbsp;
                        {deliveryPanel.deliveryTime ? new Date(deliveryPanel.deliveryTime).toLocaleDateString('pl-PL', dateOptions) : null}</p>
                      <p>Miejsce odbioru: {CartShopsList[deliveryPanel.deliveryShop!].streetAndNumber + ', ' + CartShopsList[deliveryPanel.deliveryShop!].city + " " + CartShopsList[deliveryPanel.deliveryShop!].postCode} </p>
                    </>}
                </div>
              </div>
            </Col>
          </Row>
          <div className="cartItem">
            <div className="cartItem__product" style={{ paddingRight: 8 }}>
              <p><strong>Twoje produkty</strong></p>
              <hr />
              {
                cartItems.map((item: CartItemDto, index: number) => {
                  return (
                    <div className="cartItem__details" key={index}>
                      <div className="cartItem__product">
                        <p>
                          <strong>
                            {item.title}
                          </strong>
                        </p>
                        <div className="cartItem__product__description">
                          {item.description}
                        </div>
                      </div>
                      <div className="cartItem__product" style={{ display: 'flex' }}>
                        {item.creatorResult.figureCandles && item.creatorResult.figureCandles > 0 ?
                          <p>
                            Świeczka cyfra x{item.creatorResult.figureCandles.toString().length} ({[...(item.creatorResult.figureCandles.toString().split(''))].join(', ')}) - {CreatorPrices.figureCandles}zł/szt&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </p>
                          : null}
                        {item.creatorResult.singleCandles && item.creatorResult.singleCandles > 0 ?
                          <p>
                            Świeczka pojedyncza x{item.creatorResult.singleCandles} - {CreatorPrices.singleCandles}zł/szt
                          </p>
                          : null}
                      </div>
                      <div className="cartItem__product" style={{ paddingRight: 8 }}>
                        <p style={{ textAlign: "right" }}><strong>Wartość: {CreatorPrices.sum(item.creatorResult.cakeSize, item.creatorResult.dedication, item.creatorResult.singleCandles, item.creatorResult.figureCandles).toFixed(2)} zł</strong></p>
                      </div>
                      {cartItems.length - 1 === index ? null : <hr />}
                    </div>
                  )
                })
              }
            </div>
          </div>
          <Row>
            <Col lg={12}>
              <div className="cartItem">
                <div className="cartItem__product" style={{ paddingRight: 8 }}>
                  <div className="cartItem__product__title" style={{ textAlign: 'right' }}>W sumie do zapłaty:&nbsp;{
                    ((cartItems.map((item: CartItemDto, index: number) => {
                      return CreatorPrices.sum(item.creatorResult.cakeSize, item.creatorResult.dedication, item.creatorResult.singleCandles, item.creatorResult.figureCandles);
                    })).reduce((a, b) => a + b, 0) + (deliveryPanel.deliveryType === DeliveryTypeEnum.Delivery ? 30 : 0)).toFixed(2)
                  } zł brutto</div>

                </div>
              </div>
            </Col>
          </Row>
        </> : <></>}
    </div>
  )
}

export default CartSummaryPanel;