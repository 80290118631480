/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./../home-page.scss";
import "./../standard-page.scss";
import "./kreator-page.scss";
import CreatorPrevBtn from "./creator-prevBtn";
import CreatorNextBtn from "./creator-nextBtn";

interface Props {
  children: React.ReactNode,
  isAllFinished: boolean,
  addToShoppingCart: () => void
}

const CreatorContainer = (props: Props) => {
  const { children, isAllFinished, addToShoppingCart } = props;
  return (

    <div className="kreator__container">
      <CreatorPrevBtn />
      <div className="kreator__container_subcontainer">
        {children}
      </div>
      <CreatorNextBtn isAllFinished={isAllFinished} addToShoppingCart={addToShoppingCart} offset={0} />
    </div>
  )
}

export default CreatorContainer;