import { CoverOptionDto, CreatorOptionDto, SimpleCreatorOptionDto } from "../../../../../dto/creatorDto";

// import * as bottomJson from "./creator.bottom.json";
import * as sizesJson from "./creator.sizes.json";
import * as bottomJson from "./creator.bottom.json";
import * as tastesJson from "./creator.tastes.json";
import * as coverJson from "./creator.cover.json";
import * as sidesJson from "./creator.sides.json";
import * as topAddonsJson from "./creator.topAddons.json";

// export const CreatorBottomsList = (bottomJson as any).default as Array<SimpleCreatorOptionDto>;
export const CreatorSizesList = (sizesJson as any).default as Array<SimpleCreatorOptionDto>;
export const CreatorBottomsList = (bottomJson as any).default as Array<SimpleCreatorOptionDto>;
export const CreatorTastesList = (tastesJson as any).default as Array<SimpleCreatorOptionDto>;
export const CreatorCoversList = (coverJson as any).default as Array<CoverOptionDto>;
export const CreatorSidesList = (sidesJson as any).default as Array<CreatorOptionDto>;
export const CreatorTopAddonsList = (topAddonsJson as any).default as Array<SimpleCreatorOptionDto>;

export const CreatorPrices = {
  sizePerKg: 80,
  singleCandles: 1.25,
  figureCandles: 9,
  dedication: 9,
  sum: (cakeSize: number, dedication: string, singleCandles: number, figureCandles: number) => {
    return (cakeSize * CreatorPrices.sizePerKg) + (dedication ? CreatorPrices.dedication : 0) + (singleCandles ? singleCandles * CreatorPrices.singleCandles : 0) + (figureCandles ? figureCandles.toString().length * CreatorPrices.figureCandles : 0)
  }
}