const OrdersStateActionTypes = {
  GET_ALL_ORDERS: 'GET_ALL_ORDERS',
  GET_ALL_ORDERS_SUCCESS: 'GET_ALL_ORDERS_SUCCESS',
  UPDATE_ORDER_STATUS: 'UPDATE_ORDER_STATUS',
  UPDATE_ORDER_STATUS_SUCCESS: 'UPDATE_ORDER_STATUS_SUCCESS',
  ORDERS_CLEAR_STATE: 'ORDERS_CLEAR_STATE',
  ORDERS_CLEAR_ORDERS: 'ORDERS_CLEAR_ORDERS',
  SET_ORDERSFILTERS: 'SET_ORDERSFILTERS',
  SET_ORDERSFILTERS_LASTID: 'SET_ORDERSFILTERS_LASTID',
};

export default OrdersStateActionTypes;