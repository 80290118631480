import { createSelector } from 'reselect';
import { JaworscyStore } from '../store.types';

const cartState = (state: JaworscyStore) => state.cartState;

export const selectCartItems = createSelector(
  [cartState],
  cartState => {
    return cartState.cartItems
  }
);

export const selectDeliveryPanel = createSelector(
  [cartState],
  cartState => {
    return cartState.deliveryPanel
  }
);

export const selectReceiverData = createSelector(
  [cartState],
  cartState => {
    return cartState.dataPanel.receiverData
  }
);

export const selectClientData = createSelector(
  [cartState],
  cartState => {
    return cartState.dataPanel.clientData
  }
);