import React, { useState, useEffect } from "react";
import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import "./sign-upin-form.scss";
import { signIn } from "../../redux/user/user.actions";
import { SignInDto } from "../../dto/sign-in-dto";
import Button from "react-bootstrap/Button";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import Form from "react-bootstrap/Form";
import { selectIsSignIn_Finished } from "../../redux/callbacksState/callbacksState.selectors";

const SignInForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [disableButton, setdisableButton] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser, shallowEqual);
  const isSignInFinished = useSelector(selectIsSignIn_Finished, shallowEqual);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setdisableButton(true);
    dispatch(signIn({ email: email, password: password } as SignInDto));
  };

  useEffect(() => {
    if (isSignInFinished === true) {
      setdisableButton(false);
    }
  }, [isSignInFinished])

  return (
    <form className="sign-upin-form sign-up-email-form" onSubmit={handleSubmit} >
      <Form.Label>Email</Form.Label>
      <Form.Control
        className="form-control-alternative"
        placeholder="Email"
        name="email"
        type="email"
        value={email}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setEmail(e.target.value)}
        required
      />
      <Form.Label>Hasło</Form.Label>
      <Form.Control
        className="form-control-alternative"
        name="password"
        type="password"
        placeholder="Hasło"
        value={password}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setPassword(e.target.value)}
        required
      />
      <div className="btnWrapper">
        <Button type="submit" variant="secondary" disabled={((user && user.token) || disableButton) as boolean}>Zaloguj</Button>
      </div>
    </form >
  )
}

export default SignInForm