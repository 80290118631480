import CreatorStateTypes from "./creatorState.types";

export const setCakeId = (cakeId: string | undefined) => {
  return {
    type: CreatorStateTypes.SET_CAKEID,
    payload: cakeId
  };
};

export const setActivePanel = (activePanel: number | undefined) => {
  return {
    type: CreatorStateTypes.SET_ACTIVEPANEL,
    payload: activePanel
  };
};

export const setCakeSize = (size: number | undefined) => {
  return {
    type: CreatorStateTypes.SET_CAKESIZE,
    payload: size
  };
};

export const setCakeBottom = (bottom: number | undefined) => {
  return {
    type: CreatorStateTypes.SET_CAKEBOTTOM,
    payload: bottom
  };
};

export const setCakeCover = (cover: number | undefined) => {
  return {
    type: CreatorStateTypes.SET_CAKECOVER,
    payload: cover
  };
};

export const setCakeCoverDetails = (coverDetails: number | undefined) => {
  return {
    type: CreatorStateTypes.SET_CAKECOVERDETAILS,
    payload: coverDetails
  };
};

export const setCakeSide = (side: number | undefined) => {
  return {
    type: CreatorStateTypes.SET_CAKESIDE,
    payload: side
  };
};


export const addCakeTaste = (taste: number | undefined) => {
  return {
    type: CreatorStateTypes.ADD_CAKETASTE,
    payload: taste
  };
};

export const setCakeTastes = (tastes: Array<number>) => {
  return {
    type: CreatorStateTypes.SET_CAKETASTE,
    payload: tastes
  };
};

export const delCakeTaste = (taste: number | undefined) => {
  return {
    type: CreatorStateTypes.DEL_CAKETASTE,
    payload: taste
  };
};

export const addCakeTopAddon = (topAddon: number | undefined) => {
  return {
    type: CreatorStateTypes.ADD_CAKETOPADDONS,
    payload: topAddon
  };
};

export const setCakeTopAddons = (topAddons: Array<number>) => {
  return {
    type: CreatorStateTypes.SET_CAKETOPADDONS,
    payload: topAddons
  };
};

export const delCakeTopAddon = (topAddon: number | undefined) => {
  return {
    type: CreatorStateTypes.DEL_CAKETOPADDONS,
    payload: topAddon
  };
};

export const setCakeHasSmoothTop = (isSmooth: boolean) => {
  return {
    type: CreatorStateTypes.SET_CAKEHASSMOOTHTOP,
    payload: isSmooth
  };
};

export const setDedication = (dedication: string | undefined) => {
  return {
    type: CreatorStateTypes.SET_DEDICATION,
    payload: dedication
  };
};

export const setSingleCandles = (singleCandles: number | undefined) => {
  return {
    type: CreatorStateTypes.SET_SINGLECANDLES,
    payload: singleCandles
  };
};

export const setFigureCandles = (figureCandles: number | undefined) => {
  return {
    type: CreatorStateTypes.SET_FIGURECANDLES,
    payload: figureCandles
  };
};

export const clearCreator = () => {
  return {
    type: CreatorStateTypes.CLEAR_STATE,
  };
};


export const restoreCreator = () => {
  return {
    type: CreatorStateTypes.RESTORE_STATE,
  };
};

