/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Modal from "react-bootstrap/Modal";
import { CreatorTastesList, CreatorTopAddonsList } from "../creator-assets/creatorData/creator.data";

interface Props {
  isSelectModalOpen: boolean;
  setIsSelectModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  activePanel: number;
  addNewTasteLayer: (index: number) => void;
  addNewTopAddon: (index: number) => void;
}

const AddCreatorOptionModal = (props: Props) => {

  const { isSelectModalOpen, setIsSelectModalOpen, activePanel, addNewTasteLayer, addNewTopAddon } = props;

  return (
    <Modal size={'lg'} show={isSelectModalOpen} onHide={() => setIsSelectModalOpen(false)}>
      <Modal.Header closeButton>
        {activePanel === 2 ? <h5>Dodaj smak</h5> : null}
        {activePanel === 6 ? <h5>Wybierz dodatek</h5> : null}
      </Modal.Header>
      <Modal.Body className="tasteLayerModalBody">
        <div className="kreator__wrapper kreator__bottom">
          <div className="kreator__container">
            {activePanel === 2 ?
              CreatorTastesList.map((taste, index: number) => {
                return (
                  <div className="kreator__option" key={index}>
                    <div onClick={() => addNewTasteLayer(index)} className={`kreator__option__imgWrappr`}>
                      <img src={taste.icon} alt="" />
                    </div>
                    <p>{taste.name}</p>
                  </div>
                )
              }) : null}
            {activePanel === 6 ?
              CreatorTopAddonsList.map((taste, index: number) => {
                return (
                  <div className="kreator__option" key={index}>
                    <div onClick={() => addNewTopAddon(index)} className={`kreator__option__imgWrappr`}>
                      <img src={taste.icon} alt="" />
                    </div>
                    <p>{taste.name}</p>
                  </div>
                )
              }) : null}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default AddCreatorOptionModal;