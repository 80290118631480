import React, { useEffect } from "react";
import "./signinup-page.scss";
import Helmet from "react-helmet";
import SignInForm from "../../components/sign-upin-form/sign-in-form";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NavbarLanding from "../../components/navbar-landing/navbar-landing";

const SignInPage: React.FC<any> = () => {
  useEffect(() => {
    // history.push(RoutePath.HOME);
  }, [])

  return (
    <div className="signinup-page">
      <Helmet>
        <title>{'Jaworscy | Logowanie'}</title>
      </Helmet>

      <NavbarLanding isSubNavHidden />

      <Row>
        <Col xl={{ span: 6, offset: 3 }}>
          <Card className="signInForm">
            <h5>Zaloguj się</h5>
            <SignInForm />
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default SignInPage;