/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import "./../home-page.scss";
import "./../delivery-page.scss";
import "./../standard-page.scss";
import "./standard-product-page.scss";

import Helmet from "react-helmet";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import HomeNav from "../home-nav";
import HomeContactSection from "../home-contact-section";
import HomeFooter from "../home-footer";
import SweetDream from "../sweet-dream";
import { GetParamsHelper } from "../../../tools/PathParamsHelper";
import BakeOne from "./../assets/bake1.jpg";
import BakeTwo from "./../assets/bake2.jpg";
import BakeThree from "./../assets/bake3.jpg";
import CakeSpecs from "./cake-specs";
import TopLink from "../../../components/top-link/top-link";
import { RoutePath } from "../../../../route-paths";

const StandardProductPage: React.FC<any> = () => {
  const [productName, setProductName] = useState('')
  const cakeImages = [BakeOne, BakeTwo, BakeThree];
  const [activeImg, setActiveImg] = useState(0);
  const [isProductInBasket, setIsProductInBasket] = useState(false)

  useEffect(() => {
    let params = GetParamsHelper();
    let productName = params.find((x) => x.name === 'productName')!.value;
    if (!(productName === null || productName.length === 0)) {
      let productNameFormated = (productName.charAt(0).toUpperCase() + productName.slice(1)).split('-').join(' ');
      setProductName(productNameFormated);
    }
  }, [])

  return (
    <div className="home-page standard-page delivery-page standard-product-page">
      <Helmet>
        <title>{productName + ' | TORTY.COM'}</title>
        {/* <meta name="description" content="Blog interaktywny. Praca kreatywna od kuchni, ukazana z różnych perspektyw. Programowanie, Design, Biznes, Seo, Teamwork, Strony internetowe, Aplikacje webowe i wiele innych." />
          <meta name="keywords" content="narzędzia,design,komunikacja,teamwork,responsive" /> */}
      </Helmet>
      <HomeNav />

      <div className="overlayLoader"></div>

      <section className="mainSection">
        {/* style={{ backgroundImage: `url(${OfertaB})` }}> */}
        <Container>
          <Row>
            <Col md={{ span: 6 }}>
              <div className="fotoGalleryWrapper">
                <div>
                  <img src={cakeImages[activeImg]} alt={productName} />
                </div>
                <div className="fotoGalleryNav">
                  {cakeImages && cakeImages.length > 1 ? cakeImages.map((cakeImage, index) => {
                    return (
                      <div key={index} className="fotoGalleryNavImgWrapper" onClick={() => setActiveImg(index)}>
                        <img src={cakeImage} alt={productName} />
                      </div>
                    )
                  }) : null}

                </div>
              </div>
            </Col>
            <Col md={{ span: 5, offset: 1 }}>
              <h1>{productName}</h1>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint nihil commodi provident deserunt amet veniam, sapiente sequi molestiae vero doloribus accusamus iste itaque, maiores minus id incidunt! Exercitationem, dolores optio.</p>
              <h4>Cena: 80zł/kg</h4>
              <br />
              <div style={{ minHeight: 190 }}>
                <div
                  className={isProductInBasket ? "btn btn-outline-dark" : "btn btn-dark"}
                  onClick={() => setIsProductInBasket(true)}
                >
                  Dodaj do koszyka
                </div>
                {isProductInBasket ?
                  <>
                    <p style={{ marginBottom: 15, fontSize: '1.6rem' }}>{productName} został dodany! Przejdź do koszyka, aby ustalić szczegóły: dedykację, rozmiar i rodzaj dostawy.</p>
                    <div className="btn btn-outline-dark">Zobacz koszyk</div>
                  </>
                  : null}
              </div>
              <br />
            </Col>
          </Row>
        </Container>
      </section>

      <section className="trustSection cakeDetails">
        <Container>
          <Row>
            <Col xl={{ span: 7, offset: 1 }} className="col">
              <div className="togetherJumbDescr">
                <h2>Czym cechuje się {productName}</h2>
                <br />
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse debitis suscipit, natus doloribus enim expedita velit provident iusto! Culpa qui neque magnam aut, praesentium quis sunt a et laboriosam. Doloremque?
                </p>
              </div>
            </Col>
            <Col xl={{ span: 3, offset: 1 }} className="col">
              <h5>Dostawa we Wrocławiu</h5>
              <p>lub</p>
              <h5>Odbiór w punkcie</h5>
              <br />
              <TopLink to={RoutePath.DELIVERYPOINTS}><div className="btn">&nbsp; Zobacz miejsca odbioru &nbsp;</div></TopLink>
            </Col>
          </Row>

        </Container>
      </section>

      <section className="trustSection">
        <Container>
          <h2>Co wchodzi w skład tego tortu?</h2>
          <br />
          <p>Tajemnicą są starannie dobrane składniki. Wybieramy tylko te, która są najlepsze. Najczęściaj z lokalnych źródeł, tak aby były najświeższe.</p>
          <br />
          <br />
          <CakeSpecs
            specs={['jaja', 'mleko', 'maka', 'maslo', 'fruit', 'rollingPin']}
          />
        </Container>
      </section>
      <br/>
      <br/>
      <br/>
      <br/>

      <SweetDream />
      <HomeContactSection />
      <HomeFooter />
    </div >
  )
}

export default StandardProductPage;