import React from "react";
import "./cake-specs.scss";

import Row from "react-bootstrap/Row";

import JajaImg from "./icons/jaja.svg";
import MlekoImg from "./icons/mleko.svg";
import MakaImg from "./icons/maka.svg";
import MasloImg from "./icons/maslo.svg";
import FruitImg from "./icons/fruit.svg";
import RollingPin from './icons/rollingPin.svg';

interface Props {
  specs: Array<'jaja' | 'mleko' | 'maka' | 'maslo' | 'fruit' | 'rollingPin'>
}

const CakeSpecs: React.FC<Props> = (props: Props) => {
  const { specs } = props;

  return (
    <Row className="cake-specs">
      {(specs.indexOf(('jaja')) > -1) ?
        <div className="col-lg-4 col-md-6 text-center">
          <div className="mt-5">
            <img className="services-icon" src={JajaImg} alt="jaja" />
            <h3 className="h4 mb-2">Ekologiczne jaja</h3>
          </div>
        </div>
        : null}

      {(specs.indexOf(('mleko')) > -1) ?
        <div className="col-lg-4 col-md-6 text-center">
          <div className="mt-5">
            <img className="services-icon" src={MlekoImg} alt="mleko" />
            <h3 className="h4 mb-2">Świeże mleko</h3>
          </div>
        </div>
        : null}

      {(specs.indexOf(('maka')) > -1) ?
        <div className="col-lg-4 col-md-6 text-center">
          <div className="mt-5">
            <img className="services-icon" src={MakaImg} alt="maka" />
            <h3 className="h4 mb-2">Lokalna mąka</h3>
          </div>
        </div>
        : null}

      {(specs.indexOf(('maslo')) > -1) ?
        <div className="col-lg-4 col-md-6 text-center">
          <div className="mt-5">
            <img className="services-icon" src={MasloImg} alt="maslo" />
            <h3 className="h4 mb-2">Naturalne masło</h3>
          </div>
        </div>
        : null}

      {(specs.indexOf(('fruit')) > -1) ?
      <div className="col-lg-4 col-md-6 text-center">
        <div className="mt-5">
          <img className="services-icon" src={FruitImg} alt="owoce" />
          <h3 className="h4 mb-2">Soczyste owoce</h3>
        </div>
      </div>
        : null}

      {(specs.indexOf(('rollingPin')) > -1) ?
      <div className="col-lg-4 col-md-6 text-center">
        <div className="mt-5">
          <img className="services-icon" src={RollingPin} alt="doswiadczenie" />
          <h3 className="h4 mb-2">Tradycja wyrobu</h3>
        </div>
      </div>
      : null}
    </Row>
  )
}

export default CakeSpecs;