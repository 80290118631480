import UserActionTypes from "./user.types";
import { SignUpDto } from "../../dto/sign-up-dto";
import { SignInDto } from "../../dto/sign-in-dto";
import { UserDto, UserRole } from "../../dto/user-dto";
import { UserRoleEnum } from "../../dto/user-role-enum";

// import UserActionTypes from "./user.types";
// import { SignUpDto } from "../../dto/sign-up-dto";
// import { SignInDto } from "../../dto/signInDto";
// import { UserDto } from "../../dto/user-dto";


export const signUp = (user: SignUpDto) => {
  return {
    type: UserActionTypes.AUTH_SIGNUP,
    payload: user
  };
};

export const signUpSuccess = (user: SignUpDto) => ({
  type: UserActionTypes.AUTH_SIGNUP_SUCCESS,
  payload: user
});

export const signUpFailure = (error: any) => ({
  type: UserActionTypes.AUTH_FAILURE,
  payload: error
});


export const signIn = (user: SignInDto) => ({
  type: UserActionTypes.SIGN_IN,
  payload: user
});

export const signInSuccess = (user: UserDto, token: string) => {
  return {
    type: UserActionTypes.SIGN_IN_SUCCESS,
    payload: { user, token }
  };
};

export const signOut = () => ({
  type: UserActionTypes.SIGN_OUT
});

export const signOutSuccess = () => ({
  type: UserActionTypes.SIGN_OUT_SUCCESS
})


export const refreshToken = () => ({
  type: UserActionTypes.REFRESH_TOKEN,
})

export const updateRequestTime = (lastRequestTime: Date) => ({
  type: UserActionTypes.UPDATE_REQUEST_TIME,
  payload: lastRequestTime
})

export const getUserRoles = () => ({
  type: UserActionTypes.GET_USERROLES,
})

export const getUserRolesSuccess = (roles: UserRoleEnum[], rolePlan?: UserRole) => ({
  type: UserActionTypes.GET_USERROLES,
  payload: { roles: roles, rolePlan: rolePlan }
})

// export const clearStore = () => ({
//   type: UserActionTypes.CLEAR_STORE,
// })