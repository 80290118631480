import { ApiRootPath } from "./apiRootPath";

const rootPath: string = ApiRootPath.rootPath;
export class ApiPath {
  // AUTH
  static auth_signUp: string = rootPath + 'auth/signUp';
  static auth_signIn: string = rootPath + 'auth/signIn';
  static auth_signOut: string = rootPath + 'auth/signOut';
  static auth_refreshToken: string = rootPath + 'auth/refreshToken';
  static auth_resetPassword: string = rootPath + 'auth/resetPassword';
  static auth_getUserRoles: string = rootPath + 'auth/getUserRoles';

  // LEAD
  static lead_deleteLead: string = rootPath + 'lead/deleteLead';
  static lead_getAllLeads: string = rootPath + 'lead/getAllLeads';

  // ORDERS
  static payment_getAllPayments: string = rootPath + 'payment/getPayments';
  static payment_updateOrderStatus: string = rootPath + 'payment/updateOrderStatus';

  // PUBLIC
  static publicApi_confirmEmail: string = rootPath + 'publicApi/confirmEmail';
  static publicApi_isLive: string = rootPath + 'publicApi/isLive';

  // PAYMENT
  static payment_createCheckoutSession: string = rootPath + 'payment/createCheckoutSession';
  // static payment_createPaymentIntent: string = rootPath + 'payment/createPaymentIntent';

  
  //STORE
  static store_getAllProducts: string = "/products.json" //"https://api.jsonbin.io/b/5f6a272b65b18913fc514635";
}