/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { CreatorBottomsList, CreatorCoversList, CreatorPrices, CreatorSidesList, CreatorTastesList, CreatorTopAddonsList } from "./creator-assets/creatorData/creator.data";
import "./kreator-summary.scss";
import FigureCandlesModal from "./kreator-modals/figureCandlesModal";
import SingleCandlesModal from "./kreator-modals/singleCandlesModal";
import DedicationModal from "./kreator-modals/dedicationModal";
import SummaryItem from "./summary-item";
import { useSelector, shallowEqual } from "react-redux";
import { selectCakeBottom, selectCakeCover, selectCakeCoverDetails, selectCakeHasSmoothTop, selectCakeSide, selectCakeSize, selectCakeTastes, selectCakeTopAddons, selectDedication, selectFigureCandles, selectSingleCandles } from "../../../redux/creatorState/creatorState.selectors";

interface Props { }

const KreatorSummary: React.FC<Props> = (props: Props) => {

  const cakeSize = useSelector(selectCakeSize, shallowEqual) as number;
  const cakeBottom = useSelector(selectCakeBottom, shallowEqual) as number;
  const cakeCover = useSelector(selectCakeCover, shallowEqual) as number;
  const cakeCoverDetails = useSelector(selectCakeCoverDetails, shallowEqual) as number;
  const cakeSide = useSelector(selectCakeSide, shallowEqual) as number;
  const cakeHasSmoothTop = useSelector(selectCakeHasSmoothTop, shallowEqual) as boolean;
  const cakeTastes = useSelector(selectCakeTastes, shallowEqual) as Array<number>;
  const cakeTopAddons = useSelector(selectCakeTopAddons, shallowEqual) as Array<number>;
  const singleCandles = useSelector(selectSingleCandles, shallowEqual) as number;
  const figureCandles = useSelector(selectFigureCandles, shallowEqual) as number;
  const dedication = useSelector(selectDedication, shallowEqual) as string;

  const [isDedicationModalOpen, setIsDedicationModalOpen] = useState(false);
  const [isSingleCandlesModalOpen, setIsSingleCandlesModalOpen] = useState(false);
  const [isFigureCandlesModalOpen, setIsFigureCandlesModalOpen] = useState(false);

  const [price, setPrice] = useState(0)

  useEffect(() => {
    setPrice(CreatorPrices.sum(cakeSize, dedication, singleCandles, figureCandles));
    // eslint-disable-next-line no-sparse-arrays
  }, [, cakeSize, dedication, singleCandles, figureCandles])

  return (
    <div className="kreator__summaryContainer">
      <DedicationModal
        isDedicationModalOpen={isDedicationModalOpen}
        setIsDedicationModalOpen={setIsDedicationModalOpen}
      />
      <SingleCandlesModal
        isSingleCandlesModalOpen={isSingleCandlesModalOpen}
        setIsSingleCandlesModalOpen={setIsSingleCandlesModalOpen}
      />
      <FigureCandlesModal
        isFigureCandlesModalOpen={isFigureCandlesModalOpen}
        setIsFigureCandlesModalOpen={setIsFigureCandlesModalOpen}
      />

      <div className="summaryItemsWrapper">
        <p className={'subtitle'}>Łączny koszt</p>
        <hr />
        <p className={'price'}>{price.toFixed(2)} zł</p>
        <hr />

        <p className={'subtitle'}>Dodatki</p>
        <hr />
        <SummaryItem name={"Dedykacja:"}>
          <span className='summaryItem__Value--dedication'>{dedication ? dedication : " - "}</span>
          &nbsp;&nbsp;
          <Button variant="outline-dark" size="sm" onClick={() => setIsDedicationModalOpen(true)}>
            &nbsp;&nbsp; {dedication ? 'edytuj' : 'dodaj'}&nbsp;&nbsp;
          </Button>
          &nbsp;&nbsp;
          {dedication ? <span className="priceSpan">({(CreatorPrices.dedication).toFixed(2)} zł)</span> : null}
        </SummaryItem>
        <SummaryItem name={"Świeczki pojedyncze:"}>
          <span className='summaryItem__Value--dedication'>{singleCandles ? singleCandles + " szt." : " - "}</span>
          &nbsp;&nbsp;
          <Button variant="outline-dark" size="sm" onClick={() => setIsSingleCandlesModalOpen(true)}>
            &nbsp;&nbsp; {singleCandles ? 'edytuj' : 'dodaj'}&nbsp;&nbsp;
          </Button>
          &nbsp;&nbsp;
          {singleCandles ? <span className="priceSpan">({(singleCandles * CreatorPrices.singleCandles).toFixed(2)} zł)</span> : null}
        </SummaryItem>
        <SummaryItem name={"Świeczki cyfry:"}>
          <span className='summaryItem__Value--dedication'>
            {figureCandles ? (<>
              {figureCandles.toString().length} szt. - [ {[...(figureCandles.toString().split(''))].join(', ')} ]
            </>) : " - "}
          </span>
          &nbsp;&nbsp;
          <Button variant="outline-dark" size="sm" onClick={() => setIsFigureCandlesModalOpen(true)}>
            &nbsp;&nbsp; {figureCandles ? 'edytuj' : 'dodaj'}&nbsp;&nbsp;
          </Button>
          &nbsp;&nbsp;
          {figureCandles ? <span className="priceSpan">({(figureCandles.toString().length * CreatorPrices.figureCandles).toFixed(2)} zł)</span> : null}
        </SummaryItem>

        <p className={'subtitle'}>Szczegóły tortu</p>
        <hr />
        <SummaryItem name={"Rozmiar:"}>
          {cakeSize}kg <span className="priceSpan">({(cakeSize * CreatorPrices.sizePerKg).toFixed(2)} zł)</span>
        </SummaryItem>
        <SummaryItem name={"Spód:"}>
          {CreatorBottomsList[cakeBottom].name}
        </SummaryItem>
        <SummaryItem name={"Smaki:"}>
          {cakeTastes.map((taste: number, index: number) => {
            return (
              // (index + 1).toString() + '. ' +
              CreatorTastesList[taste].name + (cakeTastes.length > (index + 1) ? ', ' : '')
            )
          })}
        </SummaryItem>
        <SummaryItem name={"Pokrycie:"}>
          {CreatorCoversList[cakeCover].name + ' - ' + CreatorCoversList[cakeCover].details[cakeCoverDetails].name}
        </SummaryItem>
        <SummaryItem name={"Boki:"}>
          {CreatorSidesList[cakeSide].name}
        </SummaryItem>
        <SummaryItem name={"Wierzch:"}>
          {cakeTopAddons.length > 0 ? cakeTopAddons.map((topAddon: number, index: number) => {
            return (
              // (index + 1).toString() + '. ' +
              CreatorTopAddonsList[topAddon].name + (cakeTopAddons.length > (index + 1) ? ', ' : '')
            )
          }) :
            (cakeHasSmoothTop ? "Gładki" : "")
          }
        </SummaryItem>
      </div>
    </div>
  )
}

export default KreatorSummary;