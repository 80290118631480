/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import "./home-page.scss";
import "./delivery-page.scss";

import Helmet from "react-helmet";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import HomeNav from "./home-nav";
import HomeContactSection from "./home-contact-section";
import HomeFooter from "./home-footer";
import SweetDream from "./sweet-dream";

const DeliveryPage: React.FC<any> = () => {
  const [mapScrollBlockOberlayIsEnabled, setMapScrollBlockOberlayIsEnabled] = useState(true);
  return (
    <div className="home-page delivery-page">
      <Helmet>
        <title>{'Oferta | TORTY.COM'}</title>
        {/* <meta name="description" content="Blog interaktywny. Praca kreatywna od kuchni, ukazana z różnych perspektyw. Programowanie, Design, Biznes, Seo, Teamwork, Strony internetowe, Aplikacje webowe i wiele innych." />
          <meta name="keywords" content="narzędzia,design,komunikacja,teamwork,responsive" /> */}
      </Helmet>
      <HomeNav />

      <div className="overlayLoader"></div>


      <section className="trustSection">
        <Container>
          <br />
          <br />
          <h1>Jak następuje przekazanie tortu?</h1>
          <br />
          <p>Możesz odebrać wskazując jeden z naszych punktów przy składaniu zamówienia lub wybrać opcję z dowozem na terenie Wrocławia.</p>
          <br />
          <br />
        </Container>
      </section>

      <section className="togetherSection">
        <Container>
          <Row>
            <Col xl={{ span: 8, offset: 2 }}>
              <h2 style={{ margin: 'auto', textAlign: 'center' }}>Odbiór z dowozem</h2>
              <br />
              <br />
              <p style={{ margin: 'auto', textAlign: 'center' }}>Dowozimy torty na terenie całego Wrocławia, na wskazaną przez Ciebie godzinę. Posiadamy samochody wyposażone w nowoczesne chłodnie, dzięki czemu jakość i świeżość Twojego zamówienia, będzie doskonała!</p>
              <br />
              <br />

            </Col>
            <Col xl={{ span: 8, offset: 2 }} className="col">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d90587.91461709954!2d16.94956953555966!3d51.1291898782786!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470fe9c2d4b58abf%3A0xb70956aec205e0f5!2zV3JvY8WCYXc!5e0!3m2!1spl!2spl!4v1599864174543!5m2!1spl!2spl" width="100%" height="400" frameBorder={0} style={{ border: 0, pointerEvents: 'auto' }} allowFullScreen={false} aria-hidden={false} tabIndex={0} onMouseLeave={() => setMapScrollBlockOberlayIsEnabled(true)}></iframe>
              {mapScrollBlockOberlayIsEnabled ?
                <div className="mapScrollOverlay" onClick={() => setMapScrollBlockOberlayIsEnabled(false)}></div>
                : null}

            </Col>
          </Row>
          <br />
          <br />
          <br />

          <h2 style={{ margin: 'auto', textAlign: 'center' }}>Odbiór osobisty w puntach</h2>
          <br />

          <Row>
            <Col xl={{ span: 5 }} className="col">
              <div className="togetherJumbDescr">
                <h3>Krzemieniecka 47</h3>
                <h4>54-613 Wrocław</h4>
                <p><strong>Godz. otwarcia:</strong><br />pn-sb: 07:00 - 18:00<br />nd: 10:00 - 18:00</p>
                <p><strong>Telefon:</strong><br />(+48) 713 739 524</p>
              </div>
            </Col>
            <Col xl={{ span: 6, offset: 1 }} className="col">
              <iframe src="https://www.google.com/maps/embed?pb=!4v1599861151962!6m8!1m7!1s1Cz4Wxq1O0Gv7CjolyfXcg!2m2!1d51.0958386067927!2d16.96288394284406!3f9.995842315158427!4f-7.492089542915338!5f0.7820865974627469" width="100%" height="400" frameBorder={0} style={{ border: 0, pointerEvents: 'auto' }} allowFullScreen={false} aria-hidden={false} tabIndex={0} onMouseLeave={() => setMapScrollBlockOberlayIsEnabled(true)}></iframe>
              {mapScrollBlockOberlayIsEnabled ?
                <div className="mapScrollOverlay" onClick={() => setMapScrollBlockOberlayIsEnabled(false)}></div>
                : null}
            </Col>
          </Row>


          <Row>
            <Col xl={{ span: 6 }} className="col">
              <iframe src="https://www.google.com/maps/embed?pb=!4v1599861527442!6m8!1m7!1sZUoJGP7bTZHHUAun4MoXsw!2m2!1d51.13628999872969!2d16.97688052667974!3f177.73441839369178!4f-12.779542767418661!5f0.7820865974627469" width="100%" height="400" frameBorder={0} style={{ border: 0, pointerEvents: 'auto' }} allowFullScreen={false} aria-hidden={false} tabIndex={0} onMouseLeave={() => setMapScrollBlockOberlayIsEnabled(true)}></iframe>
              {mapScrollBlockOberlayIsEnabled ?
                <div className="mapScrollOverlay" onClick={() => setMapScrollBlockOberlayIsEnabled(false)}></div>
                : null}
            </Col>
            <Col xl={{ span: 5 }} className="col">
              <div className="togetherJumbDescr togetherJumbDescr__reversed">
                <h3>Wiślańska 18</h3>
                <h4>54-152 Wrocław</h4>
                <p><strong>Godz. otwarcia:</strong><br />pn-sb: 07:00 - 18:00<br />nd: 10:00 - 16:00</p>
                <p><strong>Telefon:</strong><br />(+48) 713 744 054</p>
              </div>
            </Col>
          </Row>


          <Row>
            <Col xl={{ span: 5 }} className="col">
              <div className="togetherJumbDescr">
                <h3>Stacha Świstackiego 2</h3>
                <h4>50-430 Wrocław</h4>
                <p><strong>Godz. otwarcia:</strong><br />pn-sb: 07:00 - 17:00<br />nd: 10:00 - 17:00</p>
                <p><strong>Telefon:</strong><br />(+48) 713 436 994</p>
              </div>
            </Col>
            <Col xl={{ span: 6, offset: 1 }} className="col">
              <iframe src="https://www.google.com/maps/embed?pb=!4v1599862230056!6m8!1m7!1sLndb1KiNa_KOWiqklF69Kw!2m2!1d51.09868555383073!2d17.05091808893476!3f98.1151614461183!4f-3.6641602877506187!5f1.1924812503605782" width="100%" height="400" frameBorder={0} style={{ border: 0, pointerEvents: 'auto' }} allowFullScreen={false} aria-hidden={false} tabIndex={0} onMouseLeave={() => setMapScrollBlockOberlayIsEnabled(true)}></iframe>
              {mapScrollBlockOberlayIsEnabled ?
                <div className="mapScrollOverlay" onClick={() => setMapScrollBlockOberlayIsEnabled(false)}></div>
                : null}
            </Col>
          </Row>


          <Row>
            <Col xl={{ span: 6 }} className="col">
              <iframe src="https://www.google.com/maps/embed?pb=!4v1599862100386!6m8!1m7!1sWWeWwaFfZUYl2GCBQkjVcQ!2m2!1d51.08887588885454!2d17.02023993380534!3f72.00075389488605!4f0!5f1.1924812503605782" width="100%" height="400" frameBorder={0} style={{ border: 0, pointerEvents: 'auto' }} allowFullScreen={false} aria-hidden={false} tabIndex={0} onMouseLeave={() => setMapScrollBlockOberlayIsEnabled(true)}></iframe>
              {mapScrollBlockOberlayIsEnabled ?
                <div className="mapScrollOverlay" onClick={() => setMapScrollBlockOberlayIsEnabled(false)}></div>
                : null}
            </Col>
            <Col xl={{ span: 5 }} className="col">
              <div className="togetherJumbDescr">
                <h3>Drukarska 36</h3>
                <h4>53-311 Wrocław</h4>
                <p><strong>Godz. otwarcia:</strong><br />pn-sb: 07:00 - 18:00<br />nd: 10:00 - 16:00</p>
                <p><strong>Telefon:</strong><br />(+48) 713 670 622</p>
              </div>
            </Col>
          </Row>


          <Row>
            <Col xl={{ span: 5 }} className="col">
              <div className="togetherJumbDescr">
                <h3>Sienkiewicza 150</h3>
                <h4>50-347 Wrocław</h4>
                <p><strong>Godz. otwarcia:</strong><br />pn-sb: 07:00 - 18:00<br />nd: 10:00 - 16:00</p>
                <p><strong>Telefon:</strong><br />(+48) 602 123 133</p>
              </div>
            </Col>
            <Col xl={{ span: 6, offset: 1 }} className="col">
              <iframe src="https://www.google.com/maps/embed?pb=!3m2!1spl!2spl!4v1599859518002!5m2!1spl!2spl!6m8!1m7!1sT1cLDaj4PtRF9XJTiO1yOg!2m2!1d51.11520422313627!2d17.06688616253008!3f6.677067817867602!4f6.169991715977943!5f0.7820865974627469" width="100%" height="400" frameBorder={0} style={{ border: 0, pointerEvents: 'auto' }} allowFullScreen={false} aria-hidden={false} tabIndex={0} onMouseLeave={() => setMapScrollBlockOberlayIsEnabled(true)}></iframe>
              {mapScrollBlockOberlayIsEnabled ?
                <div className="mapScrollOverlay" onClick={() => setMapScrollBlockOberlayIsEnabled(false)}></div>
                : null}
            </Col>
          </Row>

          <br />
          <br />
          <br />
          <br />
          <br />
          <br />

        </Container>
      </section>

      <SweetDream />
      <HomeContactSection />
      <HomeFooter />
    </div >
  )
}

export default DeliveryPage;