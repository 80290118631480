import { PaymentIntent } from "@stripe/stripe-js";
import { OrderFiltersDto } from "../../dto/orderFiltersDto";
import { UpdateOrderStatusDto } from "../../dto/updateOrderStatusDto";
import OrdersStateTypes from "./ordersState.types";

export const getAllOrders = () => {
  return {
    type: OrdersStateTypes.GET_ALL_ORDERS
  };
};

export const clearOrders = () => {
  return {
    type: OrdersStateTypes.ORDERS_CLEAR_ORDERS
  };
};

export const clearOrdersState = () => {
  return {
    type: OrdersStateTypes.ORDERS_CLEAR_STATE
  };
};

export const setOrdersFilters = (orderFilters: OrderFiltersDto) => {
  return {
    type: OrdersStateTypes.SET_ORDERSFILTERS,
    payload: orderFilters
  };
}

export const setOrdersFiltersLastId = (lastId: number | undefined) => {
  return {
    type: OrdersStateTypes.SET_ORDERSFILTERS_LASTID,
    payload: lastId
  };
}

export const getAllOrdersSuccess = (orders: Array<PaymentIntent>) => {
  return {
    type: OrdersStateTypes.GET_ALL_ORDERS_SUCCESS,
    payload: orders
  };
};

export const updateOrderStatus = (updateOrderStatusDto: UpdateOrderStatusDto) => {
  return {
    type: OrdersStateTypes.UPDATE_ORDER_STATUS,
    payload: updateOrderStatusDto
  };
};