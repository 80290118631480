/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef } from "react";
import "./home-page.scss";
import Helmet from "react-helmet";
import { RoutePath } from "../../../route-paths";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import ArrowDown from "./assets/arrowDown.svg";

// import MaleChef from "./assets/male-chef.svg";
import MaleChef from "./assets/male-chef.jpg";

import BakeOne from "./assets/bake1.jpg";
import BakeTwo from "./assets/bake2.jpg";
import BakeThree from "./assets/bake3.jpg";
import BakeFour from "./assets/bake4.jpg";
import MainImg from "./assets/mainImg.jpg";

import DeliveryIcon from "./assets/deliveryIcon.svg";
import BakeIcon from "./assets/bakeIcon.svg";
import ComputerIcon from "./assets/computerIcon.svg";


import ProductThumbnail from "../../components/product-thumbnail/product-thumbnail";
import HomeNav from "./home-nav";
import HomeContactSection from "./home-contact-section";
import HomeFooter from "./home-footer";
import SweetDream from "./sweet-dream";
import HomeInsideNav from "./home-inside-nav";
import TopLink from "../../components/top-link/top-link";
import { handleScrollToSection } from "../../tools/handleScrollToSection";

const HomePage: React.FC<any> = () => {

  const startLink = useRef<any>();

  return (
    <div className="home-page">
      <Helmet>
        <title>{'TORTY.COM - Najlepsze torty we Wrocławiu!'}</title>
        {/* <meta name="description" content="Blog interaktywny. Praca kreatywna od kuchni, ukazana z różnych perspektyw. Programowanie, Design, Biznes, Seo, Teamwork, Strony internetowe, Aplikacje webowe i wiele innych." />
          <meta name="keywords" content="narzędzia,design,komunikacja,teamwork,responsive" /> */}
      </Helmet>
      <HomeNav />

      <div className="overlayLoader"></div>

      <section className="mainSection" style={{ backgroundImage: `url(${MainImg})` }}>
        <Container>
          <Row>
            <Col md={{ span: 6 }}>
              <br />
              <h1>Pieczemy najlepsze torty we Wrocławiu od&nbsp;pokoleń!</h1>
              <br />
              <p>Użyj naszego kreatora tortów, aby uzyskać dokładnie to, czego poszukujesz. Wykorzystamy nasze wieloletnie doświadczenie, aby połączyć wybrane przez Ciebie składniki&nbsp;i&nbsp;dostarczyć Ci Twój personalny tort!</p>
              <div className="box">
                <div className={"btn btn-dark"}><TopLink to={RoutePath.KREATOR}> Zacznij kreować!</TopLink></div>
                &nbsp;
                &nbsp;
                &nbsp;
                <div className={"btn btn-outline-dark"} onClick={() => { handleScrollToSection(startLink, 0) }}>Jak to działa?</div>
              </div>
            </Col>
            <Col md={{ span: 5, offset: 1 }}>
              <div className="box">
                {/* <img src={BigCake} alt="Wirtualny Asystent" /> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>


      <div className="popularItems py-5">
        <div className="container">
          <h2>Zobacz popularne torty z kreatora</h2>
          <div className="row">
            <ProductThumbnail
              image={BakeOne}
              title={"Tort czekoladowy"}
              description="Lorem ipsum dolor sctus augue, sit amet aliquet dit amet, consectetur adipiscing elit. Ut aliquam pulvinar justo et convallis. Sed aliquam luctus augue, sit amet aliquet dui fringilla vitae. Sed a mattis mi."
              buttonText={"Zobacz w kreatorze"}
              buttonLink={RoutePath.RULES}
            />

            <ProductThumbnail
              image={BakeTwo}
              title={"Tort truskawkowy"}
              description="Ut aliquam pulvinar justo et convallis. Sed aliquam luctus augue, sit amet aliquet dui frinLorem ipsum dolor sit amet, consectetur adipiscictus augue, sit amet aliquet dng elit gilla vitae. Sed a mattis mi."
              buttonText={"Zobacz w kreatorze"}
              buttonLink={RoutePath.RULES}
            />

            <ProductThumbnail
              image={BakeThree}
              title={"Tort owoce leśne"}
              description="Ut aliquam pulvinar justo et convallis. Sed aliquam luctus augue, sit amet aliquet dui frinLorem ipsum dolor sit amet, consectetur adipiscictus augue, sit amet aliquet dng elit gilla vitae. Sed a mattis mi."
              buttonText={"Zobacz w kreatorze"}
              buttonLink={RoutePath.RULES}
            />

            <ProductThumbnail
              image={BakeFour}
              title={"Tort kokosowy"}
              description="Lorem ipsum dolor sctus augue, sit amet aliquet dit amet, consectetur adipiscing elit. Ut aliquam pulvinar justo et convallis. Sed aliquam luctus augue, sit amet aliquet dui fringilla vitae. Sed a mattis mi."
              buttonText={"Zobacz w kreatorze"}
              buttonLink={RoutePath.RULES}
            />
          </div>
        </div>
      </div>


      <section ref={startLink} className="createProjectSection">
        <Container>
          <div className="introBox">
            <h4>Jak stworzyć własny tort<br /> bez kilku godzin spędzonych w kuchni?</h4>
            <p>
              Z naszym kretorem, to niezwykle proste i przyjemne. Wystarczy, że przejdziesz przez parę kroków, a my upieczemy i dowieziemy Twoje dzieło. Zaskocz znajomych lub rodzinę wyjątkową kompozycją smaków.
            </p>
          </div>

          <div className="stepsBox">
            <Row>
              <Col xl={{ span: 3, offset: 1 }} sm={{ span: 6, offset: 0, order: 1 }} xs={{ order: 2 }} className="col">
                <div className="stepImg">
                  <img src={ComputerIcon} alt="" />
                </div>
              </Col>
              <Col xl={{ span: 1, offset: 0 }} sm={{ span: 2, offset: 7, order: 2 }} xs={{ order: 1 }} className="col">
                <div className="stepNum">
                  1
                </div>
              </Col>
              <Col xl={{ span: 6, order: 3 }} xs={{ order: 3 }} className="col">
                <div className="stepDescr">
                  <h3>Kreujesz samemu i zamawiasz</h3>
                  <p>Najpierw określasz rozmiar i kształt tortu, następnie spód. W kolejnym kroku dodajesz swoje smaki. Na koniec definiujesz czym powinien zostać pokryty i ozdobiony.</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xl={{ span: 1, offset: 4 }} className="col">
                <div className="stepArrow">
                  <img src={ArrowDown} alt="" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xl={{ span: 3, offset: 1 }} sm={{ span: 6, offset: 0, order: 1 }} xs={{ order: 2 }} className="col">
                <div className="stepImg">
                  <img src={BakeIcon} alt="" />
                </div>
              </Col>
              <Col xl={{ span: 1, offset: 0 }} sm={{ span: 2, offset: 7, order: 2 }} xs={{ order: 1 }} className="col">
                <div className="stepNum">
                  2
                </div>
              </Col>
              <Col xl={{ span: 6, order: 3 }} xs={{ order: 3 }} className="col">
                <div className="stepDescr">
                  <h3>Pieczemy Twój personalny tort</h3>
                  <p>Wykorzystamy tradycyjne metody, do upieczenia Twojego tortu. Potrafimy połączyć wszystkie możliwe konfiguracje z kreatora. Nie wierzysz? Sprawdź czy damy radę!</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xl={{ span: 1, offset: 4 }} className="col">
                <div className="stepArrow">
                  <img src={ArrowDown} alt="" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xl={{ span: 3, offset: 1 }} sm={{ span: 6, offset: 0, order: 1 }} xs={{ order: 2 }} className="col">
                <div className="stepImg">
                  <img src={DeliveryIcon} alt="" />
                </div>
              </Col>
              <Col xl={{ span: 1, offset: 0 }} sm={{ span: 2, offset: 7, order: 2 }} xs={{ order: 1 }} className="col">
                <div className="stepNum">
                  3
                </div>
              </Col>
              <Col xl={{ span: 6, order: 3 }} xs={{ order: 3 }} className="col">
                <div className="stepDescr">
                  <h3>Dowozimy lub odbierasz w punkcie</h3>
                  <p>Upieczony tort możesz odebrać w jednym z naszym punktów, lub zamówić z dowozem do określonej lokalizacji. Dowozimy zamówienia tylko na terenie Wrocławia i okolic.</p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>

      <section className="trustSection">
        <Container>
          <h4>Stwórz własny tort już dzisiaj!</h4>
          <p>Tort możesz zamówić z wyprzedzeniem. Wykonamy go w nocy, poprzedzającej dzień odbioru, dzięki czemu masz gwarancję świeżości.</p>
          <br />
          <br />
          <div className={"btn btn btn-dark"}>&nbsp;&nbsp;&nbsp;Rozpocznij kreowanie!&nbsp;&nbsp;&nbsp;</div>
        </Container>
      </section>

      <section className="togetherSection">
        <Container>
          <Row>
            <Col xl={{ span: 5, offset: 1, order: 1 }} sm={{ order: 1 }} xs={{ order: 2 }} className="col">
              <div className="togetherJumbDescr">
                <h3>A może... <br /> wolisz coś sprawdzonego?</h3>
                <p>Przez 30 lat zebraliśmy najlepsze przepisy, które umieścilismy dla Ciebie w ofercie standardowej. Realizujemy też torty okolicznościowe i dla firm.</p>
                <p><strong>Zobacz tradycyjną ofertę &#8628;</strong></p>
              </div>
            </Col>
            <Col xl={{ span: 3, offset: 1, order: 2 }} sm={{ order: 2 }} xs={{ order: 1 }} className="col">
              <img src={MaleChef} alt="" />
            </Col>
          </Row>
          <Row>
            <Col xl={{ span: 5, offset: 1 }} className="col arrowRollDown">
              <div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <HomeInsideNav excluded={'kreator'} />
      <SweetDream />
      <HomeContactSection />
      <HomeFooter />
    </div>
  )
}

export default HomePage;