import { Middleware } from 'redux';
import { setQueryStringValue } from '../../tools/queryStringTool';
import { KeyMapper } from '../creatorState/creatorState.reducer';
import { JaworscyStore } from "../store.types";

const urlReduxSyncMiddleware: Middleware<{}, JaworscyStore> = store => next => action => {
  let result = next(action)
  let creatorState = store.getState()['creatorState'];

  for (let [key, value] of Object.entries(creatorState)) {

    if (typeof value === 'object') {
      if (Array.isArray(value) && value.length <= 0) {
        setQueryStringValue((KeyMapper as any)[key], undefined);
      }
      else {
        setQueryStringValue((KeyMapper as any)[key], JSON.stringify(value));
      }
    }
    else {

      if (value === false) {
        setQueryStringValue((KeyMapper as any)[key], undefined);
      }
      else {
        setQueryStringValue((KeyMapper as any)[key], value);
      }
    }
  }
  return result
}

export default urlReduxSyncMiddleware;