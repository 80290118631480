import React from "react";
import Helmet from "react-helmet";
import Container from "react-bootstrap/Container";
import { shallowEqual, useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../redux/user/user.selectors";
import "./home-panel.scss";
import MainSection from "../../../../components/main-section/main-section";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import CircuralChart from "../../../../components/circular-chart/circural-chart";

const HomePanel: React.FC<any> = () => {
  const currentUser = useSelector(selectCurrentUser, shallowEqual);


  return (
    currentUser ? <div className="home-panel">
      <Helmet>
        <title>{'Suggestly'}</title>
      </Helmet>
      <Container>
        <MainSection
          title={"Welcome"}
          subtitle="Panel obsługi"
        ></MainSection>
        <Row>
          <Col xl={{ span: 4 }}>
            <Card className="chartCard">
              <Card.Header>
                Nowe zamówienia
              </Card.Header>
              <Card.Body>
                <div className="chartWrapper">
                  <CircuralChart val={85} />
                </div>
                {/* <br /> */}
                {/* <p>Zrealizowanych sugestii: <strong>{clientActionsSum ? clientActionsSum.results : 0}</strong></p>
                <p>Dostępnych w planie: <strong>{getMaxResults()}</strong></p>
                <p>Pozostało: <strong>{clientActionsSum ? (getMaxResults() - clientActionsSum.results) : 0}</strong></p>

                {maxResults >= 85 ?
                  <Link to={RoutePath.SUBSCRIPTION_SETTINGS}><Button variant="secondary">Zwiększ</Button></Link>
                  : null
                } */}
              </Card.Body>
            </Card>
          </Col>
          <Col xl={{ span: 4 }}>
            <Card className="chartCard">
              <Card.Header>
                Zamówienia w realizacji
              </Card.Header>
              <Card.Body>
                <div className="chartWrapper">
                  <CircuralChart val={3} />
                </div>
                <br />
                <p>Dane na bieżący miesiąc:</p>
                {/* <p>Łącznie nowych kontaktów: <strong>{clientActionsSum ? clientActionsSum.newLeads : 0}</strong></p> */}
              </Card.Body>
            </Card>
          </Col>
          <Col xl={{ span: 4 }}>
            <Card className="chartCard">
              <Card.Header>
                Skuteczność przekierowań
              </Card.Header>
              <Card.Body>
                <div className="chartWrapper">
                  <CircuralChart val={156} />
                </div>
                <br />
                <p>Dane na bieżący miesiąc:</p>
                {/* <p>Łącznie przekierowań: <strong>{clientActionsSum ? clientActionsSum.redirects : 0}</strong></p> */}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div> : null
  )
}

export default HomePanel;