import React, { useState } from "react";
import "./order-filters.scss";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import DropdownItem from "react-bootstrap/DropdownItem";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { OrderStatusEnum } from "../../dto/orderStatusEnum";
import { clearOrders, clearOrdersState, getAllOrders, setOrdersFilters } from "../../redux/ordersState/ordersState.actions";
import { selectOrdersFilters } from "../../redux/ordersState/ordersState.selectors";
import { OrderFiltersDto } from "../../dto/orderFiltersDto";

const OrderFilters: React.FC<any> = () => {
  const ordersFilters = useSelector(selectOrdersFilters, shallowEqual) as OrderFiltersDto;

  const dispatch = useDispatch();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const [orderDateRange, setOrderDateRange] = useState([null, null] as Array<Date | null>);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [orderStatus, setOrderStatus] = useState({ orderStatus: undefined, orderStatusString: "" } as { orderStatus: OrderStatusEnum | undefined, orderStatusString: String });

  const filterOrders = () => {
    dispatch(setOrdersFilters({
      lastId: undefined,
      filterByStatus: orderStatus.orderStatus ? orderStatus.orderStatus : undefined,
      dateStart: orderDateRange[0] ? orderDateRange[0] : undefined,
      dateEnd: orderDateRange[1] ? orderDateRange[1] : undefined
    }))
    dispatch(clearOrders());
    dispatch(getAllOrders());
  }

  const cancelFilters = () => {
    dispatch(clearOrdersState());
    dispatch(getAllOrders());
  }

  const onChangeCalendar = (date: Date[]) => {
    setOrderDateRange(date);
    setIsCalendarOpen(false);
  }

  const setOrderStatusHandler = (orderStatus: OrderStatusEnum, orderStatusString: String) => {
    setOrderStatus({ orderStatus: orderStatus, orderStatusString: orderStatusString })
  }

  return (
    <div className="order-filters">
      <Row>
        <Col xl={{ span: 4 }}>
          &nbsp;Zakres czasu
        </Col>
        <Col xl={{ span: 3 }}>
          &nbsp;Status zamówienia
        </Col>
      </Row>
      <Row>
        <Col xl={{ span: 4 }}>
          <div className="btn btn-primary datePickerBtn" onClick={() => setIsCalendarOpen(true)}>
            <DateRangePicker
              onChange={onChangeCalendar}
              value={orderDateRange}
              onCalendarClose={() => setIsCalendarOpen(false)}
              isOpen={isCalendarOpen}
              maxDate={new Date(Date.now())}
              calendarIcon={null}
            />
          </div>
        </Col>
        <Col xl={{ span: 4 }}>
          <Dropdown show={dropdownOpen} onToggle={toggle} className="actionBtn" alignRight={true}>
            <DropdownToggle id="caret">
              <div className="dropdownContent">
                {orderStatus && orderStatus.orderStatus ?
                  orderStatus.orderStatusString
                  : <> Status zamówienia </>
                }
              </div>
            </DropdownToggle>
            <DropdownMenu>
              <div>
                <DropdownItem onClick={() => setOrderStatusHandler(OrderStatusEnum.PaymentIntent, "Opłacona")}>
                  Opłacone
                </DropdownItem>
                <DropdownItem onClick={() => setOrderStatusHandler(OrderStatusEnum.InRealization, "Realizacja")}>
                  Realizacja
                </DropdownItem>
                <DropdownItem onClick={() => setOrderStatusHandler(OrderStatusEnum.RealizedAndWaitingForDelivery, "W trakcie dostawy")}>
                  W trakcie dostawy
                </DropdownItem>
                <DropdownItem onClick={() => setOrderStatusHandler(OrderStatusEnum.RealizedAndWaitingForReceiver, "Oczekuje na odbiorcę")}>
                  Oczekuje na odbiorcę
                </DropdownItem>
                <DropdownItem onClick={() => setOrderStatusHandler(OrderStatusEnum.Received, "Odebrane")}>
                  Odebrane
                </DropdownItem>
              </div>
            </DropdownMenu>
          </Dropdown>
        </Col>
        <Col xl={{ offset: 1, span: 3 }} style={{ textAlign: 'right' }}>
          {ordersFilters.dateEnd || ordersFilters.dateStart || ordersFilters.filterByStatus ?
            <>
              <Button onClick={cancelFilters}>
                Wyczyść &nbsp; <i className="fa fa-remove" aria-hidden="true"></i>
              </Button>
              &nbsp;
              &nbsp;
              &nbsp;
            </>
            : null}
          <Button onClick={filterOrders}>
            Filtruj &nbsp; <i className="fa fa-filter" aria-hidden="true"></i>
          </Button>
        </Col>
      </Row>
    </div >
  )
}

export default OrderFilters;