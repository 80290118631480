import React from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { ModalDto } from "../../dto/modalDto";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { hideGlobalModal } from "../../redux/callbacksState/callbacksState.actions";
import { selectGlobalModal } from "../../redux/callbacksState/callbacksState.selectors";

const GlobalModal: React.FC = () => {
  const globalModal = useSelector(selectGlobalModal, shallowEqual) as ModalDto;
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hideGlobalModal());
  }
  const handleRedirect = () => {
    setTimeout(() => {
      dispatch(hideGlobalModal());
    }, 50);
  }

  return (
    <>
      {globalModal ?
        <div className="globalModal">
          <Modal show={globalModal.isVisible} onHide={handleClose}>
            <Modal.Header closeButton>
              <h5>{globalModal.title}</h5>
            </Modal.Header>
            <Modal.Body className="">
              {globalModal.description}
            </Modal.Body>
            <Modal.Footer>
              <Link to={globalModal.path as string}>
                <Button variant="secondary" onClick={handleRedirect}>
                  {globalModal.buttonTitle}
                </Button>
              </Link>
              <Button variant="primary" onClick={handleClose}>
                Anuluj
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        : null}
    </>
  )
}

export default GlobalModal;
