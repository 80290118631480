import React, { useState } from "react";
import "./sidenav.scss";
import ListGroup from "react-bootstrap/ListGroup";
import { RoutePath } from "../../../route-paths";
import history from './../../../history';

const SideBar: React.FC<any> = (props: any) => {
  const navItem = [
    { routePath: RoutePath.HOME_PANEL, name: "Statystyki projektu" },
    { routePath: RoutePath.ORDERS_PANEL, name: "Zamówienia" },
    { routePath: RoutePath.LEADS_PANEL, name: "Twoje kontakty" },
  ]

  const getDefault = (): number => {
    const activeIndex = navItem.findIndex(x => x.routePath === history.location.pathname);
    return activeIndex;
  }
  const [activeKey] = useState(getDefault)

  const setActive = (key: number, routePath: keyof RoutePath) => {
    if(props.isDisabled !== true){
      history.push(routePath as string);
    }
  }

  return (
    <ListGroup defaultActiveKey={props.isDisabled ? null : activeKey} activeKey={props.isDisabled ? null : activeKey} as="ul" className={props.isDisabled ? "sidenav sidenav--disabled" : "sidenav"}>
      {navItem.map((item, index: number) => {
        return (
          <ListGroup.Item key={index} eventKey={index} as="li" onClick={() => setActive(index, item.routePath)}>
            {item.name}
          </ListGroup.Item>
        )
      })}
    </ListGroup>
  )
}

export default SideBar;