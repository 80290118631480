import { takeLatest, put, all, call } from "redux-saga/effects";
import { ApiMethodDto } from "../../api/api-method-dto";
import { ApiPath } from "../../api/apiPath";
import { callApi } from "../../api/api-call-service";
import ProductsStateActionTypes from "./productsState.types";
import { ProductDto } from "../../dto/productDto";

function* getAllProductsAsync() {
  const options = {
    headers: {
      "secret-key": "$2b$10$mHQosfJFq7tfHxCvDfzrIOr8VhLDcCzzlDU825GDSU.tnY6aDi6.G"
    },
    method: "GET",
    expectedStatus: [200, 304]
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "get", ApiPath.store_getAllProducts, options);

    yield put({
      type: ProductsStateActionTypes.GET_ALL_PRODUCTS_SUCCESS,
      payload: res.data.products as Array<ProductDto>
    });
  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}


function* watchGetAllProducts() {
  yield takeLatest(ProductsStateActionTypes.GET_ALL_PRODUCTS as any, getAllProductsAsync);
}

export default function* ProductsStateSagas() {
  yield all([
    call(watchGetAllProducts)
  ]);
}