import { createSelector } from 'reselect';
import { JaworscyStore } from '../store.types';

const memoCallbacksState = (state: JaworscyStore) => state.memoCallbacksState;

export const select_is_waiting_for_payment = createSelector(
  [memoCallbacksState],
  memoCallbacksState => {
    return memoCallbacksState.is_waiting_for_payment
  }
);