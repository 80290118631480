/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {  } from "react";
import "./home-page.scss";
// import { Link } from "react-router-dom";
// import { RoutePath } from "../../../route-paths";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import Twitter from "./assets/icon_tw.svg";
// import Facebook from "./assets/icon_fb.svg";
// import Linkedin from "./assets/icon_li.svg";

const HomeFooter: React.FC<any> = () => {
  return (
    <footer>
    <Container>
      <Row>
        {/* <Col xl={{ span: 4 }} className="col colLeft">
          <Link to={{ pathname: RoutePath.RULES, state: { scrollTo: 'top' } }}>Polityka prywatności</Link>
          <br />
          <Link to={{ pathname: RoutePath.RULES, state: { scrollTo: 'rulesPage' } }}>Warunki użytkowania</Link>

        </Col> */}
        {/* <Col xl={{ span: 4 }} className="col colMiddle">
          <a href="#" target="_blank" rel="noopener noreferrer"><img src={Twitter} alt="" /></a>
          <a href="#" target="_blank" rel="noopener noreferrer"><img src={Facebook} alt="" /></a>
          <a href="#" target="_blank" rel="noopener noreferrer"><img src={Linkedin} alt="" /></a>
        </Col>
        <Col xl={{ span: 4 }} className="col colRight">
          TORTY.COM &copy; 2020
          <br />
          Realizacja: <a href="https://codeebo.pl" target="_blank" rel="noopener noreferrer">Codeebo</a>
        </Col> */}
      </Row>
    </Container>
  </footer>
  )
}

export default HomeFooter;