import CartStateTypes from "./cartState.types";
import { CartItemDto, ClientDataDto, DeliveryPanelDto, ReceiverDataDto } from "../../dto/cartItemDto";


export const deleteCartItem = (cartItemId: string) => {
  return {
    type: CartStateTypes.DELETE_CARTITEM,
    payload: cartItemId
  };
};

export const addCartItem = (item: CartItemDto) => {
  return {
    type: CartStateTypes.ADD_CARTITEM,
    payload: item
  };
};

export const setDeliveryPanel = (deliveryPanel: DeliveryPanelDto) => {
  return {
    type: CartStateTypes.SET_DELIVERYPANEL,
    payload: deliveryPanel
  };
};

export const setReceiverData = (receiverData: ReceiverDataDto) => {
  return {
    type: CartStateTypes.SET_RECEIVERDATA,
    payload: receiverData
  };
};


export const setClientData = (clientData: ClientDataDto) => {
  return {
    type: CartStateTypes.SET_CLIENTDATA,
    payload: clientData
  };
};

export const clearCart = () => {
  return {
    type: CartStateTypes.CART_CLEAR_STATE,
  };
};

export const createCheckoutSession = () => {
  return {
    type: CartStateTypes.CREATE_CHECKOUT_SESSION,
  };
};