import { CakeOrderDto } from '../../dto/cakeOrderDto';
import { OrderFiltersDto } from '../../dto/orderFiltersDto';
import OrdersStateTypes from './ordersState.types';

const INITIAL_STATE = {
  orders: [] as Array<CakeOrderDto>,
  ordersFilters: {
    lastId: undefined,
    filterByStatus: undefined,
    dateStart: undefined,
    dateEnd: undefined
  } as OrderFiltersDto
}

const ordersStateReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case OrdersStateTypes.GET_ALL_ORDERS_SUCCESS:
      let allOrders = [...state.orders, ...action.payload];
      return {
        ...state,
        orders: allOrders
      }
    case OrdersStateTypes.UPDATE_ORDER_STATUS_SUCCESS:
      var updatedOrder = action.payload as CakeOrderDto;
      let indexOfOrder = state.orders.findIndex(x => x.id === updatedOrder.id);
      let allOrdersCp = state.orders;
      allOrdersCp[indexOfOrder] = updatedOrder;
      return {
        ...state,
        orders: allOrdersCp
      }
    case OrdersStateTypes.SET_ORDERSFILTERS:
      return {
        ...state,
        ordersFilters: {
          lastId: action.payload.lastId,
          filterByStatus: action.payload.filterByStatus,
          dateStart: action.payload.dateStart,
          dateEnd: action.payload.dateEnd,
        }
      }
    case OrdersStateTypes.SET_ORDERSFILTERS_LASTID:
      return {
        ...state,
        ordersFilters: {
          lastId: action.payload,
          filterByStatus: state.ordersFilters.filterByStatus,
          dateStart: state.ordersFilters.dateStart,
          dateEnd: state.ordersFilters.dateEnd,
        }
      }
    case OrdersStateTypes.ORDERS_CLEAR_ORDERS:
      return {
        ...state,
        orders: [] as Array<CakeOrderDto>
      }
    case OrdersStateTypes.ORDERS_CLEAR_STATE:
      return {
        ...state,
        orders: [] as Array<CakeOrderDto>,
        ordersFilters: {
          lastId: undefined,
          filterByStatus: undefined,
          dateStart: undefined,
          dateEnd: undefined
        } as OrderFiltersDto
      }
    default: {
      return state;
    }
  }
}

export default ordersStateReducer;