/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./../home-page.scss";
import "./../standard-page.scss";
import "./kreator-page.scss";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { setActivePanel } from "../../../redux/creatorState/creatorState.actions";
import { selectActivePanel, selectCakeBottom, selectCakeCover, selectCakeCoverDetails, selectCakeHasSmoothTop, selectCakeSide, selectCakeSize, selectCakeTastes, selectCakeTopAddons } from "../../../redux/creatorState/creatorState.selectors";

interface Props {}

const CreatorNav = (props: Props) => {
  const dispatch = useDispatch();
  const activePanel = useSelector(selectActivePanel, shallowEqual) as number;
  const cakeSize = useSelector(selectCakeSize, shallowEqual) as number;
  const cakeBottom = useSelector(selectCakeBottom, shallowEqual) as number;
  const cakeCover = useSelector(selectCakeCover, shallowEqual) as number;
  const cakeCoverDetails = useSelector(selectCakeCoverDetails, shallowEqual) as number;
  const cakeSide = useSelector(selectCakeSide, shallowEqual) as number;
  const cakeHasTopSmooth = useSelector(selectCakeHasSmoothTop, shallowEqual) as boolean;
  const cakeTastes = useSelector(selectCakeTastes, shallowEqual) as Array<number>;
  const cakeTopAddons = useSelector(selectCakeTopAddons, shallowEqual) as Array<number>;


  const handleSetActivePanel = (active: number) => {
    dispatch(setActivePanel(active))
  }

  const setNavClassNameIsActiveIsFinished = (navItem: number, activePanelCp: number): string => {
    const isActive = activePanelCp === navItem || (navItem === 0 && activePanel === undefined);
    const isFinished = setNavClassNameIsFinish(navItem, activePanelCp);
    return (isActive ? "active " : "") + (isFinished ? "finished" : "");
  }

  const setNavClassNameIsFinish = (navItem: number, activePanelCp: number): boolean => {
    let isFinished: boolean = false;
    if (navItem === 0 && cakeSize > 0) isFinished = true;
    if (navItem === 1 && cakeBottom >= 0) isFinished = true;
    if (navItem === 2 && cakeTastes && cakeTastes.length > 0) isFinished = true;
    if (navItem === 3 && cakeCover >= 0) isFinished = true;
    if (navItem === 4 && cakeCoverDetails >= 0) isFinished = true;
    if (navItem === 5 && cakeSide >= 0) isFinished = true;
    if (navItem === 6 && ((cakeTopAddons && cakeTopAddons.length > 0) || cakeHasTopSmooth)) isFinished = true;

    return isFinished;
  }

  return (
    <Row>
      <p className="sub-nav">
        &nbsp;&nbsp;
        <span onClick={() => handleSetActivePanel(0)} className={setNavClassNameIsActiveIsFinished(0, activePanel)}>Rozmiar</span>&rsaquo;
        <span onClick={() => handleSetActivePanel(1)} className={setNavClassNameIsActiveIsFinished(1, activePanel)}>Spód</span>&rsaquo;
        <span onClick={() => handleSetActivePanel(2)} className={setNavClassNameIsActiveIsFinished(2, activePanel)}>Smaki</span>&rsaquo;
        <span onClick={() => handleSetActivePanel(3)} className={setNavClassNameIsActiveIsFinished(3, activePanel)}>Pokrycie</span>&rsaquo;
        <span onClick={() => handleSetActivePanel(4)} className={setNavClassNameIsActiveIsFinished(4, activePanel)}>Szczegóły&nbsp;pokrycia</span>&rsaquo;
        <span onClick={() => handleSetActivePanel(5)} className={setNavClassNameIsActiveIsFinished(5, activePanel)}>Boki</span>&rsaquo;
        <span onClick={() => handleSetActivePanel(6)} className={setNavClassNameIsActiveIsFinished(6, activePanel)}>Wierzch</span>&rsaquo;
        <span onClick={() => handleSetActivePanel(7)} className={setNavClassNameIsActiveIsFinished(7, activePanel)}>Podsumowanie</span>
      </p>
    </Row>
  )
}

export default CreatorNav;