import React, { useState } from "react";
import "./navbar-top.scss";
import Navbar from "react-bootstrap/Navbar";
import LogoImg from "./../../assets/logo.svg";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import DropdownItem from "react-bootstrap/DropdownItem";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { signOut } from "../../redux/user/user.actions";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import { RouteComponentProps, withRouter, Link } from "react-router-dom";
import { RoutePath } from "../../../route-paths";

const NavbarTop: React.FC<any> = (props: any & RouteComponentProps) => {
  const user = useSelector(selectCurrentUser, shallowEqual);

  const dispatch = useDispatch();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);

  const handleSignOut = async (e: any) => {
    e.preventDefault();
    dispatch(signOut());
  };

  return (
    <div className={props.isDisabled ? "navbarTop navbarTop--disabled " : "navbarTop"}>
      <Navbar bg="light">
        <Link to={RoutePath.HOME_PANEL}>
          <div className="brand">
            <img src={LogoImg} alt="LogoImg" />
          </div>
        </Link>
        <Dropdown show={dropdownOpen} onToggle={toggle} className="actionBtn" alignRight={true}>
          <DropdownToggle id="caret">
            <div className="actionBtnInsideWrapper">
              <p>Zalogowany {user ? user.email : null}</p>
            </div>
            <i className="fa fa-user-circle" aria-hidden="true"></i>
          </DropdownToggle>
          <DropdownMenu >
            <div className="accountMenuDropdown">
              <DropdownItem className="align-center" onClick={handleSignOut}><i className="fa fa-sign-out" aria-hidden="true" /> Wyloguj</DropdownItem>
            </div>
          </DropdownMenu>
        </Dropdown>
      </Navbar>
    </div >
  )
}

export default withRouter(NavbarTop);