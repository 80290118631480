import React, { ReactNode, useState } from "react";
import "./cart-page.scss";
import "./../home-page.scss";
import "./../standard-page.scss";

import { selectCartItems, selectClientData, selectDeliveryPanel, selectReceiverData } from "../../../redux/cartState/cartState.selectors";
import { CartItemDto, ClientDataDto, DeliveryPanelDto, DeliveryTypeEnum, isClientDataValid, isReceiverDataValid, ReceiverDataDto, ReceiverTypeEnum } from "../../../dto/cartItemDto";
import { useSelector, shallowEqual } from "react-redux";
import HomeFooter from "../home-footer";
import Helmet from "react-helmet";
import HomeNav from "../home-nav";
import Container from "react-bootstrap/Container";

import CartSteps from "./cart-steps";
import CartProductsPanel from "./cart-panels/cart-products-panel";
import CartSummaryPanel from "./cart-panels/cart-summary-panel";
import CartDeliveryPanel from "./cart-panels/cart-delivery-panel";
import CartNextBtn from "./cart-next-btn";
import CartDataPanel from "./cart-panels/cart-data-panel";

interface Props {
  isSubNavHidden?: boolean;
  children?: ReactNode
}

const CartPage: React.FC<Props> = () => {
  const cartItems = useSelector(selectCartItems, shallowEqual) as Array<CartItemDto>;
  const deliveryPanel = useSelector(selectDeliveryPanel, shallowEqual) as DeliveryPanelDto;
  const clientData = useSelector(selectClientData, shallowEqual) as ClientDataDto;
  const receiverData = useSelector(selectReceiverData, shallowEqual) as ReceiverDataDto;

  const [panelValidator, setPanelValidator] = useState(0)

  const [activeCartStep, setActiveCartStep] = useState(0);

  const setActiveCartStepWithValidator = (step: number) => {
    if (activeCartStep === 0 && step === 1) {
      if (cartItems && cartItems.length > 0) {
        setActiveCartStep(step);
        setPanelValidator(0);
        return;
      }
      setPanelValidator(1);
      return;
    }
    if (activeCartStep === 1 && step === 2 && deliveryPanel) {
      if (deliveryPanel.deliveryTime !== null && deliveryPanel.deliveryTime !== undefined) {
        if (deliveryPanel.deliveryType === DeliveryTypeEnum.SelfRecive && deliveryPanel.deliveryShop !== undefined) {
          setPanelValidator(0);
          setActiveCartStep(step);
          return;
        }
        if (deliveryPanel.deliveryType === DeliveryTypeEnum.Delivery) {
          setPanelValidator(0);
          setActiveCartStep(step);
          return;
        }
      }
      setPanelValidator(2);
      return;
    }

    if (activeCartStep === 2 && step === 3 && deliveryPanel && deliveryPanel.deliveryType !== undefined) {
      if (!isClientDataValid(clientData, (deliveryPanel.deliveryType === DeliveryTypeEnum.Delivery && receiverData.receiverType === ReceiverTypeEnum.Client))) {
        setPanelValidator(3);
        return;
      }
      if (!(receiverData && receiverData.receiverType !== undefined) || !isReceiverDataValid(receiverData, deliveryPanel.deliveryType, true)) {
        setPanelValidator(4);
        return;
      }
      setPanelValidator(0);
      setActiveCartStep(step);
      return;
    }
    if (activeCartStep > step) {
      setPanelValidator(0);
      setActiveCartStep(step);
      return;
    }
    //TODO VALIDATION
  }

  return (
    <div className="home-page standard-page kreator-page cart-page">
      <Helmet>
        <title>{'Torty standardowe | TORTY.COM'}</title>
      </Helmet>
      <HomeNav />
      <div className="overlayLoader"></div>
      <section className="trustSection">
        <Container>
          <h1>Twój koszyk</h1>
          <CartSteps activeCartStep={activeCartStep} setActiveCartStepWithValidator={setActiveCartStepWithValidator} />
        </Container>
      </section>
      <section className="togetherSection">
        <Container className={"cartItemsWrapper"}>
          {activeCartStep === 0 ? <CartProductsPanel isValidatorActive={panelValidator > 0} /> : null}
          {activeCartStep === 1 ? <CartDeliveryPanel isValidatorActive={panelValidator > 1} /> : null}
          {activeCartStep === 2 ? <CartDataPanel isValidatorActive={panelValidator > 2} /> : null}
          {activeCartStep === 3 ? <CartSummaryPanel isValidatorActive={panelValidator > 4} /> : null}
          <CartNextBtn activeCartStep={activeCartStep} setActiveCartStepWithValidator={setActiveCartStepWithValidator} />
          <br />
          <br />
          <br />
        </Container>
      </section>
      {/* <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <HomeContactSection /> */}
      <HomeFooter />
    </div>

  )
}

export default CartPage;