import { takeLatest, all, call, select } from "redux-saga/effects";
import { ApiMethodDto } from "../../api/api-method-dto";
import { ApiPath } from "../../api/apiPath";
import { callApi } from "../../api/api-call-service";
import CartStateTypes from "./cartState.types";
import { JaworscyStore } from "../store.types";
import { store } from "../store";
import MemoCallbacksStateTypes from "../memoCallbacksState/memoCallbacksState.types";
import { CartStateDto } from "../../dto/cartStateDto";

import { loadStripe, Stripe } from '@stripe/stripe-js';

function* createCheckoutSessionAsync() {
  const cart: CartStateDto = yield select((state: JaworscyStore) => state.cartState);

  try {
    const options = {
      body: cart,
      headers: { AccessControlAllowOrigin: "*", "Content-Type": "application/json" },
      method: "POST",
      expectedStatus: 200
    } as ApiMethodDto;

    const res = yield call(callApi, options.method, ApiPath.payment_createCheckoutSession, options);
    if (res.details.status === options.expectedStatus) {
      const stripe = (yield loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string)) as Stripe;

      store.dispatch({
        type: MemoCallbacksStateTypes.WAITING_FOR_PAYMENT,
        payload: true
      });
      
      stripe.redirectToCheckout({
        sessionId: res.data.id,
      })

      // console.log(typeof stripe);
      // Redirects away from the client
      // stripe.confirmP24Payment(
      //   res.data.client_secret,
      //   {
      //     payment_method: {
      //       billing_details: {
      //         email: cart.dataPanel.clientData.email,
      //         name: cart.dataPanel.clientData.name + cart.dataPanel.clientData.surname
      //       }
      //     },
      //     return_url: 'https://your-website.com/checkout/complete',
      //   }
      // ).then(function (result: any) {
      //   if (result.error) {
      //     console.log(result);
      //     // Inform the customer that there was an error.
      //   }
      // });


    }
  } catch (error) {
    console.error("Something went wrong.", error);
  }
}

function* watchCreateCheckoutSession() {
  yield takeLatest(CartStateTypes.CREATE_CHECKOUT_SESSION as any, createCheckoutSessionAsync);
}

export default function* CartStateSagas() {
  yield all([
    call(watchCreateCheckoutSession),
  ]);
}