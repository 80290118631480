

import React from "react";
import "./product-thumbnail.scss";
import TopLink from "../top-link/top-link";

interface Props {
  title: string,
  description?: string,
  image: string,
  buttonText: string,
  buttonLink: string,

}

const ProductThumbnail: React.FC<Props> = (props: Props) => {
  const { title, description, image, buttonText, buttonLink } = props;

  return (
    <div className="col-md-6 productThumbnail">
      <div className="card mb-4 shadow-sm">
        <TopLink to={buttonLink}>
          <img className="bd-placeholder-img card-img-top" src={image} alt={title} />
        </TopLink>
        <div className="card-body px-5 py-5">
          <h3>{title}</h3>
          <p className="card-text py-2">{description}</p>
          <div className="d-flex justify-content-between align-items-center">
            <div className="btn-group">
              <TopLink to={buttonLink}>
                <button type="button" className="btn">{buttonText}</button>
              </TopLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductThumbnail;
