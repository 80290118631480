import { all, call } from 'redux-saga/effects';
import UserSagas from './user/user.sagas';
import LeadsStateSagas from './leadsState/leadsState.sagas';
import ProductsStateSagas from './productsState/productsState.sagas';
import CartStateSagas from './cartState/cartState.sagas';
import OrdersStateSaga from './ordersState/ordersState.sagas';


export default function* rootSaga() {
  yield all([
    call(UserSagas),
    call(LeadsStateSagas),
    call(OrdersStateSaga),
    call(ProductsStateSagas),
    call(CartStateSagas),
  ]);
}