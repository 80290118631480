import React from "react";
import "./orders-panel.scss";
import Col from "react-bootstrap/Col";
import { CakeOrderDto, ProductItemDto } from "../../../../dto/cakeOrderDto";
import { CreatorBottomsList, CreatorTastesList, CreatorCoversList, CreatorSidesList, CreatorTopAddonsList } from "../../../home-page/creator-page/creator-assets/creatorData/creator.data";

interface Props {
  order: CakeOrderDto;
  index: number
}

const OrderDetailsCol: React.FC<Props> = (props: Props) => {

  var { order, index } = props;

  return (
    <Col xl={{ span: 3 }} className="cell" key={index}>
      {order.productItems.slice(0, -1).map((item: ProductItemDto, productItemIndex: number) => {
        return <p key={productItemIndex}>
          <span className="medium">{item.name}</span>
          <span className="tile tile--left">
            Rozmiar: {item.productItemCake.cakeSize}kg
              <br />
              Spód: {CreatorBottomsList[item.productItemCake.cakeBottom].name}
            <br />
              Smaki: {
              item.productItemCake.cakeTastes.split(",").map((taste: string, indexx: number) => {
                return <span key={indexx}>
                  {CreatorTastesList[Number(taste)].name}{indexx < (item.productItemCake.cakeTastes.split(",").length - 1) ? ", " : null}</span>
              })
            }
            <br />
              Pokrycie: {CreatorCoversList[item.productItemCake.cakeCover].name}, {CreatorCoversList[item.productItemCake.cakeCover].details[item.productItemCake.cakeCoverDetails].name}
            <br />
              Boki: {CreatorSidesList[item.productItemCake.cakeSide].name}
            <br />
              Wierzch: {
              item.productItemCake.cakeTopAddons.split(",").map((topAddons: string, indexx: number) => {
                return <span key={indexx}>{CreatorTopAddonsList[Number(topAddons)].name}{indexx < (item.productItemCake.cakeTopAddons.split(",").length - 1) ? ", " : null}</span>
              })
            }
            {
              item.productItemCake.cakeSmoothTop ? "Gładki" : ""
            }
            <br />
            <br />
              Dedykacja: {item.productItemCake.dedication}
            <br />
            <br />
              Świeczki pojedyncze: {item.productItemCake.singleCandles}
            <br />
              Świeczki cyfry: {item.productItemCake.figureCandles}
          </span>
        </p>
      })}
    </Col>
  )
}

export default OrderDetailsCol;