/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef } from "react";
import "./home-page.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import FemaleChef from "./assets/female-chef.svg";
import { ToastsStore } from "react-toasts";

const HomeContactSection: React.FC<any> = () => {
  const KontaktLink = useRef<any>();
  
  const [disableSendButton, setDisableSendButton] = useState(false);
  const [contactMessage, setContactMessage] = useState("");
  const [contactEmail, setContactEmail] = useState("");

  const sendMessageToSupport = () => {

    setDisableSendButton(true);
    window.setTimeout(() => {
      setDisableSendButton(false);
    }, 1000);

    if (contactEmail === null || contactEmail === '') {
      ToastsStore.error("Uzupełnij email");
    }

    ToastsStore.error("Wiadomość zostałaby wysłana, ale w 77 linii home-page.tsx kod jest zakomentowany");

    // var sendMessageToSupportPromise = AuthController.sendMessageToSupport(contactEmail, contactMessage)
    // sendMessageToSupportPromise.then((a: any) => {
    //   setContactEmail("");
    //   setContactMessage("");
    //   ToastsStore.success("Wiadomość została wysłana.");
    // }, reason => {
    //   ToastsStore.error("Wystąpił problem z połączeniem, spróbuj później.");
    //   console.error(reason); // Error!
    // })

  }

  return (
    <section ref={KontaktLink} className="contactSection">
    <div className="titleBox">
      <h4>Masz jakieś pytania?</h4>
      <p>Skontaktuj się z nami, chętnie odpowiemy na Twoją wiadomość.</p>
    </div>
    <Container>
      <Row>
        <Col xl={{ span: 4, offset: 2 }} className="col authorCol">
          <br />
          <img src={FemaleChef} alt="" />
          <h5>Możesz też odwiedzić nas osobiście</h5>
          <p>ul. Krzemieniecka 47, 54-613 Wrocław</p>
          <p>email: biuro@jaworscy.pl</p>
          <p>telelefon: (+48) 713 739 524</p>
        </Col>
        <Col xl={{ span: 4 }} className="col contactCol">
          <h5>Formularz kontaktowy</h5>
          <div className="formBox">
            <Form onSubmit={(e: any) => e.preventDefault()}>
              <FormGroup>
                <Form.Control
                  placeholder="Twoja wiadomość"
                  onChange={(e: any) => setContactMessage(`${e.target.value}`)}
                  name="contactMessage"
                  type="text"
                  value={contactMessage}
                  as="textarea"
                  rows={10}
                />
              </FormGroup>
            </Form>
          </div>
          <div className="formBox">
            <Form onSubmit={(e: any) => e.preventDefault()}>
              <FormGroup>
                <Form.Control
                  placeholder="Twój email"
                  onChange={(e: any) => setContactEmail(`${e.target.value}`)}
                  name="contactEmail"
                  value={contactEmail}
                  type="text"
                />
              </FormGroup>
            </Form>
            <div className={disableSendButton ? "actionBtn actionBtn--dark actionBtn--disabled" : "actionBtn actionBtn--dark"} onClick={sendMessageToSupport} style={{ pointerEvents: disableSendButton ? 'none' : 'auto' }}>
              <span>Wyślij</span>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  </section>
  )
}

export default HomeContactSection;