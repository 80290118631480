const CreatorStateTypes = {
  SET_CAKEID: 'SET_CAKEID',

  SET_ACTIVEPANEL: 'SET_ACTIVEPANEL',

  SET_CAKESIZE: 'SET_CAKESIZE',
  SET_CAKEBOTTOM: 'SET_CAKEBOTTOM',
  SET_CAKECOVER: 'SET_CAKECOVER',
  SET_CAKECOVERDETAILS: 'SET_CAKECOVERDETAILS',
  SET_CAKESIDE: 'SET_CAKESIDE',

  ADD_CAKETASTE: 'ADD_CAKETASTE',
  SET_CAKETASTE: 'SET_CAKETASTE',
  DEL_CAKETASTE: 'DEL_CAKETASTE',

  ADD_CAKETOPADDONS: 'ADD_CAKETOPADDONS',
  SET_CAKETOPADDONS: 'SET_CAKETOPADDONS',
  DEL_CAKETOPADDONS: 'DEL_CAKETOPADDONS',

  SET_CAKEHASSMOOTHTOP: 'SET_CAKEHASSMOOTHTOP',

  SET_DEDICATION: 'SET_DEDICATION',
  SET_SINGLECANDLES: 'SET_SINGLECANDLES',
  SET_FIGURECANDLES: 'SET_FIGURECANDLES',

  CLEAR_STATE: 'CLEAR_STATE',
  RESTORE_STATE: 'RESTORE_STATE'
};

export default CreatorStateTypes;