import { createSelector } from 'reselect';
import { JaworscyStore } from '../store.types';

const ordersState = (state: JaworscyStore) => state.ordersState;

export const selectOrders = createSelector(
  [ordersState],
  ordersState => {
    return ordersState.orders
  }
);

export const selectOrder = (index: number) => {
  return createSelector(
    [ordersState],
    ordersState => {
      return ordersState.orders[index]
    }
  );
}

export const selectOrdersFilters = createSelector(
  [ordersState],
  ordersState => {
    return ordersState.ordersFilters
  }
);
