import ProductsStateTypes from './productsState.types';
import { ProductDto } from '../../dto/productDto';

const INITIAL_STATE = {
  products: [] as Array<ProductDto>,
}

const productsStateReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case ProductsStateTypes.GET_ALL_PRODUCTS_SUCCESS:
      let allProducts = [...state.products, ...action.payload];
      return {
        ...state,
        products: allProducts
      }
    default: {
      return state;
    }
  }
}

export default productsStateReducer;