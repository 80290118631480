


import React, { useEffect, useState } from "react";
import "./slide-overlay.scss";
import history from './../../../history';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { select_is_slideOverlayActive } from "../../redux/callbacksState/callbacksState.selectors";
import { hideSlideOverlay } from "../../redux/callbacksState/callbacksState.actions";

interface Props {
}

const SlideOverlay: React.FC<Props> = (props: Props) => {
  const [slideAnimation, setSlideAnimation] = useState(false)
  const [slideAnimationSecond, setSlideAnimationSecond] = useState(false)
  const slideOverlayState = useSelector(select_is_slideOverlayActive, shallowEqual);
  const dispatch = useDispatch()

  useEffect(() => {
    if (slideOverlayState.isActive === true) {
      showSlide();
    } else if (slideOverlayState.isActive === false) {
      hideSlide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slideOverlayState])

  const showSlide = () => {
    setSlideAnimation(true)
    setTimeout(() => {
      setSlideAnimationSecond(true);
    }, 100);
    setTimeout(() => {
      history.push(slideOverlayState.redirectTo as string);
      dispatch(hideSlideOverlay());
    }, 500);
  }

  const hideSlide = () => {
    setSlideAnimationSecond(false);
    setSlideAnimation(false)
  }

  return (
    <>
      <div className={slideAnimation ? "slide-overlay slide slideAnimation" : "slide-overlay slide"}></div>
      <div className={slideAnimationSecond ? "slide-overlay slideSecond slideAnimation" : "slide-overlay slideSecond"}></div>
    </>
  )
}

export default SlideOverlay;
