import React, { useEffect, useState } from "react";
import "./status-selector.scss";
import { CakeOrderDto } from "../../../../dto/cakeOrderDto";
import { OrderStatusEnum } from "../../../../dto/orderStatusEnum";
import SetStatusModal from "./setStatusModal";
import Col from "react-bootstrap/Col";
import { shallowEqual, useSelector } from "react-redux";
import { selectOrder } from "../../../../redux/ordersState/ordersState.selectors";

interface Props {
  index: number;
}

interface SubProps {
  order: CakeOrderDto;
  status: OrderStatusEnum;
  children: React.ReactNode;
  onClick?: any;
}

const StatusItem: React.FC<SubProps> = (props: SubProps) => {
  const { order, status: orderStatus, children, onClick } = props;
  return <div onClick={onClick} className={`status${order.orderStatus === orderStatus ? " active" : (order.orderStatus > orderStatus ? " finished" : "")}`}>{children}</div>
}

const StatusCol: React.FC<Props> = (props: Props) => {
  const { index } = props;
  const [isSetStatusModalOpen, setIsSetStatusModalOpen] = useState(undefined as undefined | OrderStatusEnum);
  const order = useSelector(selectOrder(index), shallowEqual) as CakeOrderDto

  useEffect(() => {
    // console.log("changed order", order.id)
  }, [order])

  return (

    <Col xl={{ span: 3 }} className="cell" key={index}>
      <span className="medium">Status</span>
      <div className="status-selector">
        <SetStatusModal
          order={order}
          isSetStatusModalOpen={isSetStatusModalOpen !== undefined}
          setIsSetStatusModalOpen={setIsSetStatusModalOpen}
          status={isSetStatusModalOpen} />
        {/* //TODO - /\ /\ /\ /\ /\ /\ /\ ten status trzeba przekazywać z propsów (prawdopodobnei) /\ */}
        {order.orderStatus === OrderStatusEnum.Canceled ?
          <StatusItem order={order} status={OrderStatusEnum.Canceled}>
            Zamówienie anulowane
          </StatusItem>
          : (
            <>
              <StatusItem onClick={() => {
                setIsSetStatusModalOpen(OrderStatusEnum.PaymentIntent);
              }} order={order} status={OrderStatusEnum.PaymentIntent}>Zamówienie opłacone</StatusItem>
              <StatusItem onClick={() => {
                setIsSetStatusModalOpen(OrderStatusEnum.InRealization);
              }} order={order} status={OrderStatusEnum.InRealization}>Zamówienie w realizacji</StatusItem>
              <StatusItem onClick={() => {
                setIsSetStatusModalOpen(OrderStatusEnum.RealizedAndWaitingForDelivery);
              }} order={order} status={OrderStatusEnum.RealizedAndWaitingForDelivery}>Zrealizowane i przekazane kurierowi</StatusItem>
              <StatusItem onClick={() => {
                setIsSetStatusModalOpen(OrderStatusEnum.RealizedAndWaitingForReceiver);
              }} order={order} status={OrderStatusEnum.RealizedAndWaitingForReceiver}>Dostarczone do wskazanego lokalu</StatusItem>
              <StatusItem onClick={() => {
                setIsSetStatusModalOpen(OrderStatusEnum.Received);
              }} order={order} status={OrderStatusEnum.Received}>Zamówienie odebrane</StatusItem>
            </>
          )}
      </div>
    </Col>
  )
}

export default StatusCol;