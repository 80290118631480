import { takeLatest, put, all, call, select } from "redux-saga/effects";
import UserActionTypes from "./user.types";
import { SignUpDto } from "../../dto/sign-up-dto";
import { ToastsStore } from "react-toasts";
import { UserDto, UserRole } from "../../dto/user-dto";
import { SignInDto } from "../../dto/sign-in-dto";
import { ApiMethodDto } from "../../api/api-method-dto";
import { ApiPath } from "../../api/apiPath";
import { callApi } from "../../api/api-call-service";
import LeadsStateActionTypes from "../leadsState/leadsState.types";
import CallbacksStateTypes from "../callbacksState/callbacksState.types";

function* signUpAsync(credentials: any) {
  const options = {
    body: credentials.payload as SignUpDto,
    headers: { "Content-Type": "application/json" },
    method: "POST",
    expectedStatus: 201
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "post", ApiPath.auth_signUp, options);
    
    yield put({ type: CallbacksStateTypes.SIGNUP_FINISHED, payload: true });
    yield put({ type: CallbacksStateTypes.SIGNUP_FINISHED, payload: false });

    var rolePlanIndex = res.data.roles.findIndex((x: UserRole) => x.stripePlan != null);

    yield put({
      type: UserActionTypes.SIGN_IN_SUCCESS,
      payload: {
        userId: res.data.userId,
        email: credentials.payload.email,
        suggestlyEntities: res.data.userSuggestlyEntities,
        roles: res.data.roles.map((item: any) => { return item }),
        rolePlan: rolePlanIndex ? res.data.roles[rolePlanIndex] : null,
        token: "Bearer " + res.data.token.toString(),
        lastRequestTime: Number(Date.UTC),
        expiresStart: res.data.expiresStart,
        expiresEnd: res.data.expiresEnd,
      } as UserDto
    });
    
    ToastsStore.success('Sign Up Success');

  } catch (error) {
    yield put({ type: CallbacksStateTypes.SIGNUP_FINISHED, payload: true });
    yield put({ type: CallbacksStateTypes.SIGNUP_FINISHED, payload: false });
    yield put({ type: UserActionTypes.AUTH_FAILURE });
    console.error('Something went wrong. ', error)
  }
}

function* signInAsync(credentials: any) {
  const options = {
    body: credentials.payload as SignInDto,
    headers: { "Content-Type": "application/json" },
    method: "POST",
    expectedStatus: 200
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "post", ApiPath.auth_signIn, options);
    
    yield put({ type: CallbacksStateTypes.SIGNIN_FINISHED, payload: true });
    yield put({ type: CallbacksStateTypes.SIGNIN_FINISHED, payload: false });

    var rolePlanIndex = res.data.roles.findIndex((x: UserRole) => x.stripePlan != null);

    yield put({
      type: UserActionTypes.SIGN_IN_SUCCESS,
      payload: {
        userId: res.data.userId,
        email: credentials.payload.email,
        suggestlyEntities: res.data.userSuggestlyEntities,
        roles: res.data.roles.map((item: any) => { return item }),
        rolePlan: rolePlanIndex ? res.data.roles[rolePlanIndex] : null,
        token: "Bearer " + res.data.token.toString(),
        lastRequestTime: Date.UTC,
        expiresStart: res.data.expiresStart,
        expiresEnd: res.data.expiresEnd,
      }
    });

    ToastsStore.success('Sign In Success');
  } catch (error) {
    yield put({ type: CallbacksStateTypes.SIGNIN_FINISHED, payload: true });
    yield put({ type: CallbacksStateTypes.SIGNIN_FINISHED, payload: false });

    yield put({ type: UserActionTypes.SIGN_IN_FAILURE });
    console.error('Something went wrong. ', error)
  }
}

function* refreshTokenAsync() {
  const token = yield select(state => state.user.token);

  try {
    const options = {
      body: '',
      headers: { Authorization: token, "Access-Control-Allow-Origin": "*" },
      method: "GET",
      expectedStatus: 200
    } as ApiMethodDto;

    const res = yield call(callApi, "get", ApiPath.auth_refreshToken, options);

    yield put({
      type: UserActionTypes.TOKEN_REFRESH_SUCCESS,
      payload: { token: res.data.token }
    });

  } catch (error) {
    yield put({ type: UserActionTypes.TOKEN_REFRESH_FAILURE, message: 'Something went wrong.' });
    console.error('Something went wrong. ', error)
  }
}

function* signOutAsync() {
  const token = yield select(state => state.user.token);

  try {
    const options = {
      body: null,
      headers: { Authorization: token, "Access-Control-Allow-Origin": "*" },
      method: "GET",
      expectedStatus: 200
    } as ApiMethodDto

    call(callApi, "get", ApiPath.auth_signOut, options);

    ToastsStore.success('Sign Out Success');

    yield put({ type: UserActionTypes.USER_CLEAR_STORE });

  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* getUserRolesAsync() {
  const token = yield select(state => state.user.token);
  const options = {
    headers: { Authorization: token },
    method: "GET",
    expectedStatus: 200,
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "get", ApiPath.auth_getUserRoles, options);

    var rolePlanIndex = res.data.findIndex((x: UserRole) => x.stripePlan != null);

    yield put({
      type: UserActionTypes.GET_USERROLES_SUCCESS,
      payload: {
        roles: res.data.map((item: any) => { return item }),
        rolePlan: rolePlanIndex ? res.data[rolePlanIndex] : null
      }
    });

  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* clearStoreAsync() {
  yield put({ type: UserActionTypes.SIGN_OUT_SUCCESS });
  yield put({ type: LeadsStateActionTypes.LEADS_CLEAR_STATE });
}

function* watchTokenRefresh() {
  yield takeLatest(UserActionTypes.REFRESH_TOKEN as any, refreshTokenAsync);
}

function* watchSignUp() {
  yield takeLatest(UserActionTypes.AUTH_SIGNUP as any, signUpAsync);
}

function* watchSignIn() {
  yield takeLatest(UserActionTypes.SIGN_IN as any, signInAsync);
}

function* watchSignOut() {
  yield takeLatest(UserActionTypes.SIGN_OUT as any, signOutAsync);
}

function* watchClearStore() {
  yield takeLatest(UserActionTypes.USER_CLEAR_STORE as any, clearStoreAsync);
}

function* watchGetUserRoles() {
  yield takeLatest(UserActionTypes.GET_USERROLES as any, getUserRolesAsync);
}

export default function* userSagas() {
  yield all([
    call(watchGetUserRoles),
    call(watchClearStore),
    call(watchSignUp),
    call(watchSignIn),
    call(watchTokenRefresh),
    call(watchSignOut)]);
}
