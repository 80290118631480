import { createSelector } from 'reselect';
import { JaworscyStore } from '../store.types';
import { OfferTypeEnum } from '../../dto/productDto';

const productsState = (state: JaworscyStore) => state.productsState;

// export const selectProducts = createSelector(
//   [productsState],
//   productsState => {
//     return productsState.products
//   }
// );

export const selectProducts = (offerType: OfferTypeEnum) => createSelector(
  [productsState],
  productsState => {
    return productsState.products.filter(x=>x.offerType === offerType)
  }
);