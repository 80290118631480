import React from "react";
import "./../../cart-page.scss";
import "./../cart-panel.scss";

import Form from "react-bootstrap/Form";
import { ReceiverDataDto } from "../../../../../dto/cartItemDto";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setReceiverData } from "../../../../../redux/cartState/cartState.actions";
import { selectReceiverData } from "../../../../../redux/cartState/cartState.selectors";
import { validateEmail } from "../../../../../tools/emailValidator";

interface Props {
  isValidationActive: boolean
}

const CartDataSelfReciveForm: React.FC<Props> = (props: Props) => {
  // const [deliveryReceiver, setDeliveryReceiver] = useState({
  //   name: '',
  //   surname: '',
  //   phone: '',
  //   email: '',
  //   message: '',
  // } as ReceiverDataDto);


  // const setInputValue = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  //   const { name, value } = e.target;
  //   setDeliveryReceiver({ ...deliveryReceiver, [name]: value } as ReceiverDataDto);
  // }

  const dispatch = useDispatch();
  const receiverData = useSelector(selectReceiverData, shallowEqual) as ReceiverDataDto
  
  const { isValidationActive } = props;

  const setInputValue = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    dispatch(setReceiverData({ ...receiverData, [name]: value } as ReceiverDataDto));
  }

  return (
    <form onSubmit={e => { e.preventDefault(); }}>
      <div className="input-wrapper">
        <Form.Label>Imię odbiorcy</Form.Label>
        <Form.Control
          className="form-control-alternative"
          name="name"
          type="text"
          isValid={isValidationActive && !(!receiverData.name || 0 === receiverData.name.length)}
          isInvalid={isValidationActive && (!receiverData.name || 0 === receiverData.name.length)}
          placeholder="Imię odbiorcy"
          value={receiverData.name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e)}
          required
        />
      </div>
      <div className="input-wrapper">
        <Form.Label>Nazwisko odbiorcy</Form.Label>
        <Form.Control
          className="form-control-alternative"
          name="surname"
          type="text"
          isValid={isValidationActive && !(!receiverData.surname || 0 === receiverData.surname.length)}
          isInvalid={isValidationActive && (!receiverData.surname || 0 === receiverData.surname.length)}
          placeholder="Nazwisko odbiorcy"
          value={receiverData.surname}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e)}
          required
        />
      </div>
      <div className="input-wrapper">
        <Form.Label>Telefon odbiorcy</Form.Label>
        <Form.Control
          className="form-control-alternative"
          name="phone"
          type="text"
          isValid={isValidationActive && !(!receiverData.phone || receiverData.phone.length < 6 || receiverData.phone.length > 17)}
          isInvalid={isValidationActive && (!receiverData.phone || receiverData.phone.length < 6 || receiverData.phone.length > 17)}
          placeholder="Telefon odbiorcy"
          value={receiverData.phone}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e)}
          required
        />
      </div>
      <div className="input-wrapper">
        <Form.Label>Email odbiorcy</Form.Label>
        <Form.Control
          className="form-control-alternative"
          name="email"
          type="email"
          isValid={isValidationActive && !(!receiverData.email || 0 === receiverData.email.length || !validateEmail(receiverData.email))}
          isInvalid={isValidationActive && (!receiverData.email || 0 === receiverData.email.length || !validateEmail(receiverData.email))}
          placeholder="Email odbiorcy"
          value={receiverData.email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e)}
          required
        />
      </div>
      {/* <div className="input-wrapper">
        <Form.Label>Informacje dodatkowe</Form.Label>
        <Form.Control
          className="form-control-alternative"
          name="message"
          type="text"
          placeholder="Informacje dla dostawcy"
          value={receiverData.message}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e)}
        />
      </div> */}
    </form>
  )
}

export default CartDataSelfReciveForm;