import MemoCallbacksStateTypes from './memoCallbacksState.types';

const INITIAL_STATE = {
  is_waiting_for_payment: false as boolean,
}

const memoCallbacksStateReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case MemoCallbacksStateTypes.WAITING_FOR_PAYMENT:
      return {
        ...state,
        is_waiting_for_payment: action.payload
      }
    default: {
      return state;
    }
  }
}

export default memoCallbacksStateReducer;