import React from "react";
import { useDispatch } from "react-redux";
import { createCheckoutSession } from "../../../redux/cartState/cartState.actions";

interface Props {
  activeCartStep: number;
  setActiveCartStepWithValidator: (step: number) => void;
}

const CartNextBtn: React.FC<Props> = (props: Props) => {
  // const cartItems = useSelector(selectCartItems, shallowEqual) as Array<CartItemDto>
  const dispatch = useDispatch();

  const { activeCartStep, setActiveCartStepWithValidator } = props;

  return (
    <div className="nextBtnWrapper">
      {activeCartStep === 3 ?
        <div className={`btn btn-dark`} onClick={() => dispatch(createCheckoutSession())}>
          &nbsp;&nbsp;Zapłać&nbsp;&nbsp;&nbsp;&rsaquo;&nbsp;
        </div>
        :
        <div className={`btn btn-dark`} onClick={() => setActiveCartStepWithValidator(activeCartStep + 1)}>
          &nbsp;&nbsp;Dalej&nbsp;&nbsp;&nbsp;&rsaquo;&nbsp;
        </div>
      }
    </div>
  )
}

export default CartNextBtn;