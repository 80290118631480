import React, { } from "react";
import "./../cart-page.scss";
import "./../../home-page.scss";
import "./../../standard-page.scss";

import { selectCartItems } from "../../../../redux/cartState/cartState.selectors";
import { CartItemDto } from "../../../../dto/cartItemDto";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { deleteCartItem } from "../../../../redux/cartState/cartState.actions";
import TopLink from "../../../../components/top-link/top-link";

import CandleNumIcon from "./../cart-assets/candleNum.svg"
import CandleSingIcon from "./../cart-assets/candleSing.svg"
import CartCakeIcon from "./../cart-assets/cartCake.svg"
import { CreatorPrices } from "../../creator-page/creator-assets/creatorData/creator.data";

interface Props {
  isValidatorActive: boolean;
}

const CartProductsPanel: React.FC<Props> = (props: Props) => {
  const cartItems = useSelector(selectCartItems, shallowEqual) as Array<CartItemDto>
  const dispatch = useDispatch();

  return (
    <>
      {cartItems && cartItems.length > 0 ?
        <>
          <div className="cartItem">
            <div className="cartItem__product">
              <div className="cartItem__product__title">Zawartość Twojego koszyka</div>
            </div>
          </div>
          {
            cartItems.map((item: CartItemDto, index: number) => {
              return (
                <div key={index}>
                  <div className="cartItem" key={index}>
                    <div className="cartItem__img">
                      <img src={CartCakeIcon} alt={CartCakeIcon} />
                    </div>
                    <div className="cartItem__details">
                      <div className="cartItem__product">
                        <div className="cartItem__product__title">
                          {item.title} - {((CreatorPrices.sizePerKg * item.creatorResult.cakeSize) + (item.creatorResult.dedication ? CreatorPrices.dedication : 0)).toFixed(2)} zł
                        </div>
                        <div className="cartItem__product__description">
                          {item.description}
                        </div>
                      </div>

                      <div className="cartItem__addonProduct__wrapper">
                        {item.creatorResult.figureCandles && item.creatorResult.figureCandles > 0 ?
                          <div className="cartItem__addonProduct">
                            <div className="cartItem__addonProduct__img">
                              <img src={CandleNumIcon} alt={CandleNumIcon} />
                            </div>
                            <div className="cartItem__addonProduct__product">
                              <div className="cartItem__addonProduct__product__title">
                                Świeczka cyfra - {CreatorPrices.figureCandles}zł/szt
                              </div>
                              <div className="cartItem__addonProduct__product__description">
                                {/* .map((n: any) => { return n + ','; }) */}
                                Sztuk: {item.creatorResult.figureCandles.toString().length},&nbsp;
                                Cyfry: {[...(item.creatorResult.figureCandles.toString().split(''))].join(', ')}
                              </div>
                            </div>
                          </div>
                          : null}
                        {item.creatorResult.singleCandles && item.creatorResult.singleCandles > 0 ?
                          <div className="cartItem__addonProduct">
                            <div className="cartItem__addonProduct__img">
                              <img src={CandleSingIcon} alt={CandleSingIcon} />
                            </div>
                            <div className="cartItem__addonProduct__product">
                              <div className="cartItem__addonProduct__product__title">
                                Świeczka pojedyncza - {CreatorPrices.singleCandles}zł/szt
                              </div>
                              <div className="cartItem__addonProduct__product__description">
                                Sztuk: {item.creatorResult.singleCandles}
                              </div>
                            </div>
                          </div>
                          : null}
                      </div>
                    </div>
                    <div className="cartItem__footer">
                      <div className="cartItem__delete">
                        <TopLink to={item.creatorResult.pathToEdit}>
                          <Button variant={'outline-dark'}>Edytuj</Button>
                        </TopLink>&nbsp;&nbsp;
                        <Button variant={'outline-dark'} onClick={() => dispatch(deleteCartItem(item.id))}>X</Button>
                      </div>
                      <div className="cartItem__price">{CreatorPrices.sum(item.creatorResult.cakeSize, item.creatorResult.dedication, item.creatorResult.singleCandles, item.creatorResult.figureCandles).toFixed(2)} zł</div>
                    </div>
                  </div>
                  <p>
                  </p>
                </div>
              )
            })
          } </> :
        <div className="cartItem">
          <div className="cartItem__product">
            <div className="cartItem__product__title">Twój koszyk jest pusty.</div>
          </div>
        </div>}

      {props.isValidatorActive ?
        <div className={"cartItem cart-panel"}>
          <div className="cartItem__product">
            <div className="cartItem__product__title">Nie można przejść dalej</div>
            Aby kontynuować dodaj produkty do koszyka.
          </div>
        </div> : null
      }
    </>
  )
}

export default CartProductsPanel;