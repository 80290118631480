/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { selectFigureCandles } from "../../../../redux/creatorState/creatorState.selectors";
import { setFigureCandles } from "../../../../redux/creatorState/creatorState.actions";


interface Props {
  isFigureCandlesModalOpen: boolean;
  setIsFigureCandlesModalOpen: any;
}

const FigureCandlesModal = (props: Props) => {

  const { isFigureCandlesModalOpen, setIsFigureCandlesModalOpen } = props;
  const [modalFigureCandles, setModalFigureCandles] = useState(0);

  const figureCandles = useSelector(selectFigureCandles, shallowEqual) as number;

  const dispatch = useDispatch();

  useEffect(() => {
    setModalFigureCandles(figureCandles ? figureCandles : 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFigureCandlesModalOpen])

  return (
    <Modal size={'sm'} show={isFigureCandlesModalOpen} onHide={() => setIsFigureCandlesModalOpen(false)} className="addEditDedicationModal">
      <Modal.Header closeButton>
        <p className={'modalTitle'}>Świeczki cyfry</p>
      </Modal.Header>
      <Modal.Body className="addEditDedication">
        <form onSubmit={e => { e.preventDefault(); }}>
          <Form.Label>
            Liczba, którą mają reprezentować cyfry
          </Form.Label>
          <Form.Control
            className="form-control-alternative"
            name="figureCandles"
            autoFocus
            step={1}
            min={0}
            max={120}
            type="number"
            placeholder="Liczba ze świeczek"
            value={modalFigureCandles}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setModalFigureCandles(Number(e.target.value))}
            required
          />
        </form>
        <p className={'invalid-message'}>{modalFigureCandles >= 0 ? modalFigureCandles : 0} / 120</p>
        {modalFigureCandles > 120 ? <p className={'invalid-message'}>Przekroczono zakres</p> : null}
        {modalFigureCandles < 0 ? <p className={'invalid-message'}>Minimalna wartość to 0</p> : null}
      </Modal.Body>
      <Button variant="dark" onClick={() => {
        if (modalFigureCandles > 0 && modalFigureCandles <= 120) {
          dispatch(setFigureCandles(Number(modalFigureCandles.toFixed())));
          setIsFigureCandlesModalOpen(false)
        } else if (modalFigureCandles > 120) {
          setModalFigureCandles(120);
          dispatch(setFigureCandles(120));
          setIsFigureCandlesModalOpen(false)
        } else if (modalFigureCandles <= 0) {
          setModalFigureCandles(0);
          dispatch(setFigureCandles(undefined));
          setIsFigureCandlesModalOpen(false)
        }
      }}>
        {figureCandles ? 'Zapisz' : 'Dodaj'}
      </Button>
      <Button size={'sm'} variant="primary" onClick={() => {
        setModalFigureCandles(figureCandles);
        setIsFigureCandlesModalOpen(false)
      }}>
        Anuluj
    </Button>
    </Modal>
  )
}

export default FigureCandlesModal;