/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./kreator-summary.scss";

const SummaryItem: React.FC<{ name: string, children: React.ReactNode }> = (props: { name: string, children: React.ReactNode }) => {
  return (
    <div className="summaryItem">
      <div className="summaryItem__name">{props.name}</div>
      <div className="summaryItem__Value">
        {props.children}
      </div>
    </div>
  )
}

export default SummaryItem;