/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef } from "react";
import "./home-page.scss";
import "./standard-page.scss";

import Helmet from "react-helmet";
import { RoutePath } from "../../../route-paths";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


// import MaleChef from "./assets/male-chef.svg";
import MaleChef from "./assets/male-chef.jpg";


// import BakeOne from "./assets/bake1.jpg";
// import BakeTwo from "./assets/bake2.jpg";
// import BakeThree from "./assets/bake3.jpg";
// import BakeFour from "./assets/bake4.jpg";



import OfertaC from "./assets/amazon.jpg";

import ProductThumbnail from "../../components/product-thumbnail/product-thumbnail";
import HomeNav from "./home-nav";
import HomeContactSection from "./home-contact-section";
import HomeFooter from "./home-footer";
import SweetDream from "./sweet-dream";
import HomeInsideNav from "./home-inside-nav";
import { useSelector, shallowEqual } from "react-redux";
import { selectProducts } from "../../redux/productsState/productsState.selectors";
import { OfferTypeEnum, ProductDto } from "../../dto/productDto";
import { handleScrollToSection } from "../../tools/handleScrollToSection";

const DlafirmPage: React.FC<any> = () => {
  const products = useSelector(selectProducts(OfferTypeEnum.Business), shallowEqual) as Array<ProductDto>

  const showOffer = useRef<any>();

  return (
    <div className="home-page standard-page">
      <Helmet>
        <title>{'Torty standardowe | TORTY.COM'}</title>
        {/* <meta name="description" content="Blog interaktywny. Praca kreatywna od kuchni, ukazana z różnych perspektyw. Programowanie, Design, Biznes, Seo, Teamwork, Strony internetowe, Aplikacje webowe i wiele innych." />
          <meta name="keywords" content="narzędzia,design,komunikacja,teamwork,responsive" /> */}
      </Helmet>

      <HomeNav />

      <div className="overlayLoader"></div>

      <section className="mainSection">
        {/* style={{ backgroundImage: `url(${OfertaB})` }}> */}
        <Container>
          <Row>
            <Col md={{ span: 6 }}>
              <div>
                <h1>Realizujemy wielkie zamówienia firmowe</h1>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Mollitia accusantium nobis nostrum similique corporis autem architecto voluptate!</p>
                <div className="btn btn-outline-dark" onClick={() => handleScrollToSection(showOffer)}>Zobacz ofertę</div>
              </div>
            </Col>
            <Col md={{ span: 5, offset: 1 }}>
              <div className="box">
                <img src={OfertaC} alt="Wirtualny Asystent" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <div className="popularItems py-5" ref={showOffer}>
        <div className="container">
          <h2>Zobacz torty z realizacji firmowych</h2>
          <div className="row">
            {products && products.length ? products.map((product: ProductDto, index: number) => {
              return (
                <ProductThumbnail
                  image={product.images[0].url}
                  title={product.name}
                  description={product.description}
                  buttonText={"Zobacz szczegóły i kup"}
                  // buttonLink={(RoutePath.BUSINESSPRODUCT).split(':productName').join(product.url)}
                  buttonLink={(RoutePath.PRODUCT).split(':offerType').join('torty-dla-firm').split(':productName').join(product.url)}

                />
              )
            }) : null}
            {products && products.length ? products.map((product: ProductDto, index: number) => {
              return (
                <ProductThumbnail
                  image={product.images[0].url}
                  title={product.name}
                  description={product.description}
                  buttonText={"Zobacz szczegóły i kup"}
                  // buttonLink={(RoutePath.BUSINESSPRODUCT).split(':productName').join(product.url)}
                  buttonLink={(RoutePath.PRODUCT).split(':offerType').join('torty-dla-firm').split(':productName').join(product.url)}

                />
              )
            }) : null}
          </div>
        </div>
      </div>

      <section className="togetherSection">
        <Container>
          <Row>
            <Col xl={{ span: 5, offset: 1, order: 1 }} sm={{ order: 1 }} xs={{ order: 2 }} className="col">
              <div className="togetherJumbDescr">
                <h3>A może... <br /> szukasz czegoś innego?</h3>
                <p>Przez 30 lat zebraliśmy najlepsze przepisy, które umieścilismy dla Ciebie w ofercie standardowej. Realizujemy też torty okolicznościowe i dla firm.</p>
                <p><strong>Zobacz pełną ofertę &#8628;</strong></p>
              </div>
            </Col>
            <Col xl={{ span: 3, offset: 1, order: 2 }} sm={{ order: 2 }} xs={{ order: 1 }} className="col">
              <img src={MaleChef} alt="" />
            </Col>
          </Row>
          <Row>
            <Col xl={{ span: 5, offset: 1 }} className="col arrowRollDown">
              <div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <HomeInsideNav excluded={'dlafirm'} />
      <SweetDream />
      <HomeContactSection />
      <HomeFooter />
    </div>
  )
}

export default DlafirmPage;