const CallbacksStateTypes = {
  SIGNIN_FINISHED: 'SIGNIN_FINISHED',
  SIGNUP_FINISHED: 'SIGNUP_FINISHED',
  TOAST_FAILEDTOFETCH_ACTIVATED: 'TOAST_FAILEDTOFETCH_ACTIVATED',
  HIDE_GLOBAL_MODAL_SUCCESS: 'HIDE_GLOBAL_MODAL_SUCCESS',
  SHOW_GLOBAL_MODAL_SUCCESS: 'SHOW_GLOBAL_MODAL_SUCCESS',
  
  HIDE_SLIDE_OVERLAY_SUCCESS: 'HIDE_SLIDE_OVERLAY_SUCCESS',
  SHOW_SLIDE_OVERLAY_SUCCESS: 'SHOW_SLIDE_OVERLAY_SUCCESS',
};

export default CallbacksStateTypes;