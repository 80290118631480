import React from "react";
import { Switch, Route } from "react-router-dom";
import { RoutePath } from "../../../../route-paths";
import HomePanel from "./home-panel/home-panel";
import SideBar from "../../../components/sidebar/sidebar";
import NavbarTop from "../../../components/navbar-top/navbar-top";
import LeadsPanel from "./leads-panel/leads-panel";
import { useSelector, shallowEqual } from "react-redux";
import { selectCurrentUser } from "../../../redux/user/user.selectors";
import OrdersPanel from "./orders-panel/orders-panel";

const PanelSwitch: React.FC = () => {

  const currentUser = useSelector(selectCurrentUser, shallowEqual);

  return (
    <>
      <NavbarTop isPanelSwitch={true} />
      <div className="mainWrapper">
        <SideBar/>
        <div className="pagesPanel">
          {currentUser ?
            <Switch>
              <Route exact path={RoutePath.LEADS_PANEL} component={LeadsPanel} />
              <Route exact path={RoutePath.ORDERS_PANEL} component={OrdersPanel} />
              <Route component={HomePanel} />
            </Switch> : null
          }
        </div>
      </div>
    </>
  );
}

export default PanelSwitch;