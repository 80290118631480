import CallbacksStateTypes from './callbacksState.types';
import { ModalDto } from '../../dto/modalDto';
import { RoutePath } from '../../../route-paths';

const INITIAL_STATE = {
  is_signUp_finished: false as boolean,
  is_signIn_finished: false as boolean,
  is_globalToastFailedToFetchActive: false as boolean,
  is_slideOverlayActive: {
    isActive: false as boolean,
    redirectTo: ''
  },
  globalModal: {
    title: "",
    description: "",
    buttonTitle: "",
    path: RoutePath.HOME,
    isVisible: false
  } as ModalDto,
}

const callbacksStateReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case CallbacksStateTypes.SIGNIN_FINISHED:
      return {
        ...state,
        is_signIn_finished: action.payload
      }
    case CallbacksStateTypes.SHOW_SLIDE_OVERLAY_SUCCESS:
      return {
        ...state,
        is_slideOverlayActive: {
          isActive: true,
          redirectTo: action.payload
        }
      }
    case CallbacksStateTypes.HIDE_SLIDE_OVERLAY_SUCCESS:
      return {
        ...state,
        is_slideOverlayActive: {
          isActive: false,
          redirectTo: ''
        }
      }
    case CallbacksStateTypes.SIGNUP_FINISHED:
      return {
        ...state,
        is_signUp_finished: action.payload
      }
    case CallbacksStateTypes.TOAST_FAILEDTOFETCH_ACTIVATED:
      return {
        ...state,
        is_globalToastFailedToFetchActive: action.payload
      }
    case CallbacksStateTypes.SHOW_GLOBAL_MODAL_SUCCESS:
      return {
        ...state,
        globalModal: {
          title: action.payload.title,
          description: action.payload.description,
          buttonTitle: action.payload.buttonTitle,
          path: action.payload.path,
          isVisible: true
        }
      }
    case CallbacksStateTypes.HIDE_GLOBAL_MODAL_SUCCESS:
      return {
        ...state,
        globalModal: {
          title: "",
          description: "",
          buttonTitle: "",
          path: RoutePath.HOME,
          isVisible: false
        }
      }
    default: {
      return state;
    }
  }
}

export default callbacksStateReducer;