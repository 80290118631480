import React, { useEffect, useState } from "react";
import "./../cart-page.scss";
import "./cart-panel.scss";
import './react-calendar.scss';

import { selectDeliveryPanel } from "../../../../redux/cartState/cartState.selectors";
import { DeliveryPanelDto, DeliveryTypeEnum } from "../../../../dto/cartItemDto";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { setDeliveryPanel } from "../../../../redux/cartState/cartState.actions";


import Shop from "./../cart-assets/shop.svg"
import Truck from "./../cart-assets/truck.svg"
import Calendar from "react-calendar";
import { CartShopsList } from "./cartData/cart.data";
import { ShopPointDto } from "../../../../dto/shopPointDto";


interface Props {
  isValidatorActive: boolean;
}


const CartDeliveryPanel: React.FC<Props> = (props: Props) => {

  const disabledDates = [
    [2021, 1, 27],
    [2021, 1, 28],
  ]
  const isDateDisabled = (date: any): boolean => {
    // console.log(date.getFullYear(), date.getMonth(), date.getFullDay())
    // console.log((new Date(2021, 0, 27)).getTime())
    let isDateDisabled = false;
    const currentObjDate = new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime();
    for (let i = 0; i < disabledDates.length; i++) {
      if (currentObjDate === (new Date(disabledDates[i][0], disabledDates[i][1] - 1, disabledDates[i][2])).getTime()) {
        isDateDisabled = true;
      }
    }
    return isDateDisabled;
  }

  const minDate = new Date(Date.now() + (2 * 3600 * 1000 * 24));
  const maxDate = new Date(Date.now() + (3600 * 1000 * 24 * 360));
  const disabledSundays = (obj: { date: any }) => obj.date.getDay() === 0 || isDateDisabled(obj.date);
  const disabledCustomDays = (obj: { date: any }) => isDateDisabled(obj.date);

  //  === new Date(2021, 1, 27).getTime();//obj.date.getDay() === 0 || isDateDisabled(obj.date);

  const dispatch = useDispatch();

  const deliveryPanel = useSelector(selectDeliveryPanel, shallowEqual) as DeliveryPanelDto
  const [deliveryDate, setDeliveryDate] = useState((deliveryPanel.deliveryTime ? new Date(deliveryPanel.deliveryTime) : undefined) as Date | undefined);
  const [activeDeliveryMethod, setActiveDeliveryMethod] = useState(deliveryPanel.deliveryType as DeliveryTypeEnum | null);
  const [activeShop, setActiveShop] = useState(deliveryPanel.deliveryShop as number | undefined)

  useEffect(() => {
    if (deliveryDate && (deliveryDate.getTime() > maxDate.getTime() || deliveryDate.getTime() < (minDate.getTime() - (1 * 3600 * 1000 * 24)))) {
      setDeliveryDate(null as any);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryDate])


  useEffect(() => {
    if (activeDeliveryMethod === DeliveryTypeEnum.Delivery && deliveryDate && deliveryDate.getDay() === 0) {
      setDeliveryDate(null as any);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDeliveryMethod])

  useEffect(() => {
    dispatch(setDeliveryPanel({
      deliveryTime: deliveryDate,
      deliveryType: activeDeliveryMethod,
      deliveryShop: activeShop,
    } as DeliveryPanelDto))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeShop, activeDeliveryMethod, deliveryDate])

  const onChangeDate = (date: Date | Date[]) => {
    if (Array.isArray(date)) {
      setDeliveryDate(date[0]);
    }
    else {
      setDeliveryDate(date);
    }
  }

  return (
    <>
      <div className="cartItem cart-panel">
        <div className="col50">
          <div className="cartItem__product">
            <div className="cartItem__product__title">Sposób odbioru</div>
            <hr />
            <p><strong>Wybierz sposób odbioru:</strong></p>
            <div
              className={`validationBase ${props.isValidatorActive ? ((activeDeliveryMethod === null || activeDeliveryMethod === undefined) ? 'isntValid' : '') : 'validationOf'} fullWidth`}
            >
              <div className="deliverySubNav">
                <div className={`deliverySubNav__option${activeDeliveryMethod === 0 ? ' isActive' : ''}`} onClick={() => setActiveDeliveryMethod(DeliveryTypeEnum.Delivery)}>
                  <img src={Truck} alt="Truck" />
                  <p>Odbiór z dowozem</p>
                </div>
                <div className={`deliverySubNav__option${activeDeliveryMethod === 1 ? ' isActive' : ''}`} onClick={() => setActiveDeliveryMethod(DeliveryTypeEnum.SelfRecive)}>
                  <img src={Shop} alt="Shop" />
                  <p>Odbiór osobisty</p>
                </div>
              </div>
            </div>
            <div>
              <br />
              {activeDeliveryMethod === 0 ?
                <>
                  <p>Odbiór z dowozem realizujemy tylko na terenie Wrocławia.</p>
                  <p>Godziny dostawy: 9:00 - 14:00</p>
                  <p>Koszt dostawy: <strong>30zł</strong></p>
                </>
                : null}
              {activeDeliveryMethod === 1 ?
                <>
                  <p>Odbiór z osobisty możesz zrealizować w dowolnym sklepie firmowym.</p>
                  <br />
                  <p><strong>Wybierz sklep:</strong></p>
                  <div
                    className={`validationBase ${props.isValidatorActive ? ((activeShop === null || activeShop === undefined) ? 'isntValid' : '') : 'validationOf'} fullWidth`}
                  >
                    <div className="shopsWrapper">
                      {CartShopsList.map((shopPoint: ShopPointDto, index: number) => {
                        return (
                          <div className={`shopTile${activeShop === index ? ' isActive' : ''}`} onClick={() => setActiveShop(index)} key={index}>
                            <p><strong>{shopPoint.streetAndNumber},<br />{shopPoint.city + " " + shopPoint.postCode}</strong></p>
                            <p>
                              <strong>Godz. otwarcia:</strong>
                              {shopPoint.openTime.map((openTime: string, indexTwo: number) => {
                                return (
                                  <span key={indexTwo}><br />{openTime}</span>
                                )
                              })}
                            </p>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </>
                : null}
            </div>
          </div>
        </div>

        <div className="col50">
          <div className="cartItem__product">
            <div className="cartItem__product__title">Termin odbioru</div>
            <hr />
            {activeDeliveryMethod !== undefined && ((activeDeliveryMethod === 0) || (activeDeliveryMethod === 1 && activeShop !== undefined)) ?
              <>
                <p><strong>Określ dzień odbioru:</strong></p>
                <div
                  className={`validationBase ${props.isValidatorActive ? ((deliveryDate === null || deliveryDate === undefined) ? 'isntValid' : '') : 'validationOf'}`}
                >
                  <Calendar
                    onChange={onChangeDate}
                    value={deliveryDate}
                    minDate={minDate}
                    maxDate={maxDate}
                    tileDisabled={activeDeliveryMethod === 0 ? disabledSundays : disabledCustomDays}
                  />
                </div>
              </>
              : null}

            {/* {activeDeliveryMethod === 0 ?
            <CartDeliveryDataForm />
            : null}
          {activeDeliveryMethod === 1 ?
            <CartDeliveryDataSelfReciveForm />
            : null} */}
          </div>
        </div>

      </div>
      {props.isValidatorActive ?
        <div className={"cartItem cart-panel"}>
          <div className="cartItem__product">
            <div className="cartItem__product__title">Nie można przejść dalej</div>
            Aby kontynuować uzupełnij brakujące informacje i spróbuj jeszcze raz.
          </div>
        </div> : null
      }
    </>
  )
}

export default CartDeliveryPanel;