/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { setSingleCandles } from "../../../../redux/creatorState/creatorState.actions";
import { selectSingleCandles } from "../../../../redux/creatorState/creatorState.selectors";


interface Props {
  isSingleCandlesModalOpen: boolean;
  setIsSingleCandlesModalOpen: any;
}

const SingleCandlesModal = (props: Props) => {

  const { isSingleCandlesModalOpen, setIsSingleCandlesModalOpen } = props;
  const [modalSingleCandles, setModalSingleCandles] = useState(0);

  const singleCandles = useSelector(selectSingleCandles, shallowEqual) as number;

  const dispatch = useDispatch();

  useEffect(() => {
    setModalSingleCandles(singleCandles ? singleCandles : 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSingleCandlesModalOpen])



  return (
    <Modal size={'sm'} show={isSingleCandlesModalOpen} onHide={() => setIsSingleCandlesModalOpen(false)} className="addEditDedicationModal">
      <Modal.Header closeButton>
        <p className={'modalTitle'}>Świeczki pojedyncze</p>
      </Modal.Header>
      <Modal.Body className="addEditDedication">
        <form onSubmit={e => { e.preventDefault(); }}>
          <Form.Label>
            Liczba świeczek
          </Form.Label>
          <Form.Control
            className="form-control-alternative"
            name="singleCandles"
            autoFocus
            step={1}
            min={0}
            max={120}
            type="number"
            placeholder="Liczba świeczek"
            value={modalSingleCandles}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setModalSingleCandles(Number(e.target.value))}
            required
          />
        </form>
        <p className={'invalid-message'}>{modalSingleCandles >= 0 ? modalSingleCandles : 0} / 120</p>
        {modalSingleCandles > 120 ? <p className={'invalid-message'}>Przekroczono zakres</p> : null}
        {modalSingleCandles < 0 ? <p className={'invalid-message'}>Minimalna wartość to 0</p> : null}
      </Modal.Body>
      <Button variant="dark" onClick={() => {
        if (modalSingleCandles > 0 && modalSingleCandles <= 120) {
          dispatch(setSingleCandles(Number(modalSingleCandles.toFixed())));
          setIsSingleCandlesModalOpen(false)
        } else if (modalSingleCandles > 120) {
          setModalSingleCandles(120);
          dispatch(setSingleCandles(120));
          setIsSingleCandlesModalOpen(false)
        } else if (modalSingleCandles <= 0) {
          setModalSingleCandles(0);
          dispatch(setSingleCandles(undefined));
          setIsSingleCandlesModalOpen(false)
        }
      }}>
        {singleCandles ? 'Zapisz' : 'Dodaj'}
      </Button>
      <Button size={'sm'} variant="primary" onClick={() => {
        setModalSingleCandles(singleCandles);
        setIsSingleCandlesModalOpen(false)
      }}>
        Anuluj
    </Button>
    </Modal>
  )
}

export default SingleCandlesModal;