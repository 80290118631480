import ProductsStateTypes from "./productsState.types";
import { ProductDto } from "../../dto/productDto";


export const getAllProducts = () => {
  return {
    type: ProductsStateTypes.GET_ALL_PRODUCTS
  };
};

export const getAllProductsSuccess = (products: Array<ProductDto>) => {
  return {
    type: ProductsStateTypes.GET_ALL_PRODUCTS_SUCCESS,
    payload: products
  };
};