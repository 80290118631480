/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./../home-page.scss";
import "./../standard-page.scss";
import "./kreator-page.scss";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { setActivePanel } from "../../../redux/creatorState/creatorState.actions";
import { selectActivePanel } from "../../../redux/creatorState/creatorState.selectors";

interface Props {
  isAllFinished: boolean;
  offset: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  addToShoppingCart: () => void;
}

const CreatorNextBtn = (props: Props) => {

  const { isAllFinished, addToShoppingCart } = props;

  const dispatch = useDispatch();
  const activePanel = useSelector(selectActivePanel, shallowEqual) as number;

  const handleSetActivePanel = (active: number | undefined) => {
    let activeNew = 0;
    if (activePanel > 6) {
      activeNew = 7;
    } else if (activePanel) {
      activeNew = activePanel + 1
    } else {
      activeNew = 1;
    }

    dispatch(setActivePanel(activeNew))
  }

  const addToCart = () => {
    addToShoppingCart();
  }

  return (
    <div className={'kreator-btn kreator-btn--next'}>
      {activePanel === 7 && isAllFinished ?
        // <TopLink to={RoutePath.CART}>
        <div className={`btn btn-dark`} onClick={addToCart}>&nbsp;&nbsp;Do kasy&nbsp;&nbsp;&nbsp;&rsaquo;&nbsp;</div>
        // </TopLink>
        :
        <div onClick={() => handleSetActivePanel(activePanel)} className={`btn btn-outline-dark ${activePanel === 7 ? 'disabled' : ''}`}>&nbsp;&nbsp;dalej&nbsp;&nbsp;&nbsp;&rsaquo;&nbsp;</div>
      }
    </div>
  )
}

export default CreatorNextBtn;