/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState, useEffect } from "react";
import "./../home-page.scss";
import "./../standard-page.scss";
import "./kreator-page.scss";

import Helmet from "react-helmet";

import HomeNav from "./../home-nav";
import HomeContactSection from "./../home-contact-section";
import HomeFooter from "./../home-footer";
import SweetDream from "./../sweet-dream";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SugarMassIconComponent from "../../../components/colorIcons/sugerMass-icon";
import CreamIconComponent from "../../../components/colorIcons/cream-icon";

import AddCircleIcon from "./creator-assets/kreatorIcons/addCircleIcon.svg";
import SmoothTopAddonIcon from "./creator-assets/kreatorIcons/smoothTopAddon.svg";

import { CreatorOptionDto, CoverOptionDto, SimpleCreatorOptionDto, CoverOptionDetailsDto, CreatorDto } from "../../../dto/creatorDto";
import { CreatorBottomsList, CreatorCoversList, CreatorTastesList, CreatorSidesList, CreatorTopAddonsList } from "./creator-assets/creatorData/creator.data";

import KreatorSummary from "./kreator-summary";
import AddCreatorOptionModal from "./kreator-modals/addCreatorOptionModal";
import CreatorNav from "./creator-nav";
import CreatorSummaryNotFinishedMsg from "./creator-summary-notFinished-msg";

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { CartItemDto, CartItemTypeEnum } from "../../../dto/cartItemDto";
import { UUID } from "../../../tools/uuidGen";
import history from './../../../../history';
import { addCartItem } from "../../../redux/cartState/cartState.actions";
import { selectActivePanel, selectCakeBottom, selectCakeCover, selectCakeCoverDetails, selectCakeHasSmoothTop, selectCakeId, selectCakeSide, selectCakeSize, selectCakeTastes, selectCakeTopAddons, selectDedication, selectFigureCandles, selectSingleCandles } from "../../../redux/creatorState/creatorState.selectors";
import { addCakeTaste, addCakeTopAddon, clearCreator, delCakeTaste, delCakeTopAddon, restoreCreator, setActivePanel, setCakeBottom, setCakeCover, setCakeCoverDetails, setCakeHasSmoothTop, setCakeId, setCakeSide, setCakeSize } from "../../../redux/creatorState/creatorState.actions";
import { handleScrollToSection } from "../../../tools/handleScrollToSection";
import { showSlideOverlay } from "../../../redux/callbacksState/callbacksState.actions";
import { RoutePath } from "../../../../route-paths";
import CreatorContainer from "./creator-container";


const KreatorPage: React.FC<any> = () => {

  const creatorRef = useRef<any>();
  const dispatch = useDispatch();

  useEffect(() => {

    if (history.location.search.length > 2) {
      dispatch(restoreCreator());
      setIsAllFinishedHelper();
    }

    return () => {
      dispatch(clearCreator());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const activePanel = useSelector(selectActivePanel, shallowEqual) as number;
  const cakeSize = useSelector(selectCakeSize, shallowEqual) as number;
  const cakeBottom = useSelector(selectCakeBottom, shallowEqual) as number;
  const cakeCover = useSelector(selectCakeCover, shallowEqual) as number;
  const cakeCoverDetails = useSelector(selectCakeCoverDetails, shallowEqual) as number;
  const cakeSide = useSelector(selectCakeSide, shallowEqual) as number;
  const cakeHasSmoothTop = useSelector(selectCakeHasSmoothTop, shallowEqual) as boolean;
  const cakeTastes = useSelector(selectCakeTastes, shallowEqual) as Array<number>;
  const cakeTopAddons = useSelector(selectCakeTopAddons, shallowEqual) as Array<number>;
  const singleCandles = useSelector(selectSingleCandles, shallowEqual) as number;
  const figureCandles = useSelector(selectFigureCandles, shallowEqual) as number;
  const dedication = useSelector(selectDedication, shallowEqual) as string;
  const cakeId = useSelector(selectCakeId, shallowEqual) as string;


  const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);
  const [isAllFinished, setIsAllFinished] = useState(false);


  const isFirstRun = useRef(true);
  useEffect(() => {

    if (activePanel === 7) {
      if (cakeId === undefined) {
        dispatch(setCakeId(UUID()));
      }
    }

    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    else {
      setIsAllFinishedHelper();
      handleScrollToSection(creatorRef);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePanel])


  useEffect(() => {
    if (cakeTopAddons && cakeTopAddons.length > 0) {
      dispatch(setCakeHasSmoothTop(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cakeTopAddons])

  const addNewTasteLayer = (index: number) => {
    dispatch(addCakeTaste(index));
    setIsSelectModalOpen(false);
  }

  const addNewTopAddon = (index: number) => {
    dispatch(addCakeTopAddon(index));
    setIsSelectModalOpen(false);
  }

  const handleSetActiveSize = (value: number) => {
    dispatch(setCakeSize(value));
  }

  const incrementSize = (e: any) => {
    if (cakeSize < 2) {
      handleSetActiveSize(cakeSize + 0.5);
    }
    else if (cakeSize < 20) {
      handleSetActiveSize(cakeSize + 1);
    }
  }

  const decrementSize = (e: any) => {
    if (cakeSize >= 3) {
      handleSetActiveSize(cakeSize - 1);
    }
    else if (cakeSize > 1) {
      handleSetActiveSize(cakeSize - 0.5);
    }
  }

  const setActiveSizeCircleCustom = (e: any) => {

    if (cakeSize === undefined) {
      handleSetActiveSize(1);
      if (e.target.id === 'increment') {
        handleSetActiveSize(1.5);
      }
    }
    // else if (cakeSize < 1) {
    //   handleSetActiveSize(1);
    //   if (e.target.id === 'increment') {
    //     handleSetActiveSize(1.5);
    //   }
    // }
    // else if (cakeSize >= 2) {
    //   handleSetActiveSize(2);
    //   if (e.target.id === 'increment') {
    //     handleSetActiveSize(1.5);
    //   }
    // }
  }

  // const setActiveSizeCustom = (e: any) => {
  //   console.log(cakeSize);
  //   if (cakeSize < 3 || cakeSize == undefined) {
  //     handleSetActiveSize(3);
  //     if (e.target.id === 'increment') {
  //       handleSetActiveSize(4);
  //     }
  //   }
  // }

  const setIsAllFinishedHelper = () => {
    let isAllFinishedTemp: boolean = true;
    if (!(cakeSize > 0)) isAllFinishedTemp = false;
    if (!(cakeBottom >= 0)) isAllFinishedTemp = false;
    if (!(cakeTastes && cakeTastes.length > 0)) isAllFinishedTemp = false;
    if (!(cakeCover >= 0)) isAllFinishedTemp = false;
    if (!(cakeCoverDetails >= 0)) isAllFinishedTemp = false;
    if (!(cakeSide >= 0)) isAllFinishedTemp = false;
    if (!(((cakeTopAddons && cakeTopAddons.length > 0) || cakeHasSmoothTop))) isAllFinishedTemp = false;

    setIsAllFinished(isAllFinishedTemp);
  }

  const handleSetActiveCakeCover = (value: number) => {
    if (cakeCover !== value) {
      dispatch(setCakeCover(value))
      dispatch(setCakeCoverDetails(undefined))
    }
  }

  const addToShoppingCart = () => {
    const path = window.location.pathname + window.location.search;
    let cartItemDto: CartItemDto = {
      id: cakeId,
      creatorResult: {
        pathToEdit: path,
        cakeSize: cakeSize,
        cakeBottom: cakeBottom,
        cakeCover: cakeCover,
        cakeCoverDetails: cakeCoverDetails,
        cakeSide: cakeSide,
        cakeTastes: cakeTastes,
        cakeTopAddons: cakeTopAddons,
        cakeSmoothTop: cakeHasSmoothTop,
        dedication: dedication,
        singleCandles: singleCandles,
        figureCandles: figureCandles
      } as CreatorDto,
      title: 'Tort z kreatora',
      description: convertResultToDescription(),
      cartItemType: CartItemTypeEnum.CreatorCake,
      itemsCount: 1,
    }

    dispatch(addCartItem(cartItemDto))
    dispatch(showSlideOverlay(RoutePath.CART))

    setTimeout(() => {
      dispatch(clearCreator());
      window.scrollTo(0, 0)
    }, 200);

  }

  const convertResultToDescription = () => {
    return (
      `Rozmiar: ${cakeSize}kg, ` +
      `spód: ${CreatorBottomsList[cakeBottom].name}, ` +
      `smaki: [${(cakeTastes.map((taste: number, index: number) => {
        return (index === 0 ? '' : ' ') + CreatorTastesList[taste].name
      }))}], ` +
      `pokrycie: ${CreatorCoversList[cakeCover].name + ' - ' + CreatorCoversList[cakeCover].details[cakeCoverDetails].name}, ` +
      `boki: ${CreatorSidesList[cakeSide].name}, ` +
      `wierzch: [${cakeTopAddons.length > 0 ? cakeTopAddons.map((topAddon: number, index: number) => {
        return (
          (index === 0 ? '' : ' ') + CreatorTopAddonsList[topAddon].name
        )
      }) :
        (cakeHasSmoothTop ? "Gładki" : "")
      }], ` +
      `świeczki pojed: ${singleCandles ? singleCandles.toString() : '0'}, ` +
      `świeczki cyfry: ${figureCandles ? figureCandles.toString() : '0'}, ` +
      `dedykacja: ${dedication ? dedication : '-'}`
    )
  }

  return (
    <>

      <AddCreatorOptionModal
        isSelectModalOpen={isSelectModalOpen}
        setIsSelectModalOpen={setIsSelectModalOpen}
        activePanel={activePanel}
        addNewTasteLayer={addNewTasteLayer}
        addNewTopAddon={addNewTopAddon}
      />
      <div className="home-page standard-page kreator-page">
        <Helmet>
          <title>{'Torty standardowe | TORTY.COM'}</title>
        </Helmet>
        <HomeNav />
        <div className="overlayLoader"></div>
        <div className="hideOnTablet">
          <section className="trustSection">
            <Container>
              <h1>Kreator tortów!</h1>
              <p>Upieczemy dla Ciebie dowolny tort, który stworzysz w kreatorze.</p>
            </Container>
          </section>
        </div>
        <section ref={creatorRef} className="togetherSection">
          <Container>
            <CreatorNav />
            <br />
            <Row className={"kreator__mainRow"}>
              <Col xl={{ span: 12 }} className="col">
                {activePanel === 0 || (activePanel === undefined) ?
                  <div className="kreator__wrapper kreator__size">
                    <h3>Wybierz rozmiar tortu</h3>
                    <CreatorContainer
                      isAllFinished={isAllFinished}
                      addToShoppingCart={addToShoppingCart}
                    >
                      <div className="sizesWrapper">
                        <div className="sizesBox">
                          <p>Kształt tortu zależy od masy:<br />do 2kg jest okrągły, powyżej prostokątny</p>
                          <br />
                          <div className="sizes">
                            <div onClick={setActiveSizeCircleCustom} className={`circle sizeCustom ${cakeSize ? 'active' : ''}`}>
                              <span id="decrement" className={`sizeCustomBtn ${cakeSize <= 1 || cakeSize === undefined ? 'disabled' : ''}`} onClick={decrementSize}>-</span>
                              <div className="sizeValue">{cakeSize ? cakeSize.toFixed(1) : "--- "}kg</div>
                              <span id="increment" className={`sizeCustomBtn ${cakeSize >= 20 ? 'disabled' : ''}`} onClick={incrementSize}>+</span>
                            </div>
                          </div>
                          <br />
                          {cakeSize === undefined ?
                            <p>Aby ustawić rozmiar, kliknij powyższy element.</p>
                            : null}
                          {cakeSize <= 2 ?
                            <p>Wybrany kształt tortu:<br />
                              <strong>
                                okrągły
                              </strong>
                            </p>
                            : null}
                          {cakeSize > 2 ?
                            <p>Wybrany kształt tortu:<br />
                              <strong>
                                prostokątny
                              </strong>
                            </p>
                            : null}
                        </div>
                      </div>
                      {/* <div onClick={() => handleSetActiveSize(3)} className={`rect size3000 ${cakeSize === 3 ? 'active' : ''}`}>3.0kg</div>
                      <div onClick={() => handleSetActiveSize(4)} className={`rect size4000 ${cakeSize === 4 ? 'active' : ''}`}>4.0kg</div> */}
                    </CreatorContainer>
                  </div>
                  : null}
                {activePanel === 1 ?
                  <div className="kreator__wrapper kreator__bottom">
                    <h3>Rodzaj spodu</h3>
                    <CreatorContainer
                      isAllFinished={isAllFinished}
                      addToShoppingCart={addToShoppingCart}
                    >
                      {CreatorBottomsList.map((bottom: SimpleCreatorOptionDto, index: number) => {
                        return (
                          <div className="kreator__option" key={index}>
                            <div onClick={() => dispatch(setCakeBottom(index))} className={`kreator__option__imgWrappr ${cakeBottom === index ? 'active' : ''}`}><img src={bottom.icon} alt={bottom.name} /></div>
                            <p>{bottom.name}</p>
                          </div>
                        )
                      })}
                    </CreatorContainer>
                  </div>
                  : null}

                {activePanel === 2 ?
                  <div className="kreator__wrapper kreator__bottom kreator__smaki">
                    <h3>Smaki</h3>
                    <CreatorContainer
                      isAllFinished={isAllFinished}
                      addToShoppingCart={addToShoppingCart}
                    >
                      <div className="kreator__option kreator__option--add">
                        <div onClick={() => setIsSelectModalOpen(true)} className={`kreator__option__imgWrappr`}><img src={AddCircleIcon} alt="" /></div>
                        <p>Dodaj smak</p>
                      </div>
                      {cakeTastes && cakeTastes.length > 0 ?
                        cakeTastes.map((taste: number, index: number) => {
                          return (
                            CreatorTastesList[taste] ?
                              <div className="kreator__option" key={index}>
                                <div onClick={() => dispatch(delCakeTaste(index))} className={`kreator__option__imgWrappr`}><img src={CreatorTastesList[taste].icon} alt="" /></div>
                                <p>{CreatorTastesList[taste].name}</p>
                              </div> : null
                          )
                        }) : null
                      }
                    </CreatorContainer>
                  </div>
                  : null}

                {activePanel === 3 ?
                  <div className="kreator__wrapper kreator__bottom">
                    <h3>Pokrycie tortu</h3>
                    <CreatorContainer
                      isAllFinished={isAllFinished}
                      addToShoppingCart={addToShoppingCart}
                    >
                      {CreatorCoversList.map((coverOpcion: CoverOptionDto, index: number) => {
                        return (
                          <div className="kreator__option" key={index}>
                            <div onClick={() => handleSetActiveCakeCover(index)} className={`kreator__option__imgWrappr ${cakeCover === index ? 'active' : ''}`}><img src={coverOpcion.icon} alt={coverOpcion.name} /></div>
                            <p>{coverOpcion.name}</p>
                          </div>
                        )
                      })}
                    </CreatorContainer>
                  </div>
                  : null}
                {activePanel === 4 ? (
                  cakeCover >= 0 ?
                    <>
                      <div className="kreator__wrapper kreator__bottom">
                        <h3>{CreatorCoversList[cakeCover].detailsTitle}</h3>
                        <CreatorContainer
                          isAllFinished={isAllFinished}
                          addToShoppingCart={addToShoppingCart}
                        >
                          {cakeCover >= 0 ?
                            <>
                              {CreatorCoversList[cakeCover].details.map((coverDetail: CoverOptionDetailsDto, index: number) => {
                                return (
                                  <div className="kreator__option" key={index}>
                                    <div onClick={() => dispatch(setCakeCoverDetails(index))} className={`kreator__option__imgWrappr ${cakeCoverDetails === index ? 'active' : ''}`}>

                                      {typeof coverDetail.icon === 'string' ?
                                        <img src={coverDetail.icon} alt={coverDetail.name} />
                                        : (
                                          CreatorCoversList[cakeCover].name === "Masa cukrowa" ?
                                            <SugarMassIconComponent mainColor={coverDetail.color!} /> :
                                            (
                                              CreatorCoversList[cakeCover].name === "Śmietana" ?
                                                <CreamIconComponent mainColor={coverDetail.color!} /> : null
                                            )
                                        )}
                                    </div>
                                    <p>{coverDetail.name}</p>
                                  </div>
                                )
                              })}
                            </> : null
                          }
                        </CreatorContainer>
                      </div>
                    </>
                    :
                    <>
                      <div className="kreator__wrapper kreator__bottom">
                        <h3>Szczegóły pokrycia</h3>
                        <CreatorContainer
                          isAllFinished={isAllFinished}
                          addToShoppingCart={addToShoppingCart}
                        >
                          <h4 className="kreator__subTitle">
                            Nie wybrano rodzaju pokrycia
                          </h4>
                          <div className="kreator__subTitle noFinishedInfo">
                            Rodzaj pokrycia, możesz określić w poprzednim kroku &nbsp;&nbsp;&nbsp;&nbsp;
                            <div onClick={() => dispatch(setActivePanel(activePanel - 1))} className={`btn btn-outline-dark`}>&nbsp;&lsaquo;&nbsp;&nbsp;&nbsp;Pokrycie&nbsp;&nbsp;</div>
                          </div>
                        </CreatorContainer>
                      </div>
                    </>
                ) : null}

                {activePanel === 5 ?
                  <div className="kreator__wrapper kreator__bottom">
                    <h3>Boki tortu</h3>
                    <CreatorContainer
                      isAllFinished={isAllFinished}
                      addToShoppingCart={addToShoppingCart}
                    >
                      {CreatorSidesList.map((option: CreatorOptionDto, index: number) => {
                        return (
                          <div className="kreator__option" key={index}>
                            <div onClick={() => dispatch(setCakeSide(index))} className={`kreator__option__imgWrappr ${cakeSide === index ? 'active' : ''}`}>
                              {typeof option.icon === 'string' ?
                                <img src={option.icon} alt={option.name} />
                                : option.icon}
                            </div>
                            <p>{option.name}</p>
                          </div>
                        )
                      })}
                    </CreatorContainer>
                  </div>
                  : null}

                {activePanel === 6 ?
                  <div className="kreator__wrapper kreator__bottom kreator__smaki">
                    <h3>Wierzch tortu</h3>
                    <CreatorContainer
                      isAllFinished={isAllFinished}
                      addToShoppingCart={addToShoppingCart}
                    >
                      <div className="kreator__option kreator__option--add">
                        <div onClick={() => setIsSelectModalOpen(true)} className={`kreator__option__imgWrappr`}><img src={AddCircleIcon} alt="" /></div>
                        <p>Dodaj element</p>
                      </div>
                      {
                        cakeTopAddons.length === 0 ?
                          <div className="kreator__option kreator__topAddons kreator__option--glad">
                            <div onClick={() => dispatch(setCakeHasSmoothTop(true))} className={`kreator__option__imgWrappr ${cakeHasSmoothTop ? 'active' : ''}`}><img src={SmoothTopAddonIcon} alt="" /></div>
                            <p>Gładki wierzch</p>
                          </div> : null
                      }
                      {CreatorTopAddonsList && CreatorTopAddonsList.length > 0 ?
                        cakeTopAddons.map((topAddon: number, index: number) => {
                          return (
                            CreatorTopAddonsList[topAddon] ?
                              <div className="kreator__option" key={index}>
                                <div onClick={() => dispatch(delCakeTopAddon(index))} className={`kreator__option__imgWrappr`}>
                                  <img src={CreatorTopAddonsList[topAddon].icon} alt="" />
                                </div>
                                <p>{CreatorTopAddonsList[topAddon].name}</p>
                              </div>
                              : null
                          )
                        }) : null
                      }
                    </CreatorContainer>
                  </div>
                  : null}

                {activePanel === 7 ?
                  <div className="kreator__wrapper kreator__bottom kreator__smaki kreator__summary">
                    <h3>Podsumowanie</h3>
                    <CreatorContainer
                      isAllFinished={isAllFinished}
                      addToShoppingCart={addToShoppingCart}
                    >
                      {
                        cakeSize > 0 &&
                          cakeBottom >= 0 &&
                          (cakeTastes && cakeTastes.length > 0) &&
                          cakeCover >= 0 &&
                          cakeCoverDetails >= 0 &&
                          cakeSide >= 0 &&
                          ((cakeTopAddons && cakeTopAddons.length > 0) || cakeHasSmoothTop) ?
                          <KreatorSummary />
                          :
                          <CreatorSummaryNotFinishedMsg />
                      }
                    </CreatorContainer>
                  </div>
                  : null}

              </Col>
              {/* <CreatorNextBtn
                isAllFinished={isAllFinished}
                offset={0}
                addToShoppingCart={addToShoppingCart}
              /> */}
            </Row>
            {/* <Row>
              <CreatorPrevBtn />
              <CreatorNextBtn
                isAllFinished={isAllFinished}
                offset={10}
                addToShoppingCart={addToShoppingCart}
              />
            </Row> */}
          </Container>
        </section>
        <br />
        <br />
        <div className="hideOnTablet">
          <SweetDream />
          <HomeContactSection />
          <HomeFooter />
        </div>
      </div>
    </>
  )
}

export default KreatorPage;