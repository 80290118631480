import React, { useEffect, useRef } from 'react';

interface Props {
  loadData: (lastId: number | undefined) => any;
  items: Array<any>
}


const SimpleInfiniteScroll: React.FC<Props> = (props) => {
  const bottomRef = useRef<any>()
  let infinityScroll_requestAllowed = true;

  /* eslint-disable */
  useEffect(() => {
    props.loadData
    handleScroll();
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [])
  /* eslint-enable */

  useEffect(() => {
    window.removeEventListener('scroll', handleScroll);
    handleScroll();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.items])


  const handleScroll = () => {

    window.addEventListener("scroll", (e: any) => {
      const actualPos = e.target.scrollTop + window.innerHeight;
      let bottomPos = 9999999999;
      if (bottomRef.current !== null) {
        bottomPos = bottomRef.current.offsetTop;
      }
      if (actualPos >= bottomPos && infinityScroll_requestAllowed) {
        infinityScroll_requestAllowed = false;
        var lastId = props.items && props.items.length && props.items.length > 0 ? props.items[props.items.length - 1].id : undefined
        props.loadData(lastId);
        setTimeout(() => {
          infinityScroll_requestAllowed = true;
        }, 1000);
      }
    }, true);
  }

  return (
    <>
      {props.children}
      <div ref={bottomRef} />
    </>
  )
}

export default SimpleInfiniteScroll;