import React, { useRef, useEffect } from 'react';
import './rules-page.scss'
import { PrivacyPolicy } from './privacy-policy';
import Helmet from 'react-helmet';
import { CookiePolicy } from './cookie-policy';
import Container from 'react-bootstrap/Container';
import MainSection from '../../components/main-section/main-section';
import NavbarLanding from '../../components/navbar-landing/navbar-landing';
import { useLocation } from 'react-router-dom';

export const RulesPage: React.FC<any> = (props: any) => {

  const rulesLink = useRef<any>()
  const privacyLink = useRef<any>()

  const handleScrollToSection = (section: React.MutableRefObject<any>) => {
    window.scrollTo(0, section.current!.getBoundingClientRect().top + window.pageYOffset);
  }

  const { state } = useLocation();

  useEffect(() => {
    if (state && (state as any).scrollTo && (state as any).scrollTo === 'top') {
      window.scrollTo(0, 0);
      window.history.pushState(null, '')
      // window.scrollTo(0, privacyLink.current!.getBoundingClientRect().top + window.pageYOffset);
    }
    else if (state && (state as any).scrollTo && (state as any).scrollTo === 'rules') {
      window.scrollTo(0, rulesLink.current!.getBoundingClientRect().top + window.pageYOffset);
      window.history.pushState(null, '')
    }
    else {
      window.scrollTo(0, 0);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (

    <div className="rules-page">
      <Helmet>
        <title>{'Suggestly'}</title>
        {/* <meta name="description" content="Blog interaktywny. Praca kreatywna od kuchni, ukazana z różnych perspektyw. Programowanie, Design, Biznes, Seo, Teamwork, Strony internetowe, Aplikacje webowe i wiele innych." />
        <meta name="keywords" content="narzędzia,design,komunikacja,teamwork" /> */}
      </Helmet>
      <NavbarLanding>
        <span onClick={() => { handleScrollToSection(privacyLink) }}>
          Polityka Prywatności
        </span>
        <span onClick={() => { handleScrollToSection(rulesLink) }}>
          Warunki użytkowania
        </span>
      </NavbarLanding>
      <Container>
        <MainSection
          title="Polityka strony"
        >
        </MainSection>
        <h3 ref={privacyLink}>
          Polityka Prywatności
        </h3>
        <PrivacyPolicy />
        <h3 ref={rulesLink}>
          Polityka Cookies
        </h3>
        <CookiePolicy />
      </Container>
    </div>
  )
}