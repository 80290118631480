/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {  } from "react";
import "./home-page.scss";
import { RoutePath } from "../../../route-paths";
import NavbarLanding from "../../components/navbar-landing/navbar-landing";
import TopLink from "../../components/top-link/top-link";

const HomeNav: React.FC<any> = () => {
  return (
      <NavbarLanding>
        <TopLink to={RoutePath.KREATOR}>Kreator</TopLink>
        <TopLink to={RoutePath.STANDARD}>Standardowe</TopLink>
        <TopLink to={RoutePath.OKOLICZNOSCIOWE}>Okolicznościowe</TopLink>
        <TopLink to={RoutePath.DLAFIRM}>Dla firm</TopLink>
      </NavbarLanding>
  )
}

export default HomeNav;