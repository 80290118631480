import { takeLatest, put, all, call, select } from "redux-saga/effects";
import { ToastsStore } from "react-toasts";
import { ApiMethodDto, UrlParam } from "../../api/api-method-dto";
import { ApiPath } from "../../api/apiPath";
import { callApi } from "../../api/api-call-service";
import { SagaInputDto } from "../../api/saga-input-dto";
import LeadsStateActionTypes from "./leadsState.types";
import { LeadDto } from "../../dto/leadDto";
import { JaworscyStore } from "../store.types";

function* getAllLeadsAsync(sagaInput: SagaInputDto<{ page: number, count: number }>) {
  const token = yield select(state => state.user.token);
  const leads = yield select((state: JaworscyStore) => state.leadsState.leads);

  const options = {
    headers: { Authorization: token },
    method: "GET",
    expectedStatus: 200,
    params: [{
      name: "lastItem",
      value: leads.length
    }, {
      name: "count",
      value: sagaInput.payload.count
    }] as Array<UrlParam>
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "get", ApiPath.lead_getAllLeads, options);

    yield put({
      type: LeadsStateActionTypes.GET_ALL_LEADS_SUCCESS,
      payload: res.data.leads as Array<LeadDto>
    });
  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* deleteLeadAsync(sagaInput_leadId: SagaInputDto<string>) {
  const token = yield select(state => state.user.token);
  const options = {
    headers: { Authorization: token },
    method: "DELETE",
    expectedStatus: 200,
    params: {
      name: "leadId",
      value: sagaInput_leadId.payload
    } as UrlParam
  } as ApiMethodDto;

  try {
    yield call(callApi, "delete", ApiPath.lead_deleteLead, options);

    yield put({
      type: LeadsStateActionTypes.DELETE_LEAD_SUCCESS,
      payload: sagaInput_leadId.payload
    });

    ToastsStore.warning('deleted');

  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}


function* watchDeleteLead() {
  yield takeLatest(LeadsStateActionTypes.DELETE_LEAD as any, deleteLeadAsync);
}

function* watchGetAllLeads() {
  yield takeLatest(LeadsStateActionTypes.GET_ALL_LEADS as any, getAllLeadsAsync);
}

export default function* LeadsStateSagas() {
  yield all([
    call(watchDeleteLead),
    call(watchGetAllLeads)
  ]);
}