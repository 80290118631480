/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { setDedication } from "../../../../redux/creatorState/creatorState.actions";
import { selectDedication } from "../../../../redux/creatorState/creatorState.selectors";

interface Props {
  isDedicationModalOpen: boolean;
  setIsDedicationModalOpen: any;
}

const DedicationModal = (props: Props) => {

  const { isDedicationModalOpen, setIsDedicationModalOpen } = props;
  const [modalDedication, setModalDedication] = useState('');
  const dispatch = useDispatch();
  const dedication = useSelector(selectDedication, shallowEqual) as string;

  useEffect(() => {
    setModalDedication(dedication ? dedication : '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDedicationModalOpen])

  return (
    <Modal size={'sm'} show={isDedicationModalOpen} onHide={() => setIsDedicationModalOpen(false)} className="addEditDedicationModal">
      <Modal.Header closeButton>
        <p className={'modalTitle'}>Dedykacja</p>
      </Modal.Header>
      <Modal.Body className="addEditDedication">
        <form onSubmit={e => { e.preventDefault(); }}>
          <Form.Label>
            Treść dedykacji
          </Form.Label>
          <Form.Control
            className="form-control-alternative"
            name="dedication"
            autoFocus
            as="textarea"
            rows={4}
            maxLength={120}
            type="textarea"
            placeholder="Treść dedykacji"
            value={modalDedication}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setModalDedication(e.target.value)}
            required
          />
        </form>
        <p className={'invalid-message'}>{modalDedication.length} / 120</p>

      </Modal.Body>
      <Button variant="dark" onClick={() => {
        dispatch(setDedication(modalDedication));
        setIsDedicationModalOpen(false)
      }}>
        {dedication ? 'Zapisz' : 'Dodaj'}
      </Button>
      <Button size={'sm'} variant="primary" onClick={() => {
        setModalDedication(modalDedication);
        setIsDedicationModalOpen(false)
      }}>
        Anuluj
    </Button>
    </Modal>
  )
}

export default DedicationModal;