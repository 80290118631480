/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { } from "react";
import "./home-page.scss";
import "./home-inside-nav.scss";

import { RoutePath } from "../../../route-paths";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TopLink from "../../components/top-link/top-link";

import OfertaA from "./assets/standardowe.jpg";
import OfertaB from "./assets/okolicznosciowe.jpg";
import OfertaC from "./assets/amazon.jpg";
import OfertaD from "./assets/creator.jpg";

interface Props {
  excluded: 'kreator' | 'standardowe' | 'okolicznosciowe' | 'dlafirm';
}

const HomeInsideNav: React.FC<Props> = (props: Props) => {
  const { excluded } = props;

  return (
    <section className="suggestMostImportantSection">
      <Container>
        <Row>

          {excluded === 'standardowe' ? null :
            <Col xl={{ span: 4 }} className="col">
              <TopLink to={RoutePath.STANDARD}>
                <div>
                  <h4 style={{ paddingBottom: '15px', paddingTop: '0px', textAlign: 'left' }}>Torty standardowe</h4>
                  <img src={OfertaA} alt="" />
                  <div className="descrWrapper">
                    <p style={{ paddingBottom: '8px' }}>
                      Nasze 30 letnie doświadczenie, pozwoliło nam wyłonić najlepsze receptury. Torty, które z nich powstają, posiadają niezwykle wyważony smak i zawsze dobrze komponują się przy rodzinnym stole.
                    </p>
                    <div className="btn" style={{ background: '#FFF0BF', fontWeight: 500 }}>Zobacz więcej</div>
                  </div>
                </div>
              </TopLink>
            </Col>
          }
          {excluded === 'kreator' ? null :
            <Col xl={{ span: 4 }} className="col">
              <TopLink to={RoutePath.KREATOR}>
                <div>
                  <h4 style={{ paddingBottom: '15px', paddingTop: '0px', textAlign: 'left' }}>Kreator tortów</h4>
                  <img src={OfertaD} alt="" />
                  <div className="descrWrapper">
                    <p style={{ paddingBottom: '8px' }}>
                      Wykorzystamy nasze doświadczenie, aby połączyć wybrane przez Ciebie składniki i dostarczyć Ci personalny tort! Użyj naszego kreatora, aby uzyskać dokładnie to, czego poszukujesz.
                    </p>
                    <div className="btn" style={{ background: '#FFF0BF', fontWeight: 500 }}>Zobacz więcej</div>
                  </div>
                </div>
              </TopLink>
            </Col>
          }
          {excluded === 'okolicznosciowe' ? null :
            <Col xl={{ span: 4 }} className="col">
              <TopLink to={RoutePath.OKOLICZNOSCIOWE}>
                <div>
                  <h4 style={{ paddingBottom: '15px', paddingTop: '0px', textAlign: 'left' }}>Torty okolicznościowe</h4>
                  <img src={OfertaB} alt="" />
                  <div className="descrWrapper">
                    <p style={{ paddingBottom: '8px' }}>
                      Niektóre realizacje są niczym sztuka. Pewne zlecenia wymagają od nas znacznie więcej, jednak to tylko nas nakręca! Im większe wyzwania, tym większą radość i satysfakcję czujemy z naszej pracy.
                    </p>
                    <div className="btn" style={{ background: '#FFF0BF', fontWeight: 500 }}>Zobacz więcej</div>
                  </div>
                </div>
              </TopLink>
            </Col>
          }
          {excluded === 'dlafirm' ? null :
            <Col xl={{ span: 4 }} className="col">
              <TopLink to={RoutePath.DLAFIRM}>
                <div>
                  <h4 style={{ paddingBottom: '15px', paddingTop: '0px', textAlign: 'left' }}>Torty dla firm</h4>
                  <img src={OfertaC} alt="" />
                  <div className="descrWrapper">
                    <p style={{ paddingBottom: '8px' }}>
                      Firmowa rocznica? A może przełomowy moment w biznesie? Sukcesy należy uczcić, najlepiej zamawiając przepyszny tort dla pracowników. Realizujemy specjalne zamówienia dla firm.
                    </p>
                    <div className="btn" style={{ background: '#FFF0BF', fontWeight: 500 }}>Zobacz więcej</div>
                  </div>
                </div>
              </TopLink>
            </Col>
          }

        </Row>
      </Container>
    </section>
  )
}

export default HomeInsideNav;