import React from "react";
import "./../cart-page.scss";
import "./cart-panel.scss";
import './react-calendar.scss';

import { selectClientData, selectDeliveryPanel, selectReceiverData } from "../../../../redux/cartState/cartState.selectors";
import { BillTypeEnum, ClientDataDto, DeliveryPanelDto, DeliveryTypeEnum, ReceiverDataDto, ReceiverTypeEnum } from "../../../../dto/cartItemDto";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { setClientData, setReceiverData } from "../../../../redux/cartState/cartState.actions";

import CashR from "./../cart-assets/cashr.svg"
import Corp from "./../cart-assets/corp.svg"

import ClientReceiver from "./../cart-assets/clientReceiver.svg"
import OtherReceiver from "./../cart-assets/otherReceiver.svg"

import CartDataRequestorForm from "./forms/cart-data-client-form";
import CartDataReceiverForm from "./forms/cart-data-receiver-form";
import CartDataSelfReciveForm from "./forms/cart-data-selfreceiver-form";


interface Props {
  isValidatorActive: boolean;
}


const CartDataPanel: React.FC<Props> = (props: Props) => {

  const dispatch = useDispatch();

  const deliveryPanel = useSelector(selectDeliveryPanel, shallowEqual) as DeliveryPanelDto;
  const clientData = useSelector(selectClientData, shallowEqual) as ClientDataDto;
  const receiverData = useSelector(selectReceiverData, shallowEqual) as ReceiverDataDto;

  const setBillType = (billType: BillTypeEnum) => {
    dispatch(setClientData({ ...clientData, billType: billType } as ClientDataDto));
  }

  const setReceiverType = (receiverType: ReceiverTypeEnum) => {
    dispatch(setReceiverData({ ...receiverData, city: receiverType === ReceiverTypeEnum.Other ? "Wrocław" : "", receiverType: receiverType } as ReceiverDataDto));
  }


  return (
    <>
      <div className="cartItem cart-panel">
        <div className="col50">
          <div className="cartItem__product">
            <div className="cartItem__product__title">Dane zamawiającego</div>
            <hr />
            <p><strong>Typ rachunku:</strong></p>
            <div className="deliverySubNav">
              <div
                className={`deliverySubNav__option${clientData.billType === BillTypeEnum.CashRegister ? ' isActive' : ''}`}
                onClick={() => setBillType(BillTypeEnum.CashRegister)}
              >
                <img src={CashR} alt="Raragon" />
                <p>Paragon fiskalny</p>
              </div>
              <div
                className={`deliverySubNav__option${clientData.billType === BillTypeEnum.CompanyBill ? ' isActive' : ''}`}
                onClick={() => setBillType(BillTypeEnum.CompanyBill)}
              >
                <img src={Corp} alt="Faktura" />
                <p>Faktura na firmę</p>
              </div>
            </div>
            <br />
            <CartDataRequestorForm isValidationActive={props.isValidatorActive} />
          </div>
        </div>
        <div className="col50">
          <div className="cartItem__product">
            <div className="cartItem__product__title">Dane odbiorcy</div>
            <hr />
            <p><strong>Kto odbierze zamówienie?</strong></p>
            <div
              className={`validationBase ${props.isValidatorActive ? ((receiverData.receiverType === undefined) ? 'isntValid' : '') : 'validationOf'} fullWidth`}
            >
              <div className="deliverySubNav">
                <div
                  className={`deliverySubNav__option${receiverData.receiverType === ReceiverTypeEnum.Client ? ' isActive' : ''}`}
                  onClick={() => setReceiverType(ReceiverTypeEnum.Client)}
                >
                  <img src={ClientReceiver} alt="Zamawiający" />
                  <p>Zamawiający</p>
                </div>
                <div
                  className={`deliverySubNav__option${receiverData.receiverType === ReceiverTypeEnum.Other ? ' isActive' : ''}`}
                  onClick={() => setReceiverType(ReceiverTypeEnum.Other)}
                >
                  <img src={OtherReceiver} alt="Inny odbiorca" />
                  <p>Inny odbiorca</p>
                </div>
              </div>
            </div>
            <br />
            {receiverData.receiverType === ReceiverTypeEnum.Client ?
              <>
                {deliveryPanel.deliveryType === DeliveryTypeEnum.Delivery ?
                  ['Wrocław', 'Wroclaw', "WROCŁAW", "WROCLAW", 'wrocław', 'wroclaw'].some(x => x === clientData.city) ?
                    <p>Zamówienie zostanie dostarczone na adres zamawiającego.</p> :
                    <p>Nie możemy zrealizować zamówienia na Twój adres, ponieważ pole nie zostało uzupełnione lub podana lokalizacja znajduje się poza miastem Wrocław.</p>
                  : null}
                {deliveryPanel.deliveryType === DeliveryTypeEnum.SelfRecive ?
                  <p>Zamówienie zostanie dostarczone do sklepu firmowego i wydane na podstawie danych zamawiającego.</p>
                  : null}
              </>
              : null}
            {receiverData.receiverType === ReceiverTypeEnum.Other ?
              <>
                {deliveryPanel.deliveryType === DeliveryTypeEnum.SelfRecive ?
                  <CartDataSelfReciveForm isValidationActive={props.isValidatorActive} />
                  : null}
                {deliveryPanel.deliveryType === DeliveryTypeEnum.Delivery ?
                  <CartDataReceiverForm isValidationActive={props.isValidatorActive} />
                  : null}
              </> : null}
          </div>
        </div>
      </div>
      {props.isValidatorActive ?
        <div className={"cartItem cart-panel"}>
          <div className="cartItem__product">
            <div className="cartItem__product__title">Nie można przejść dalej</div>
            Aby kontynuować uzupełnij brakujące informacje i spróbuj jeszcze raz.
          </div>
        </div> : null
      }
    </>
  )
}

export default CartDataPanel;