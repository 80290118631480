import { RoutePath } from "../../route-paths";

export interface Param {
  name: string;
  value: string;
};

export function GetParamsHelper() {
  let allPaths = getAllExistingPaths();
  let currentPathRaw = removeDomainFromPath(window.location.href);
  let allPathsPartialled = convertRoutePathsToArrayList(allPaths);
  let currentPathRawPartialled = convertRoutePathsToArrayList([currentPathRaw])[0];
  let splittedArrayOfPaths = splitArray(allPathsPartialled, currentPathRawPartialled.length);
  let splittedCurrentPath = splitArray([currentPathRawPartialled], currentPathRawPartialled.length)[0];
  let splittedRealPath = findRealPath(splittedArrayOfPaths, splittedCurrentPath)
  let params = pullParams(splittedRealPath, splittedCurrentPath);
  return params;
}

const getAllExistingPaths = function () {
  let RoutePathObject: RoutePath = RoutePath;
  let listOfPathsWithFunctions = Object.keys(RoutePathObject);
  let listOfVariables: Array<string> = [];
  for (let i = 0; i < listOfPathsWithFunctions.length; i++) {
    if (listOfPathsWithFunctions[i] === listOfPathsWithFunctions[i].toUpperCase()) {
      listOfVariables.push(RoutePathObject[listOfPathsWithFunctions[i].toString()]);
    }
  }
  return listOfVariables;
}

const convertRoutePathsToArrayList = function (routePaths: string[]) {
  let partialledPathsArray: string[][] = new Array<Array<string>>();

  for (let i = 0; i < routePaths.length; i++) {
    let currentPath = routePaths[i];
    let partialledCurrentPath = String(currentPath).split("/");
    partialledPathsArray.push(partialledCurrentPath);
  }
  return partialledPathsArray;
}

const removeDomainFromPath = function (url: string) {
  url = url.replace(/(^\w+:|^)\/\//, '');
  let listOfPathParts = url.split('/');
  url = url.replace(listOfPathParts[0], '');
  return url;
}

const splitArray = function (array: string[][], partsNumber: number) {
  let splittedArray: string[][] = new Array<Array<string>>();
  for (let i = array.length - 1; i >= 0; i--) {
    if (array[i].length === partsNumber) {
      splittedArray.push(array[i]);
    }
  }
  return splittedArray;
}

const findRealPath = function (array: string[][], currentPath: string[]) {
  let similarityFlag: boolean;
  for (let i = 0; i < array.length; i++) {
    similarityFlag = true;
    for (let y = 0; y < array[i].length; y++) {
      if (array[i][y][0] === ":") {
        continue;
      }
      if (currentPath[y] !== array[i][y]) {
        similarityFlag = false;
        break;
      }
    }
    if (similarityFlag === true) {
      return array[i];
    }
    similarityFlag = true;
  }
  return new Array<string>();
}

const pullParams = function (pattern: string[], pathArray: string[]) {
  let params = new Array<Param>();
  for (let i = 0; i < pattern.length; i++) {
    if (pattern[i][0] !== ":") {
      continue;
    }
    params.push({
      name: pattern[i].substring(1),
      value: pathArray[i],
    } as Param)
  }
  return params;
}
