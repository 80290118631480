import { getQueryStringValue } from '../../tools/queryStringTool';
import CreatorStateTypes from './creatorState.types';
import history from './../../../history';
import { RoutePath } from '../../../route-paths';

const INITIAL_STATE = {
  activePanel: undefined as number | undefined,
  cakeSize: undefined as number | undefined,
  cakeBottom: undefined as number | undefined,
  cakeCover: undefined as number | undefined,
  cakeCoverDetails: undefined as number | undefined,
  cakeSide: undefined as number | undefined,
  cakeTastes: [] as Array<number>,
  cakeTopAddons: [] as Array<number>,
  cakeHasSmoothTop: false as boolean,
  dedication: undefined as string | undefined,
  singleCandles: undefined as number | undefined,
  figureCandles: undefined as number | undefined,
  cakeId: undefined as string | undefined,
}

const INITIAL_STATE_TYPES = {
  activePanel: 1 as number,
  cakeSize: 1 as number,
  cakeBottom: 1 as number,
  cakeCover: 1 as number,
  cakeCoverDetails: 1 as number,
  cakeSide: 1 as number,
  cakeTastes: [1] as Array<number>,
  cakeTopAddons: [1] as Array<number>,
  cakeHasSmoothTop: true as boolean,
  dedication: 'a' as string,
  singleCandles: 1 as number,
  figureCandles: 1 as number,
  cakeId: 'a' as string,
}

export enum KeyMapper {
  activePanel = 'ap',
  cakeSize = 'cs',
  cakeBottom = 'cb',
  cakeCover = 'cc',
  cakeCoverDetails = 'ccd',
  cakeSide = 'csd',
  cakeTastes = 'ct',
  cakeTopAddons = 'cta',
  cakeHasSmoothTop = 'chst',
  dedication = 'de',
  singleCandles = 'sc',
  figureCandles = 'fc',
  cakeId = 'ci'
}


const getInitialStateFromUrl = (): (typeof INITIAL_STATE) => {
  let initState = INITIAL_STATE;
  if (history.location.pathname !== RoutePath.KREATOR) {
    return INITIAL_STATE;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  for (let [key, value] of Object.entries(initState)) {
    const queryVar = getQueryStringValue((KeyMapper as any)[key])
    if (queryVar !== "" && queryVar !== undefined) {
      try {
        let newValue = JSON.parse(queryVar);
        if (typeof newValue === typeof ((INITIAL_STATE_TYPES as any)[key])) {
          (initState as any)[key] = newValue;
        }
      } catch {
        let newValue = queryVar;
        if (typeof newValue === typeof ((INITIAL_STATE_TYPES as any)[key])) {
          (initState as any)[key] = newValue;
        }
      }
    }
  }

  return initState
}

const creatorStateReducer = (state = getInitialStateFromUrl(), action: any) => {
  switch (action.type) {
    case CreatorStateTypes.SET_CAKEID:
      return {
        ...state,
        cakeId: action.payload
      }
    case CreatorStateTypes.SET_ACTIVEPANEL:
      return {
        ...state,
        activePanel: action.payload
      }
    case CreatorStateTypes.SET_CAKESIZE:
      return {
        ...state,
        cakeSize: action.payload
      }
    case CreatorStateTypes.SET_CAKEBOTTOM:
      return {
        ...state,
        cakeBottom: action.payload
      }
    case CreatorStateTypes.SET_CAKECOVER:
      return {
        ...state,
        cakeCover: action.payload
      }
    case CreatorStateTypes.SET_CAKECOVERDETAILS:
      return {
        ...state,
        cakeCoverDetails: action.payload
      }
    case CreatorStateTypes.SET_CAKESIDE:
      return {
        ...state,
        cakeSide: action.payload
      }
    case CreatorStateTypes.SET_CAKEHASSMOOTHTOP:
      return {
        ...state,
        cakeHasSmoothTop: action.payload
      }
    case CreatorStateTypes.SET_DEDICATION:
      return {
        ...state,
        dedication: action.payload
      }
    case CreatorStateTypes.SET_SINGLECANDLES:
      return {
        ...state,
        singleCandles: action.payload
      }
    case CreatorStateTypes.SET_FIGURECANDLES:
      return {
        ...state,
        figureCandles: action.payload
      }

    // TASTES
    case CreatorStateTypes.ADD_CAKETASTE:
      return {
        ...state,
        cakeTastes: [...state.cakeTastes, action.payload]
      }
    case CreatorStateTypes.SET_CAKETASTE:
      return {
        ...state,
        cakeTastes: action.payload
      }
    case CreatorStateTypes.DEL_CAKETASTE:
      return {
        ...state,
        cakeTastes: state.cakeTastes.filter((x: number, index: number) => index !== action.payload)
      }

    // TOPADDONS
    case CreatorStateTypes.ADD_CAKETOPADDONS:
      return {
        ...state,
        cakeTopAddons: [...state.cakeTopAddons, action.payload]
      }
    case CreatorStateTypes.SET_CAKETOPADDONS:
      return {
        ...state,
        cakeTopAddons: action.payload
      }
    case CreatorStateTypes.DEL_CAKETOPADDONS:
      return {
        ...state,
        cakeTopAddons: state.cakeTopAddons.filter((x: number, index: number) => index !== action.payload)
      }
    case CreatorStateTypes.RESTORE_STATE:

      return getInitialStateFromUrl();
    case CreatorStateTypes.CLEAR_STATE:

      return {
        ...state,
        activePanel: undefined as number | undefined,
        cakeSize: undefined as number | undefined,
        cakeBottom: undefined as number | undefined,
        cakeCover: undefined as number | undefined,
        cakeCoverDetails: undefined as number | undefined,
        cakeSide: undefined as number | undefined,
        cakeTastes: [] as Array<number>,
        cakeTopAddons: [] as Array<number>,
        cakeHasSmoothTop: false as boolean,
        dedication: undefined as string | undefined,
        singleCandles: undefined as number | undefined,
        figureCandles: undefined as number | undefined,
        cakeId: undefined as string | undefined,
      }
    default: {
      return state;
    }
  }
}

export default creatorStateReducer;