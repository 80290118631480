import CallbacksStateTypes from "./callbacksState.types";
import { ModalDto } from "../../dto/modalDto";

export const setIsSignInFinished = (isFinished: boolean) => {
  return {
    type: CallbacksStateTypes.SIGNIN_FINISHED,
    payload: isFinished
  };
};

export const setIsSignUpFinished = (isFinished: boolean) => {
  return {
    type: CallbacksStateTypes.SIGNUP_FINISHED,
    payload: isFinished
  };
};

export const setIsGlobalToastFailedToFetchActive = (isActive: boolean) => {
  return {
    type: CallbacksStateTypes.TOAST_FAILEDTOFETCH_ACTIVATED,
    payload: isActive
  };
};

export const hideGlobalModal = () => {
  return {
    type: CallbacksStateTypes.HIDE_GLOBAL_MODAL_SUCCESS
  }
}

export const showGlobalModal = (modal: ModalDto) => {
  return {
    type: CallbacksStateTypes.SHOW_GLOBAL_MODAL_SUCCESS,
    payload: modal
  };
};

export const hideSlideOverlay = () => {
  return {
    type: CallbacksStateTypes.HIDE_SLIDE_OVERLAY_SUCCESS
  }
}

export const showSlideOverlay = (redirectTo: string) => {
  return {
    type: CallbacksStateTypes.SHOW_SLIDE_OVERLAY_SUCCESS,
    payload: redirectTo
  };
};