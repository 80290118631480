import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import cookiePolicyPopupReducer from './cookiePolicyPopup/cookiePolicyPopup.reducer';

import userReducer from './user/user.reducer';
import leadsStateReducer from './leadsState/leadsState.reducer';
import landingPageStateReducer from './landingPageState/landingPageState.reducer';
import callbacksStateReducer from './callbacksState/callbacksState.reducer';
import productsStateReducer from './productsState/productsState.reducer';
import cartStateReducer from './cartState/cartState.reducer';
import creatorStateReducer from './creatorState/creatorState.reducer';
import memoCallbacksStateReducer from './memoCallbacksState/memoCallbacksState.reducer';
import ordersStateReducer from './ordersState/ordersState.reducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['memoCallbacksState', 'cookiePolicyPopup', 'cartState', 'user', 'suggestlyEntityState']
}

const rootReducer = combineReducers({
  cookiePolicyPopup: cookiePolicyPopupReducer,
  memoCallbacksState: memoCallbacksStateReducer,
  callbacksState: callbacksStateReducer,
  landingPageState: landingPageStateReducer,
  leadsState: leadsStateReducer,
  ordersState: ordersStateReducer,
  user: userReducer,
  productsState: productsStateReducer,
  cartState: cartStateReducer,
  creatorState: creatorStateReducer

})

export default persistReducer(persistConfig, rootReducer);