const CartStateTypes = {
  ADD_CARTITEM: 'ADD_CARTITEM',
  DELETE_CARTITEM: 'DELETE_CARTITEM',
  CART_CLEAR_STATE: 'CART_CLEAR_STATE',

  SET_DELIVERYPANEL: 'SET_DELIVERYPANEL',
  SET_RECEIVERDATA: 'SET_RECEIVERDATA',
  SET_CLIENTDATA: 'SET_CLIENTDATA',

  CREATE_CHECKOUT_SESSION: 'CREATE_CHECKOUT_SESSION'
};

export default CartStateTypes;