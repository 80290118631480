import { validateEmail } from "../tools/emailValidator"
import { CreatorDto } from "./creatorDto"

export enum DeliveryTypeEnum {
  Delivery = 0,
  SelfRecive = 1
}

export enum BillTypeEnum {
  CashRegister = 0,
  CompanyBill = 1
}

export enum ReceiverTypeEnum {
  Client = 0,
  Other = 1
}

export enum CartItemTypeEnum {
  PredefinedCake = 1,
  CreatorCake = 2,
  PhysicalProduct = 3,
}

export type CartItemDto = {
  id: string,
  creatorResult: CreatorDto, //| PhysicalProductDto,
  cartItemType: CartItemTypeEnum,
  title: string,
  description: string,
  itemsCount: number,
  deliveryTime?: Date,
}


export type DeliveryPanelDto = {
  deliveryTime?: Date,
  deliveryType?: DeliveryTypeEnum,
  deliveryShop?: number,
}

export type ReceiverDataDto = {
  name: string,
  surname: string,
  streetAndHomeNumber: string,
  postCode: string,
  city: string,
  phone: string,
  email: string,
  receiverType: ReceiverTypeEnum | undefined,
}

export const isReceiverDataValid = (receiverDataDto: ReceiverDataDto, deliveryType: DeliveryTypeEnum, mustBeWroc?: boolean) => {
  if (receiverDataDto.receiverType === ReceiverTypeEnum.Other) {
    if (!receiverDataDto.name || 0 === receiverDataDto.name.length) return false;
    if (!receiverDataDto.surname || 0 === receiverDataDto.surname.length) return false;
    if (!receiverDataDto.phone || receiverDataDto.phone.length < 6 || receiverDataDto.phone.length > 17) return false;
    if (!receiverDataDto.email || 0 === receiverDataDto.email.length || !validateEmail(receiverDataDto.email)) return false;
    if (deliveryType === DeliveryTypeEnum.Delivery) {
      if (!receiverDataDto.streetAndHomeNumber || 0 === receiverDataDto.streetAndHomeNumber.length) return false;
      if (!receiverDataDto.postCode || (5 !== receiverDataDto.postCode.length && 6 !== receiverDataDto.postCode.length)) return false;
      if (!receiverDataDto.city || 0 === receiverDataDto.city.length) return false;
      if (mustBeWroc && !['Wrocław', 'Wroclaw', "WROCŁAW", "WROCLAW", 'wrocław', 'wroclaw'].some(x => x === receiverDataDto.city)) return false;
    }
  }
  return true;
}

export type ClientDataDto = {
  name: string,
  surname: string,
  company: string,
  nip: string,
  streetAndHomeNumber: string,
  postCode: string,
  city: string,
  phone: string,
  email: string,
  message: string,
  billType: BillTypeEnum,
}

export const isClientDataValid = (clientDataDto: ClientDataDto, mustBeWroc?: boolean): boolean => {
  if (!clientDataDto.name || 0 === clientDataDto.name.length) return false;
  if (!clientDataDto.surname || 0 === clientDataDto.surname.length) return false;
  if (!clientDataDto.streetAndHomeNumber || 0 === clientDataDto.streetAndHomeNumber.length) return false;
  if (!clientDataDto.postCode || (5 !== clientDataDto.postCode.length && 6 !== clientDataDto.postCode.length)) return false;
  if (!clientDataDto.city || 0 === clientDataDto.city.length) return false;
  if (mustBeWroc && !['Wrocław', 'Wroclaw', "WROCŁAW", "WROCLAW", 'wrocław', 'wroclaw'].some(x => x === clientDataDto.city)) return false;
  if (!clientDataDto.phone || clientDataDto.phone.length < 6 || clientDataDto.phone.length > 17) return false;
  if (!clientDataDto.email || 0 === clientDataDto.email.length || !validateEmail(clientDataDto.email)) return false;

  if (clientDataDto.billType === BillTypeEnum.CompanyBill) {
    if (!clientDataDto.company || 0 === clientDataDto.company.length) return false;
    if (!clientDataDto.nip || 10 !== clientDataDto.nip.length) return false;
  }
  return true;
}

export type ProductImageDto = {
  url: string,
  alt: string
}