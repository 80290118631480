/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from "react";
import "./orders-panel.scss";
import Helmet from "react-helmet";
import Container from "react-bootstrap/Container";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import MainSection from "../../../../components/main-section/main-section";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { selectOrders } from "../../../../redux/ordersState/ordersState.selectors";
import { clearOrdersState, getAllOrders, setOrdersFiltersLastId } from "../../../../redux/ordersState/ordersState.actions";
import OrderFilters from "../../../../components/order-filters/order-filters";
import { CakeOrderDto } from "../../../../dto/cakeOrderDto";
import SimpleInfiniteScroll from "../../../../components/simple-infinite-scroll/simple-infinite-scroll";

import StatusCol from "./order-panel-statusSelector";
import DeliveryCol from "./order-panel-dataDetailsCol";
import CustomerAndReceiverDataCol from "./order-panel-customerAndReceiverDataCol";
import OrderDetailsCol from "./order-panel-orderDetailsCol";

// type BodyComponent = React.FunctionComponent;
// type OrdersPanel = React.FunctionComponent & { Body: BodyComponent };

// const Card: OrdersPanel = ({ children }): JSX.Element => <>{children}</>;
// const Body: BodyComponent = (): JSX.Element => <>Body</>;

// OrdersPanel.Body = Body;

const OrdersPanel: React.FC<any> = () => {


  const orders = useSelector(selectOrders, shallowEqual) as Array<CakeOrderDto>

  const dispatch = useDispatch();
  const [firstLoad, setfirstLoad] = useState(true);

  useEffect(() => {
    dispatch(getAllOrders());
    return () => {
      dispatch(clearOrdersState());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (firstLoad && orders && orders.length > 0) {
      setfirstLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders])

  const getOrders = (lastId: number | undefined) => {
    if (lastId) {
      dispatch(setOrdersFiltersLastId(lastId))
    }
    dispatch(getAllOrders());
  }

  return (
    <>
      <Helmet>
        <title>{'Zamówienia'}</title>
      </Helmet>

      <div className="orders-panel">
        <Container fluid={false}>
          <MainSection
            title="Panel obsługi zamówień"
          // subtitle={<>W tym panelu znajduje się lista Twoich potencjalnych klientów, którzy zostawili swój email. Znajdziesz tu informacje takie jak:  data dołączenia, informacja o Sugestii, z której dokonano zapisu oraz czy dana osoba potwierdziła adres. <OverlayTrigger trigger={["hover", "focus"]} placement="bottom" overlay={whatIsGoingOnWithContacts}>
          //   <i className="fa fa-question-circle" aria-hidden="true" />
          // </OverlayTrigger></>}
          >
          </MainSection>
          <div className="leads-wrapper" >
            <OrderFilters />
          </div>
          <div className="leads-wrapper" >
            {orders && orders.length > 0 ?
              <>
                <Row className="lead-item lead-item-header">
                  <Col xl={{ span: 1 }} className="cellHeader">
                    Zamówienie
                  </Col>
                  <Col xl={{ span: 3 }} className="cellHeader">
                    Szczegóły odbioru
                  </Col>
                  <Col xl={{ span: 3 }} className="cellHeader">
                    Szczegóły zamówienia
                  </Col>
                  <Col xl={{ span: 4 }} className="cellHeader">
                    Status zamówienia
                  </Col>
                </Row>
                <SimpleInfiniteScroll loadData={getOrders} items={orders}>
                  {orders.map((order: CakeOrderDto, index: number) => {
                    return (
                      <Row className="lead-item" key={index}>
                        <DeliveryCol order={order} index={index} />
                        <CustomerAndReceiverDataCol order={order} index={index} />
                        <OrderDetailsCol order={order} index={index} />
                        <StatusCol index={index} />
                      </Row>
                    )
                  })}
                </SimpleInfiniteScroll>
              </> :
              <div style={{ textAlign: "center" }}>
                Nie znaleziono żadnego zamówienia.
              </div>
            }
          </div>
        </Container>
      </div>
    </>
  )
}

export default OrdersPanel;