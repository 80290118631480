import "./App.scss";
import "./argon/argon-design-system-react.scss";
import "./argon/vendor/font-awesome/css/font-awesome.min.css";
import React, { useEffect } from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import { History } from 'history';
import { RoutePath } from "./route-paths";
import { compose } from "redux";
import { connect, useSelector, shallowEqual, useDispatch } from "react-redux";
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from "react-toasts";
import { RulesPage } from "./framework/pages/rules-page/rules-page";
import { selectCurrentUser } from "./framework/redux/user/user.selectors";
import { getAllProducts } from "./framework/redux/productsState/productsState.actions";
import HomePage from "./framework/pages/home-page/home-page";
import CookiePolicyPopup from "./framework/components/cookie-policy-popup/cookie-policy-popup";
import SignInPage from "./framework/pages/signinup-page/signin-page";
import AuthorizedPageSwitch from "./framework/pages/authorized-page/authorized-page-switch";
import GlobalModal from "./framework/components/global-modal/global-modal";
import ConfirmEmailPage from "./framework/pages/confirm-email/confirmEmail-page";
import StandardPage from "./framework/pages/home-page/standard-page";
import OfertaPage from "./framework/pages/home-page/oferta-page";
import OkolicznosciowePage from "./framework/pages/home-page/okolicznosciowe-page";
import DlafirmPage from "./framework/pages/home-page/dlafirm-page";
import DeliveryPage from "./framework/pages/home-page/delivery-page";
import StandardProductPage from "./framework/pages/home-page/products/standard-product-page";
import KreatorPage from "./framework/pages/home-page/creator-page/kreator-page";
import CartPage from "./framework/pages/home-page/cart-page/cart-page";
import SlideOverlay from "./framework/components/slide-overlay/slide-overlay";
import ImagesPreloader from "./framework/components/images-preloader/images-preloader";
import UrlValidator from "./framework/redux/creatorState/ulrValidator";
import PaymentStatusPage from "./framework/pages/payment-status-page/payment-status-page";

interface Props {
  match: any;
  history?: History;
}

const App: React.FC<Props> = () => {
  const user = useSelector(selectCurrentUser, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllProducts());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <SlideOverlay />
      <ImagesPreloader />
      <Switch>
        {/* <Route exact path={RoutePath.HOME_PANEL} render={() => (user && user.token) ? <Redirect to={RoutePath.HOME_PANEL} /> : <HomePage />} /> */}

        <Route exact path={RoutePath.HOME} render={() => <HomePage />} />
        <Route exact path={RoutePath.STANDARD} component={() => <StandardPage />} />
        <Route exact path={RoutePath.OKOLICZNOSCIOWE} component={() => <OkolicznosciowePage />} />
        <Route exact path={RoutePath.DLAFIRM} component={() => <DlafirmPage />} />

        <Route exact path={RoutePath.PRODUCT} component={() => <StandardProductPage />} />

        {/* <Route exact path={RoutePath.STANDARDPRODUCT} component={() => <StandardProductPage />} />
        <Route exact path={RoutePath.OCCASIONALPRODUCT} component={() => <StandardProductPage />} />
        <Route exact path={RoutePath.BUSINESSPRODUCT} component={() => <StandardProductPage />} /> */}

        <Route exact path={RoutePath.KREATOR} component={KreatorPage} />
        <Route exact path={RoutePath.OFERTA} component={() => <OfertaPage />} />
        <Route exact path={RoutePath.DELIVERYPOINTS} component={() => <DeliveryPage />} />
        <Route exact path={RoutePath.CART} component={() => <CartPage />} />

        <Route exact path={RoutePath.RULES} component={() => <RulesPage />} />
        <Route exact path={RoutePath.PAYMENT_STATUS} component={() => <PaymentStatusPage />} />

        {/* <Route exact path={RoutePath.HOME_PANEL} render={() =>  <HomePage />} /> */}


        <Route exact path={RoutePath.SIGNIN} render={() => (user && user.token) ? <Redirect to={RoutePath.HOME_PANEL} /> : <SignInPage />} />
        {/* <Route exact path={RoutePath.SIGNUP} render={() => (user && user.token) ? <Redirect to={RoutePath.HOME_PANEL} /> : <SignUpPage />} /> */}
        {/* <Route exact path={RoutePath.BLOG} render={() => (user && user.token) ? <Redirect to={RoutePath.HOME_PANEL} /> : <BlogPage />} /> */}
        {/* <Route exact path={RoutePath.RESETPASSWORD} render={() => (user && user.token) ?
          <Redirect to={RoutePath.HOME_PANEL} /> : <ResetPasswordPage />} /> */}
        <Route path={RoutePath.AUTHORIZED} component={() => (user && user.token) ? <AuthorizedPageSwitch /> : <Redirect to={RoutePath.HOME} />} />
        <Route exact path={RoutePath.CONFIRMEMAIL} render={ConfirmEmailPage} />
      </Switch>
      <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} />
      <GlobalModal />
      <CookiePolicyPopup />
      <UrlValidator />

    </>
  );
}

export default compose(
  withRouter,
  connect(null, null)
)(App);