/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import { OrderStatusEnum } from "../../../../dto/orderStatusEnum";
import { CakeOrderDto } from "../../../../dto/cakeOrderDto";
import { updateOrderStatus } from "../../../../redux/ordersState/ordersState.actions";
import { UpdateOrderStatusDto } from "../../../../dto/updateOrderStatusDto";


const statusMessages = [
  {
    status: OrderStatusEnum.PaymentIntent,
    msg: "1Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque quasi enim aut magni voluptates officiis esse aliquam hic laudantium, recusandae itaque, odit optio sed consectetur pariatur quis laboriosam provident harum."
  },
  {
    status: OrderStatusEnum.Canceled,
    msg: "2Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque quasi enim aut magni voluptates officiis esse aliquam hic laudantium, recusandae itaque, odit optio sed consectetur pariatur quis laboriosam provident harum."
  },
  {
    status: OrderStatusEnum.PaymentSuccess,
    msg: "3Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque quasi enim aut magni voluptates officiis esse aliquam hic laudantium, recusandae itaque, odit optio sed consectetur pariatur quis laboriosam provident harum."
  },
  {
    status: OrderStatusEnum.InRealization,
    msg: "4Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque quasi enim aut magni voluptates officiis esse aliquam hic laudantium, recusandae itaque, odit optio sed consectetur pariatur quis laboriosam provident harum."
  },
  {
    status: OrderStatusEnum.RealizedAndWaitingForDelivery,
    msg: "5Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque quasi enim aut magni voluptates officiis esse aliquam hic laudantium, recusandae itaque, odit optio sed consectetur pariatur quis laboriosam provident harum."
  },
  {
    status: OrderStatusEnum.RealizedAndWaitingForReceiver,
    msg: "6Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque quasi enim aut magni voluptates officiis esse aliquam hic laudantium, recusandae itaque, odit optio sed consectetur pariatur quis laboriosam provident harum."
  },
  {
    status: OrderStatusEnum.Received,
    msg: "7Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque quasi enim aut magni voluptates officiis esse aliquam hic laudantium, recusandae itaque, odit optio sed consectetur pariatur quis laboriosam provident harum."
  }
];

interface Props {
  isSetStatusModalOpen: boolean;
  setIsSetStatusModalOpen: any;
  status?: OrderStatusEnum;
  order: CakeOrderDto;
}

const SetStatusModal = (props: Props) => {

  const { isSetStatusModalOpen, setIsSetStatusModalOpen, status, order } = props;
  const [modalStatusMsg, setModalStatus] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    setModalStatus(status ? statusMessages.filter(x => x.status === status)[0].msg : "")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSetStatusModalOpen])

  const changeStatus = (sendMsg: boolean) => {
    dispatch(updateOrderStatus({ orderId: order.id, status: status, Message: sendMsg ? modalStatusMsg : "" } as UpdateOrderStatusDto))
  }

  return (
    <Modal size={'lg'} show={isSetStatusModalOpen} onHide={() => setIsSetStatusModalOpen(undefined)} className="addEditDedicationModal">
      <Modal.Header closeButton>
        <p className={'modalTitle'}>Potwierdź zmianę statusu</p>
      </Modal.Header>
      <Modal.Body className="addEditDedication">
        <form onSubmit={e => { e.preventDefault(); }}>
          <Form.Label>
            Wiadomość email do klienta, wysyłana przy zmianie statusu
          </Form.Label>
          <Form.Control
            as="textarea"
            className="form-control-alternative"
            name="statusMessage"
            type="text"
            rows={4}
            placeholder="Wiadomość"
            value={modalStatusMsg}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setModalStatus(e.target.value)}
            required
          />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => {
          changeStatus(true);
          setIsSetStatusModalOpen(undefined)
        }}>
          Potwierdź i wyślij email
        </Button>
        <Button variant="primary" onClick={() => {
          changeStatus(false);
          setIsSetStatusModalOpen(undefined)
        }}>
          Potwierdź bez wysyłania
        </Button>
        {/* <Button variant="primary" onClick={() => {
          setModalStatus(modalStatusMsg);
          setIsSetStatusModalOpen(undefined)
        }}>
          Anuluj
      </Button> */}
      </Modal.Footer>
    </Modal>
  )
}

export default SetStatusModal;