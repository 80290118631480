import React from "react";
import "./../../cart-page.scss";
import "./../cart-panel.scss";

import Form from "react-bootstrap/Form";
import { BillTypeEnum, ClientDataDto, DeliveryPanelDto, DeliveryTypeEnum, ReceiverDataDto, ReceiverTypeEnum } from "../../../../../dto/cartItemDto";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setClientData } from "../../../../../redux/cartState/cartState.actions";
import { selectClientData, selectDeliveryPanel, selectReceiverData } from "../../../../../redux/cartState/cartState.selectors";
import { validateEmail } from "../../../../../tools/emailValidator";

interface Props {
  isValidationActive: boolean
}


const CartDataClientForm: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const clientData = useSelector(selectClientData, shallowEqual) as ClientDataDto;
  const receiverData = useSelector(selectReceiverData, shallowEqual) as ReceiverDataDto;
  const deliveryPanel = useSelector(selectDeliveryPanel, shallowEqual) as DeliveryPanelDto;

  const { isValidationActive } = props;

  const setInputValue = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    dispatch(setClientData({ ...clientData, [name]: value } as ClientDataDto));
  }

  return (
    <form onSubmit={e => { e.preventDefault(); }}>
      <div className="input-wrapper">
        <Form.Label>Imię zamawiającego</Form.Label>
        <Form.Control
          className="form-control-alternative"
          name="name"
          type="text"
          placeholder="Imię odbiorcy"
          isValid={isValidationActive && !(!clientData.name || 0 === clientData.name.length)}
          isInvalid={isValidationActive && (!clientData.name || 0 === clientData.name.length)}
          value={clientData.name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e)}
          required
        />
      </div>
      <div className="input-wrapper">
        <Form.Label>Nazwisko zamawiającego</Form.Label>
        <Form.Control
          className="form-control-alternative"
          name="surname"
          type="text"
          isValid={isValidationActive && !(!clientData.surname || 0 === clientData.surname.length)}
          isInvalid={isValidationActive && (!clientData.surname || 0 === clientData.surname.length)}
          placeholder="Nazwisko odbiorcy"
          value={clientData.surname}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e)}
          required
        />
      </div>
      {clientData.billType === BillTypeEnum.CompanyBill ?
        <>
          <div className="input-wrapper">
            <Form.Label>Nazwa firmy</Form.Label>
            <Form.Control
              className="form-control-alternative"
              name="company"
              type="text"
              isValid={isValidationActive && !(!clientData.company || 0 === clientData.company.length)}
              isInvalid={isValidationActive && (!clientData.company || 0 === clientData.company.length)}
              placeholder="Nazwa firmy"
              value={clientData.company}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e)}
              required
            />
          </div>
          <div className="input-wrapper">
            <Form.Label>NIP firmy</Form.Label>
            <Form.Control
              className="form-control-alternative"
              name="nip"
              type="text"
              isValid={isValidationActive && !(!clientData.nip || 10 !== clientData.nip.length)}
              isInvalid={isValidationActive && (!clientData.nip || 10 !== clientData.nip.length)}
              placeholder="NIP"
              value={clientData.nip}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e)}
              required
            />
          </div>
        </>
        : null}
      <div className="input-wrapper">
        <Form.Label>Ulica i nr. lokalu</Form.Label>
        <Form.Control
          className="form-control-alternative"
          name="streetAndHomeNumber"
          type="text"
          isValid={isValidationActive && !(!clientData.streetAndHomeNumber || 0 === clientData.streetAndHomeNumber.length)}
          isInvalid={isValidationActive && (!clientData.streetAndHomeNumber || 0 === clientData.streetAndHomeNumber.length)}
          placeholder="Ulica i nr. lokalu"
          value={clientData.streetAndHomeNumber}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e)}
          required
        />
      </div>
      <div className="input-wrapper">
        <Form.Label>Kod pocztowy</Form.Label>
        <Form.Control
          className="form-control-alternative"
          name="postCode"
          type="text"
          isValid={isValidationActive && !(!clientData.postCode || (5 !== clientData.postCode.length && 6 !== clientData.postCode.length))}
          isInvalid={isValidationActive && (!clientData.postCode || (5 !== clientData.postCode.length && 6 !== clientData.postCode.length))}
          placeholder="Kod pocztowy"
          value={clientData.postCode}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e)}
          required
        />
      </div>
      <div className="input-wrapper">
        <Form.Label>Miasto</Form.Label>
        <Form.Control
          className="form-control-alternative"
          name="city"
          type="text"
          isValid={isValidationActive && !((!clientData.city || 0 === clientData.city.length) || ((receiverData.receiverType === ReceiverTypeEnum.Client && deliveryPanel.deliveryType === DeliveryTypeEnum.Delivery) && !['Wrocław', 'Wroclaw', "WROCŁAW", "WROCLAW", 'wrocław', 'wroclaw'].some(x => x === clientData.city)))}
          isInvalid={isValidationActive && ((!clientData.city || 0 === clientData.city.length) || ((receiverData.receiverType === ReceiverTypeEnum.Client && deliveryPanel.deliveryType === DeliveryTypeEnum.Delivery) && !['Wrocław', 'Wroclaw', "WROCŁAW", "WROCLAW", 'wrocław', 'wroclaw'].some(x => x === clientData.city)))}
          placeholder="Miasto"
          value={clientData.city}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e)}
          required
        />
      </div>
      <div className="input-wrapper">
        <Form.Label>Telefon odbiorcy</Form.Label>
        <Form.Control
          className="form-control-alternative"
          name="phone"
          type="text"
          isValid={isValidationActive && !(!clientData.phone || clientData.phone.length < 6 || clientData.phone.length > 17)}
          isInvalid={isValidationActive && (!clientData.phone || clientData.phone.length < 6 || clientData.phone.length > 17)}
          placeholder="Telefon zamawiającego"
          value={clientData.phone}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e)}
          required
        />
      </div>
      <div className="input-wrapper">
        <Form.Label>Email odbiorcy</Form.Label>
        <Form.Control
          className="form-control-alternative"
          name="email"
          type="email"
          isValid={isValidationActive && !(!clientData.email || 0 === clientData.email.length || !validateEmail(clientData.email))}
          isInvalid={isValidationActive && (!clientData.email || 0 === clientData.email.length || !validateEmail(clientData.email))}
          placeholder="Email zamawiającego"
          value={clientData.email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e)}
          required
        />
      </div>
      <div className="input-wrapper">
        <Form.Label>Informacje dodatkowe</Form.Label>
        <Form.Control
          className="form-control-alternative"
          name="message"
          type="text"
          isValid={isValidationActive}
          isInvalid={false}
          maxLength={300}
          placeholder="Informacje dla dostawcy"
          value={clientData.message}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e)}
        />
      </div>
    </form>
  )
}

export default CartDataClientForm;