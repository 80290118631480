
const qs = require('query-string');

const setQueryStringWithoutPageReload = (qsValue: any) => {
  const newurl = window.location.protocol + "//" +
    window.location.host +
    window.location.pathname +
    qsValue;

  window.history.pushState({ path: newurl }, "", newurl);
};


export const setQueryStringValue = (
  key: string,
  value: any,
  queryString = window.location.search
) => {
  const values = qs.parse(queryString);
  const newQsValue = qs.stringify({ ...values, [key]: value }, {arrayFormat: 'comma', skipEmptyString: true, skipNull: true});
  const newQsValueWithQuestionMark = newQsValue === '' ? '' : `?${newQsValue}`
  setQueryStringWithoutPageReload(newQsValueWithQuestionMark);
};

export const getQueryStringValue = (
  key: string,
  queryString = window.location.search
) => {
  const values = qs.parse(queryString);
  return values[key];
};