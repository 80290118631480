import React, { ReactNode, useState } from "react";
import "./navbar-landing.scss";
import Navbar from "react-bootstrap/Navbar";
import LogoImg from "./../../assets/logo.svg";
import BasketImg from "./../../assets/basket.svg";

import { RoutePath } from "../../../route-paths";
import Container from "react-bootstrap/Container";
import TopLink from "../top-link/top-link";
import { selectCartItems } from "../../redux/cartState/cartState.selectors";
import { CartItemDto } from "../../dto/cartItemDto";
import { useSelector, shallowEqual } from "react-redux";


interface Props {
  isSubNavHidden?: boolean;
  children?: ReactNode
}

const NavbarLanding: React.FC<Props> = (props: Props) => {
  const [navExpanded, setNavExpanded] = useState(false);
  const cartItems = useSelector(selectCartItems, shallowEqual) as Array<CartItemDto>

  return (
    <div className="navbarLanding" >
      <Navbar expanded={navExpanded} collapseOnSelect={true} expand="lg">
        <Container>
          <div className="brand">
            <TopLink to={RoutePath.HOME}>
              <img src={LogoImg} alt="LogoImg" />
            </TopLink>
          </div>
          <Navbar.Toggle className={"hideOnTablet"} aria-controls="responsive-navbar-nav" onClick={() => setNavExpanded(!navExpanded)} />
          <Navbar.Collapse className={"hideOnTablet"}  id="responsive-navbar-nav" onClick={() => setNavExpanded(false)}>
            <>
              {props.isSubNavHidden ?
                null : <div className="midNav">
                  {
                    React.Children.map(props.children, Child => {
                      return <div className="linkWrapper" onClick={() => window.scrollTo(0, 0)}>{Child}</div>
                    })
                  }
                </div>
              }
            </>
          </Navbar.Collapse>
          <TopLink to={RoutePath.CART}>
            <div className="actionNav">
              <div className="basketWrapper">
                <img src={BasketImg} alt="" />
                {cartItems && cartItems.length > 0 ?
                  <div className="numberOfProducts">{cartItems.length}</div>
                  : null}
              </div>
            </div>
          </TopLink>
        </Container>
      </Navbar>
    </div>
  )
}

export default NavbarLanding;