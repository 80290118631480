/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { } from "react";
import "./home-page.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TopLink from "../../components/top-link/top-link";
import { RoutePath } from "../../../route-paths";

const SweetDream: React.FC<any> = () => {
  return (
    <section className="actionSection">
      <Container>
        <Row>
          <Col xl={{ span: 5 }} className="col">
            <h4>Słodka przyjemność</h4>
            <br />
            <p>
              Zamów przepyszny tort z tradycyjnej oferty lub użyj kreatora. Spraw, by ważne chwile, zostały dobrze zapamiętane. Podaruj radość sobie i bliskim.
            </p>
            <br/>
            <TopLink to={RoutePath.DELIVERYPOINTS}>
            <div className="btn btn-outline-dark">Sprawdź punkty odbioru</div>
            </TopLink>
          </Col>
          <Col xl={{ span: 5, offset: 2 }} className="col">
            <h2 style={{ fontSize: '4rem' }}>Cukiernia S.R.Jaworscy</h2>
            {/* <h5>Gwarancja smaku i świeżości.</h5> */}
            <p><strong style={{ fontWeight: 500 }}>Pieczemy torty od 30 lat!</strong></p>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default SweetDream;