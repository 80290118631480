import { createSelector } from 'reselect';
import { JaworscyStore } from '../store.types';

const creatorState = (state: JaworscyStore) => state.creatorState;

export const selectCakeId = createSelector(
  [creatorState],
  creatorState => {
    return creatorState.cakeId
  }
);

export const selectActivePanel = createSelector(
  [creatorState],
  creatorState => {
    return creatorState.activePanel
  }
);

export const selectCakeSize = createSelector(
  [creatorState],
  creatorState => {
    return creatorState.cakeSize
  }
);

export const selectCakeBottom = createSelector(
  [creatorState],
  creatorState => {
    return creatorState.cakeBottom
  }
);

export const selectCakeCover = createSelector(
  [creatorState],
  creatorState => {
    return creatorState.cakeCover
  }
);

export const selectCakeCoverDetails = createSelector(
  [creatorState],
  creatorState => {
    return creatorState.cakeCoverDetails
  }
);

export const selectCakeSide = createSelector(
  [creatorState],
  creatorState => {
    return creatorState.cakeSide
  }
);

export const selectCakeTastes = createSelector(
  [creatorState],
  creatorState => {
    return creatorState.cakeTastes
  }
);

export const selectCakeTopAddons = createSelector(
  [creatorState],
  creatorState => {
    return creatorState.cakeTopAddons
  }
);

export const selectCakeHasSmoothTop = createSelector(
  [creatorState],
  creatorState => {
    return creatorState.cakeHasSmoothTop
  }
);

export const selectDedication = createSelector(
  [creatorState],
  creatorState => {
    return creatorState.dedication
  }
);

export const selectSingleCandles = createSelector(
  [creatorState],
  creatorState => {
    return creatorState.singleCandles
  }
);

export const selectFigureCandles = createSelector(
  [creatorState],
  creatorState => {
    return creatorState.figureCandles
  }
);