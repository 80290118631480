export type ProductDto = {
  id: number,
  name: string,
  url: string,
  moreInfoDescription: string,
  description: string,
  offerType: OfferTypeEnum,
  images: Array<ProductImageDto>,
  availableWeights: Array<number>
}

export type ProductImageDto = {
  url: string,
  alt: string
}

export enum OfferTypeEnum {
  Standard = 1,
  Occasional = 2,
  Business = 3,
}