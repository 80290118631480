/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { CoverOptionDetailsDto, CoverOptionDto, CreatorOptionDto, SimpleCreatorOptionDto } from "../../dto/creatorDto";
import { CreatorBottomsList, CreatorCoversList, CreatorSidesList, CreatorTastesList, CreatorTopAddonsList } from "../../pages/home-page/creator-page/creator-assets/creatorData/creator.data";
import CreamIconComponent from "../colorIcons/cream-icon";
import SugarMassIconComponent from "../colorIcons/sugerMass-icon";
import "./images-preloader.scss";

import CakeIcon from './../../pages/home-page/cart-page/cart-assets/cartCake.svg';
import CandleSinIcon from './../../pages/home-page/cart-page/cart-assets/candleSing.svg';
import CandleFigIcon from './../../pages/home-page/cart-page/cart-assets/candleNum.svg';

import SmoothTopAddonIcon from './../../pages/home-page/creator-page/creator-assets/kreatorIcons/smoothTopAddon.svg';
import AddCircleIcon from './../../pages/home-page/creator-page/creator-assets/kreatorIcons/addCircleIcon.svg';

interface Props {
}

const ImagesPreloader = (props: Props) => {

  return (
    <div className={'images-preloader'}>
      {CreatorTastesList.map((x: SimpleCreatorOptionDto, index: number) => {
        return (
          <img key={index + 1000} src={x.icon} alt="" />
        )
      })}
      {CreatorBottomsList.map((x: SimpleCreatorOptionDto, index: number) => {
        return (
          <img key={index + 2000} src={x.icon} alt="" />
        )
      })}
      {CreatorCoversList.map((x: CoverOptionDto, index: number) => {
        return (
          <div key={index + 3000} className="images-preloader-img">
            <img src={x.icon} alt="" />
            {x.details.map((y: CoverOptionDetailsDto, iindex: number) => {
              if (y.icon)
                return (
                  // eslint-disable-next-line jsx-a11y/alt-text
                  <img key={(index * 10000) + iindex} src={y.icon!} />
                )
              else
                return <div key={(index * 10000) + iindex} className="images-preloader-img"> </div>
            })}
          </div>
        )
      })}

      <CreamIconComponent mainColor={'white'} />
      <SugarMassIconComponent mainColor={'white'} />

      {CreatorSidesList.map((x: CreatorOptionDto, index: number) => {
        if (typeof x.icon === 'string')
          return (
            <img key={index + 5000} src={x.icon} alt="" />
          )
        else return x.icon
      })}

      {CreatorTopAddonsList.map((x: SimpleCreatorOptionDto, index: number) => {
        if (typeof x.icon === 'string')
          return (
            <img key={index + 6000} src={x.icon} alt="" />
          )
        else return x.icon
      })}

      <img src={CakeIcon} alt="" />
      <img src={CandleSinIcon} alt="" />
      <img src={CandleFigIcon} alt="" />
      <img src={SmoothTopAddonIcon} alt="" />
      <img src={AddCircleIcon} alt="" />

    </div>
  )
}

export default ImagesPreloader;