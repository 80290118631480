const UserActionTypes = {
  AUTH_SIGNUP: 'AUTH_SIGNUP',
  AUTH_SIGNUPTUTORIALFINISH: 'AUTH_SIGNUPTUTORIALFINISH',
  // RESET_PASSWORD: 'RESET_PASSWORD',
  AUTH_SIGNUP_SUCCESS: 'AUTH_SIGNUP_SUCCESS',
  AUTH_FAILURE: 'AUTH_FAILURE',
  AUTH_REQUEST: 'AUTH_REQUEST',
  SIGN_IN: 'SIGN_IN',
  SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
  SIGN_IN_FAILURE: 'SIGN_IN_FAILURE',
  SIGN_OUT: 'SIGN_OUT',
  SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
  GET_AUTH: 'GET_AUTH',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  TOKEN_REFRESH_FAILURE: 'TOKEN_REFRESH_FAILURE',
  TOKEN_REFRESH_SUCCESS: 'TOKEN_REFRESH_SUCCSESS',
  UPDATE_REQUEST_TIME: 'UPDATE_REQUEST_TIME',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',

  USER_CLEAR_STORE: 'USER_CLEAR_STORE',

  GET_USERROLES: 'GET_USERROLES',
  GET_USERROLES_SUCCESS: 'GET_USERROLES_SUCCESS',
};

export default UserActionTypes;