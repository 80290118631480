/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { selectCakeId, selectActivePanel, selectCakeSize, selectCakeBottom, selectCakeCover, selectCakeCoverDetails, selectCakeSide, selectCakeTastes, selectCakeTopAddons, selectCakeHasSmoothTop, selectDedication, selectSingleCandles, selectFigureCandles } from "./creatorState.selectors";
import { setActivePanel, setCakeBottom, setCakeCover, setCakeCoverDetails, setCakeId, setCakeSide, setCakeSize, setCakeTastes, setCakeTopAddons, setDedication, setFigureCandles, setSingleCandles } from "./creatorState.actions";
import { CreatorCoversList, CreatorSidesList, CreatorTastesList, CreatorTopAddonsList } from "../../pages/home-page/creator-page/creator-assets/creatorData/creator.data";

interface Props {

}

const UrlValidator: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const cakeId = useSelector(selectCakeId, shallowEqual);
  const activePanel = useSelector(selectActivePanel, shallowEqual);
  const cakeSize = useSelector(selectCakeSize, shallowEqual);
  const cakeBottom = useSelector(selectCakeBottom, shallowEqual);
  const cakeCover = useSelector(selectCakeCover, shallowEqual);
  const cakeCoverDetails = useSelector(selectCakeCoverDetails, shallowEqual);
  const cakeSide = useSelector(selectCakeSide, shallowEqual);
  const cakeTastes = useSelector(selectCakeTastes, shallowEqual);
  const cakeTopAddons = useSelector(selectCakeTopAddons, shallowEqual);
  const cakeHasSmoothTop = useSelector(selectCakeHasSmoothTop, shallowEqual);
  const dedication = useSelector(selectDedication, shallowEqual);
  const singleCandles = useSelector(selectSingleCandles, shallowEqual);
  const figureCandles = useSelector(selectFigureCandles, shallowEqual);

  useEffect(() => {
    if (activePanel === undefined) return;
    if (activePanel > 0 && activePanel <= 7) return;
    dispatch(setActivePanel(0));
  }, [activePanel])

  useEffect(() => {
    let availableSize = [1, 1.5, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
    if (cakeSize === undefined || availableSize.indexOf(cakeSize) !== -1) return;
    dispatch(setCakeSize(undefined))
  }, [cakeSize])

  useEffect(() => {
    let availableBottom = [0, 1]
    if (cakeBottom === undefined || availableBottom.indexOf(cakeBottom) !== -1) return;
    dispatch(setCakeBottom(undefined))
  }, [cakeBottom])

  useEffect(() => {
    if (cakeTastes === []) return;
    const availableTastes = CreatorTastesList.map((option, index) => { return index; });
    for (let tasteIndex = 0; tasteIndex < cakeTastes.length; tasteIndex++) {
      if (availableTastes.indexOf(cakeTastes[tasteIndex]) === -1) {
        dispatch(setCakeTastes([]));
        return;
      }
    }
  }, [cakeTastes])

  useEffect(() => {
    if (cakeTastes === undefined) return;
    const availableCover = CreatorCoversList.map((option, index) => { return index; })
    if (availableCover.indexOf(cakeCover) !== -1) return;
    dispatch(setCakeCover(undefined));
    if (cakeCoverDetails !== undefined) dispatch(setCakeCoverDetails(undefined));
  }, [cakeCover])

  useEffect(() => {
    if (cakeCoverDetails === undefined) return;
    if (cakeCover === undefined) {
      dispatch(setCakeCoverDetails(undefined));
      return;
    }


    let availableCoverDetails = CreatorCoversList[cakeCover].details.map((option, index) => { return index; })
    if (availableCoverDetails.indexOf(cakeCoverDetails) !== -1) return;
    else dispatch(setCakeCoverDetails(undefined));
  }, [cakeCoverDetails])


  useEffect(() => {
    if (cakeSide === undefined) return;
    let availableCakeSides = CreatorSidesList.map((option, index) => { return index; })
    if (availableCakeSides.indexOf(cakeSide) !== -1) return;
    else dispatch(setCakeSide(undefined));
  }, [cakeSide])


  useEffect(() => {
    if (cakeTopAddons === []) return;

    let availableTopAddons = CreatorTopAddonsList.map((option, index) => { return index; })
    for (let topAddonIndex = 0; topAddonIndex < cakeTopAddons.length; topAddonIndex++) {
      if (availableTopAddons.indexOf(cakeTopAddons[topAddonIndex]) === -1) {
        dispatch(setCakeTopAddons([]));;
        return;
      }
    }
  }, [cakeTopAddons])

  useEffect(() => {
    if (cakeHasSmoothTop === false) return;
    if (cakeHasSmoothTop === true) return;
    //?????????
  }, [cakeHasSmoothTop])

  useEffect(() => {
    if (dedication === undefined) return;
    if (dedication.length >= 0 && dedication.length <= 120) return;
    dispatch(setDedication(undefined));
  }, [dedication])

  useEffect(() => {
    if (cakeId === undefined) return;
    if (cakeId.length === 36) return;
    dispatch(setCakeId(undefined));
  }, [cakeId])

  useEffect(() => {
    if (figureCandles === undefined) return;
    if (figureCandles > 0 && figureCandles <= 120) return;
    dispatch(setFigureCandles(undefined));
  }, [figureCandles])

  useEffect(() => {
    if (singleCandles === undefined) return;
    if (singleCandles > 0 && singleCandles <= 120) return;
    dispatch(setSingleCandles(undefined));
  }, [singleCandles])


  return (
    <>
    </>
  );
}

export default UrlValidator;