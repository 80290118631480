/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./../home-page.scss";
import "./../standard-page.scss";
import "./kreator-page.scss";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { setActivePanel } from "../../../redux/creatorState/creatorState.actions";
import { selectActivePanel } from "../../../redux/creatorState/creatorState.selectors";

interface Props {
}

const CreatorPrevBtn = (props: Props) => {

  const dispatch = useDispatch();
  const activePanel = useSelector(selectActivePanel, shallowEqual) as number;

  const handleSetActivePanel = (active: number) => {
    dispatch(setActivePanel(active))
  }

  return (
    <div className={'kreator-btn kreator-btn--prev'}>
      <div onClick={() => handleSetActivePanel(activePanel === 0 ? 0 : activePanel - 1)} className={`btn btn-outline-dark ${activePanel === 0 ? 'disabled' : ''}`}>&nbsp;&lsaquo;&nbsp;&nbsp;&nbsp;cofnij&nbsp;&nbsp;</div>
    </div>
  )
}

export default CreatorPrevBtn;