import { createSelector } from 'reselect';
import { JaworscyStore } from '../store.types';

const leadsState = (state: JaworscyStore) => state.leadsState;

export const selectLeads = createSelector(
  [leadsState],
  leadsState => {
    return leadsState.leads
  }
);