import CartStateTypes from './cartState.types';
import { BillTypeEnum, CartItemDto, ClientDataDto, DeliveryPanelDto, ReceiverDataDto } from '../../dto/cartItemDto';

const INITIAL_STATE = {
  cartItems: [] as Array<CartItemDto>,
  deliveryPanel: {
    deliveryTime: undefined,
    deliveryType: undefined,
    deliveryShop: undefined,
  } as DeliveryPanelDto,
  dataPanel: {
    receiverData: {
      name: '',
      surname: '',
      streetAndHomeNumber: '',
      postCode: '',
      city: '',
      phone: '',
      email: '',
      receiverType: undefined,
    } as ReceiverDataDto,
    clientData: {
      name: '',
      surname: '',
      company: '',
      nip: '',
      streetAndHomeNumber: '',
      postCode: '',
      city: '',
      phone: '',
      email: '',
      message: '',
      billType: BillTypeEnum.CashRegister
    } as ClientDataDto
  }
}

const cartStateReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case CartStateTypes.ADD_CARTITEM:
      let allItems = [...(state.cartItems.filter(x => x.id !== action.payload.id)), action.payload];
      return {
        ...state,
        cartItems: allItems
      }
    case CartStateTypes.DELETE_CARTITEM:
      return {
        ...state,
        cartItems: state.cartItems.filter(x => x.id !== action.payload)
      }
    case CartStateTypes.SET_DELIVERYPANEL:
      return {
        ...state,
        deliveryPanel: {
          ...state.deliveryPanel,
          deliveryTime: action.payload.deliveryTime,
          deliveryType: action.payload.deliveryType,
          deliveryShop: action.payload.deliveryShop,
        }
      }
    case CartStateTypes.SET_CLIENTDATA:
      return {
        ...state,
        dataPanel: {
          ...state.dataPanel,
          clientData: action.payload
        }
      }
    case CartStateTypes.SET_RECEIVERDATA:
      return {
        ...state,
        dataPanel: {
          ...state.dataPanel,
          receiverData: action.payload
        }
      }
    case CartStateTypes.CART_CLEAR_STATE:
      return {
        ...state,
        cartItems: [] as Array<CartItemDto>,
        dataPanel: {
          receiverData: {
            name: '',
            surname: '',
            streetAndHomeNumber: '',
            postCode: '',
            city: '',
            phone: '',
            email: '',
            receiverType: undefined,
          } as ReceiverDataDto,
          clientData: {
            name: '',
            surname: '',
            company: '',
            nip: '',
            streetAndHomeNumber: '',
            postCode: '',
            city: '',
            phone: '',
            email: '',
            message: '',
            billType: BillTypeEnum.CashRegister
          } as ClientDataDto
        }
      }
    default: {
      return state;
    }
  }
}

export default cartStateReducer;