import { createSelector } from 'reselect';
import { JaworscyStore } from '../store.types';

const callbacksState = (state: JaworscyStore) => state.callbacksState;

export const selectIsSignUp_Finished = createSelector(
  [callbacksState],
  callbacksState => {
    return callbacksState.is_signUp_finished
  }
);

export const selectIsSignIn_Finished = createSelector(
  [callbacksState],
  callbacksState => {
    return callbacksState.is_signIn_finished
  }
);

export const selectIs_globalToastFailedToFetchActive = createSelector(
  [callbacksState],
  callbacksState => {
    return callbacksState.is_globalToastFailedToFetchActive
  }
);

export const selectGlobalModal = createSelector(
  [callbacksState],
  callbacksState => {
    return callbacksState.globalModal
  }
);

export const select_is_slideOverlayActive = createSelector(
  [callbacksState],
  callbacksState => {
    return callbacksState.is_slideOverlayActive
  }
);