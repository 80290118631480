import React from "react";
import "./orders-panel.scss";
import Col from "react-bootstrap/Col";
import { CakeOrderDto } from "../../../../dto/cakeOrderDto";

import Shop from "./../../../../assets/shop.svg"
import Truck from "./../../../../assets/truck.svg"
import Cashr from "./../../../../assets/cashr.svg"
import Corp from "./../../../../assets/corp.svg"

import { BillTypeEnum, DeliveryTypeEnum } from "../../../../dto/cartItemDto";
import ReactTooltip from "react-tooltip";
import { CartShopsList } from "../../../home-page/cart-page/cart-panels/cartData/cart.data";
import { DeliveryShopEnum } from "../../../../dto/deliveryShopEnum";

interface Props {
  order: CakeOrderDto;
  index: number
}

const DeliveryCol: React.FC<Props> = (props: Props) => {

  var { order, index } = props;
  var dateOptions = { year: 'numeric', month: 'numeric', day: '2-digit' };

  return (
      <Col xl={{ span: 1 }} className="cell info-cell" key={index}>
        <p>
          <span className="medium">NUMER ID</span>
          <span className="tile">{order.id}</span>
        </p>
        <p>
          <span className="medium">Na kiedy</span>
          <span className="tile">{(new Date(order.deliveryDate)).toLocaleDateString('pl-PL', dateOptions)}</span>
        </p>
        <p>
          <span className="medium">Liczba tort.</span>
          <span className="tile">{order.productItems.length - 1}</span>
        </p>
        <div>
          <span className="medium">INFO</span>
          {order.deliveryType === DeliveryTypeEnum.SelfRecive ?
            <>
              <img className={"deliveryIcon deliveryIcon--selfRecive" + (order.deliveryShop === DeliveryShopEnum.Krzemieniecka ? " deliveryIcon--krzemieniecka" : "")} data-tip="React-tooltip" data-for={`selfRecive${index}`} src={Shop} alt="Odbiór w sklepie" />
              <ReactTooltip key={index} id={`selfRecive${index}`} place="top" type="dark" effect="float" >
                <p>Odbiór w lokalu:</p>
                <p>{CartShopsList[order.deliveryShop].streetAndNumber}</p>
              </ReactTooltip>
            </>
            : <>
              <img className="deliveryIcon deliveryIcon--delivery" data-tip="React-tooltip" data-for={`delivery${index}`} src={Truck} alt="Odbiór z dostawą" />
              <ReactTooltip key={index} id={`delivery${index}`} place="top" type="dark" effect="float" >
                <span>Odbiór z dostawą</span>
              </ReactTooltip>
            </>}
          {order.billType === BillTypeEnum.CashRegister ?
            <>
              <img className={"deliveryIcon deliveryIcon--billPar"} data-tip="React-tooltip" data-for={`cashRegoster${index}`} src={Cashr} alt="Odbiór w sklepie" />
              <ReactTooltip key={index} id={`cashRegoster${index}`} place="top" type="dark" effect="float">
                <span>Paragon fiskalny</span>
              </ReactTooltip>
            </>
            : <>
              <img className="deliveryIcon deliveryIcon--billVat" data-tip="React-tooltip" data-for={`companyBill${index}`} src={Corp} alt="Odbiór z dostawą" />
              <ReactTooltip key={index} id={`companyBill${index}`} place="top" type="dark" effect="float" >
                <span>Faktura VAT</span>
              </ReactTooltip>
            </>}
        </div>
      </Col>
  )
}

export default DeliveryCol;