import { takeLatest, put, all, call, select } from "redux-saga/effects";
import { ApiMethodDto } from "../../api/api-method-dto";
import { ApiPath } from "../../api/apiPath";
import { callApi } from "../../api/api-call-service";
import OrdersStateActionTypes from "./ordersState.types";
import { SagaInputDto } from "../../api/saga-input-dto";
import { CakeOrderDto } from "../../dto/cakeOrderDto";
import { UpdateOrderStatusDto } from "../../dto/updateOrderStatusDto";

function* getAllOrdersAsync() {
  const token = yield select(state => state.user.token);
  const ordersFilters = yield select(state => state.ordersState.ordersFilters);
  const options = {
    headers: { Authorization: token, AccessControlAllowOrigin: "*", "Content-Type": "application/json"},
    body: ordersFilters,
    method: "POST",
    expectedStatus: 200,
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "post", ApiPath.payment_getAllPayments, options);
    yield put({
      type: OrdersStateActionTypes.GET_ALL_ORDERS_SUCCESS,
      payload: res.data as Array<CakeOrderDto>
    });
  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* updateOrderStatusAsync(orderFilters: SagaInputDto<UpdateOrderStatusDto>) {
  const token = yield select(state => state.user.token);

  const options = {
    headers: { Authorization: token, AccessControlAllowOrigin: "*", "Content-Type": "application/json"},
    body: orderFilters.payload,
    method: "POST",
    expectedStatus: 200,
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "post", ApiPath.payment_updateOrderStatus, options);
    yield put({
      type: OrdersStateActionTypes.UPDATE_ORDER_STATUS_SUCCESS,
      payload: res.data as CakeOrderDto
    });
  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* watchGetAllLeads() {
  yield takeLatest(OrdersStateActionTypes.GET_ALL_ORDERS as any, getAllOrdersAsync);
}

function* watchUpdateOrderStatus() {
  yield takeLatest(OrdersStateActionTypes.UPDATE_ORDER_STATUS as any, updateOrderStatusAsync);
}

export default function* OrdersStateSaga() {
  yield all([
    call(watchGetAllLeads),
    call(watchUpdateOrderStatus)
  ]);
}