/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "./../home-page/home-page.scss";
import "./../home-page/standard-page.scss";
import Helmet from "react-helmet";
import Container from "react-bootstrap/Container";
import HomeNav from "../home-page/home-nav";
import { GetParamsHelper } from "../../tools/PathParamsHelper";
import history from './../../../history';
import { RoutePath } from "../../../route-paths";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { select_is_waiting_for_payment } from "../../redux/memoCallbacksState/memoCallbacksState.selectors";
import { set_isWaitingForPayment } from "../../redux/memoCallbacksState/memoCallbacksState.actions";

const PaymentStatusPage: React.FC<any> = () => {

  const isWaitingForPayment = useSelector(select_is_waiting_for_payment, shallowEqual) as boolean;
  const dispatch = useDispatch();
  const [status, setStatus] = useState('')

  useEffect(() => {
    if (isWaitingForPayment) {
      let params = GetParamsHelper();
      let statusParam = params.find((x) => x.name === 'status')!.value;
      setStatus(statusParam);
    } else {
      history.push(RoutePath.HOME);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isWaitingForPayment === false) {
      history.push(RoutePath.HOME);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWaitingForPayment])

  useEffect(() => {
    if (status === 'ok') {
      // dispatch(clearCart())
      setTimeout(() => {
        dispatch(set_isWaitingForPayment(false))
      }, 5000);
    } else if (status === 'fail') {
      setTimeout(() => {
        history.push(RoutePath.CART);
        dispatch(set_isWaitingForPayment(false))
      }, 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  return (
    <>
      <div className="home-page standard-page kreator-page">
        <Helmet>
          <title>{'Torty standardowe | TORTY.COM'}</title>
        </Helmet>
        <HomeNav />
        <div className="overlayLoader"></div>
        {status === 'ok' ?
          <section className="trustSection">
            <Container>
              <h2>Dziękujemy za dokonanie zakupów</h2>
              <br/>
              <br/>
              <p>Status Twojego zamówienia, będzie aktualizowany pocztą elektroniczną.</p>
              <br />
              <p>Za chwilę nastąpi przekierowanie na stronę główną.</p>
            </Container>
          </section>
          : null}
        {status === 'fail' ?
          <section className="trustSection">
            <Container>
              <h2>Transakcja nie przebiegła pomyślnie</h2>
              <br/>
              <br/>
              <p>Aby dokończyć zamówienie, spróbuj jeszcze raz.</p>
              <br />
              <p>Za chwilę nastąpi przekierowanie do koszyka.</p>
            </Container>
          </section>
          : null}
      </div>
    </>
  )
}

export default PaymentStatusPage;