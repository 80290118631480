import React from "react";

interface Props {
  mainColor: string,
}

const CreamIconComponent: React.FC<Props> = (props: Props) => {
  const { mainColor } = props;

  return (
    <svg width="114" height="128" viewBox="0 0 114 128" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M44.1926 126.481H1.99268V119.933C1.99268 116.215 5.32766 113.201 9.44148 113.201H104.246C108.36 113.201 111.695 116.215 111.695 119.933V126.481H69.4948" fill="#A3A3A3" />
      <path d="M44.1926 126.481H1.99268V119.933C1.99268 116.215 5.32766 113.201 9.44148 113.201H104.246C108.36 113.201 111.695 116.215 111.695 119.933V126.481H69.4948" stroke="black" strokeWidth="3" />
      <path d="M35.3221 95.6063C30.1389 97.5962 28.9574 100.638 28.9445 103.636C28.9216 108.939 33.7969 113.201 39.6648 113.201H72.6377" fill={mainColor} />
      <path d="M35.3221 95.6063C30.1389 97.5962 28.9574 100.638 28.9445 103.636C28.9216 108.939 33.7969 113.201 39.6648 113.201H72.6377" stroke="black" strokeWidth="3" />
      <path d="M59.7041 96.5347C52.0152 92.9063 42.3122 90.6569 39.6819 83.9755C37.0395 86.6279 34.2113 91.011 36.0449 96.6652C38.5135 104.281 49.5595 113.201 72.6377 113.201H79.0533C79.0533 113.201 77.3333 104.853 59.7041 96.5347Z" fill={mainColor} stroke="black" strokeWidth="3" />
      <path d="M59.7041 96.5347C52.0152 92.9063 42.3122 90.6569 39.6819 83.9755C37.0395 86.6279 34.2113 91.011 36.0449 96.6652C38.5135 104.281 49.5595 113.201 72.6377 113.201H79.0533C79.0533 113.201 77.3333 104.853 59.7041 96.5347Z" fill="#FDFDFD" fillOpacity="0.35" stroke="black" strokeWidth="3" />
      <path d="M60.3364 80.9803C51.3014 74.0139 54.7231 64.4067 54.7231 64.4067C54.7231 64.4067 38.096 69.1224 39.0316 81.1075C39.7332 90.0912 51.0064 92.4312 59.7031 96.535C71.9651 102.321 76.5305 108.121 78.1738 111.117C78.5923 111.879 79.8762 112.044 80.3537 111.316C95.3686 88.4487 67.6264 86.6002 60.3364 80.9803Z" fill={mainColor} stroke="black" strokeWidth="3" />
      <path d="M54.8353 64.2304H48.1295L41.2905 48.2053H61.6743L54.8353 64.2304Z" fill="#A3A3A3" stroke="black" strokeWidth="3" />
      <path d="M65.2849 48.2052H37.6799C35.3994 48.2052 33.5507 46.5344 33.5507 44.4734V38.5027H69.4139V44.4734C69.4142 46.5344 67.5655 48.2052 65.2849 48.2052Z" fill="#FAF7F5" stroke="black" strokeWidth="3" />
      <path d="M68.1447 38.5027H34.82C32.5395 38.5027 30.6908 36.8319 30.6908 34.7709V26.2711H72.2736V34.7709C72.2739 36.8319 70.4252 38.5027 68.1447 38.5027Z" fill="#A3A3A3" stroke="black" strokeWidth="3" />
      <path d="M74.4024 26.2711H28.5605C25.788 26.2711 23.5405 24.2399 23.5405 21.7342V2.04474H79.4223V21.7342C79.4223 24.2399 77.1749 26.2711 74.4024 26.2711Z" fill={mainColor} stroke="black" strokeWidth="3" />
    </svg>
  )
}

export default CreamIconComponent;
