import React from "react";
import "./orders-panel.scss";
import Col from "react-bootstrap/Col";
import { CakeOrderDto } from "../../../../dto/cakeOrderDto";
import { CartShopsList } from "../../../home-page/cart-page/cart-panels/cartData/cart.data";
import { DeliveryTypeEnum } from "../../../../dto/cartItemDto";

interface Props {
  order: CakeOrderDto;
  index: number
}

const CustomerAndReceiverDataCol: React.FC<Props> = (props: Props) => {

  var { order, index } = props;

  return (
    <Col xl={{ span: 3 }} className="cell" key={index}>
      <p>
        <span className="medium">Dane klienta</span>
        {order.clientData.name}
        <br />
        {order.clientData.addressLineOne + ", " + order.clientData.addressCity + " " + order.clientData.addressPostalCode}
        <br />
        {order.clientData.nip ? <>NIP: {order.clientData.nip}
          <br /></> : null}
        <span style={{ fontWeight: 600 }}>&#9743;</span> {order.clientData.phone}
        <br />
        <span style={{ fontWeight: 300 }}>&#9993;</span> {order.clientData.email}
        {/* <br />
      <span className="medium">Info dodatkowe</span>
    {order.clientData.addressLineTwo} */}
      </p>
      {order.deliveryType === DeliveryTypeEnum.SelfRecive ?
        <>
          <p>
            <span className="medium">Dane odbiorcy</span>
            {order.receiverData.name === order.clientData.name ?
              "Klient odbierze zamówienie osobiście" :
              <>
                {order.receiverData.name}
                <br />
                {order.receiverData.phone}
                <br />
                {order.receiverData.trackingNumber}
              </>
            }
          </p>
          <p>
            <span className="medium">Dane lokalu</span>
            {CartShopsList[order.deliveryShop].streetAndNumber + ", " + CartShopsList[order.deliveryShop].city + " " + CartShopsList[order.deliveryShop].postCode}
            <br />
            {CartShopsList[order.deliveryShop].openTime[0]}
            <br />
            {CartShopsList[order.deliveryShop].openTime[1]}
          </p>
        </>
        :
        <p>
          <span className="medium">Dane odbiorcy</span>
          {order.receiverData.name}
          <br />
          {order.receiverData.phone}
          <br />
          {order.receiverData.addressLineOne + ", " + order.receiverData.addressCity + " " + order.receiverData.addressPostalCode}
          <br />
          {order.receiverData.trackingNumber}
        </p>
      }
      <p>
        <span className="medium">Info dodatkowe</span>
        {order.receiverData.addressLineTwo}
      </p>
    </Col>
  )
}

export default CustomerAndReceiverDataCol;