import React from "react";
import "./../../cart-page.scss";
import "./../cart-panel.scss";

import Form from "react-bootstrap/Form";
import { DeliveryPanelDto, DeliveryTypeEnum, ReceiverDataDto, ReceiverTypeEnum } from "../../../../../dto/cartItemDto";
import { selectDeliveryPanel, selectReceiverData } from "../../../../../redux/cartState/cartState.selectors";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setReceiverData } from "../../../../../redux/cartState/cartState.actions";
import { validateEmail } from "../../../../../tools/emailValidator";

interface Props {
  isValidationActive: boolean
}


const CartDataReceiverForm: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const receiverData = useSelector(selectReceiverData, shallowEqual) as ReceiverDataDto
  const deliveryPanel = useSelector(selectDeliveryPanel, shallowEqual) as DeliveryPanelDto;

  const { isValidationActive } = props;

  const setInputValue = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    dispatch(setReceiverData({ ...receiverData, [name]: value } as ReceiverDataDto));
  }

  return (
    <form onSubmit={e => { e.preventDefault(); }}>
      <div className="input-wrapper">
        <Form.Label>Imię odbiorcy</Form.Label>
        <Form.Control
          className="form-control-alternative"
          name="name"
          type="text"
          isValid={isValidationActive && !(!receiverData.name || 0 === receiverData.name.length)}
          isInvalid={isValidationActive && (!receiverData.name || 0 === receiverData.name.length)}
          placeholder="Imię odbiorcy"
          value={receiverData.name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e)}
          required
        />
      </div>
      <div className="input-wrapper">
        <Form.Label>Nazwisko odbiorcy</Form.Label>
        <Form.Control
          className="form-control-alternative"
          name="surname"
          type="text"
          isValid={isValidationActive && !(!receiverData.surname || 0 === receiverData.surname.length)}
          isInvalid={isValidationActive && (!receiverData.surname || 0 === receiverData.surname.length)}
          placeholder="Nazwisko odbiorcy"
          value={receiverData.surname}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e)}
          required
        />
      </div>
      <div className="input-wrapper">
        <Form.Label>Ulica i nr. lokalu</Form.Label>
        <Form.Control
          className="form-control-alternative"
          name="streetAndHomeNumber"
          type="text"
          isValid={isValidationActive && !(!receiverData.streetAndHomeNumber || 0 === receiverData.streetAndHomeNumber.length)}
          isInvalid={isValidationActive && (!receiverData.streetAndHomeNumber || 0 === receiverData.streetAndHomeNumber.length)}
          placeholder="Ulica i nr. lokalu"
          value={receiverData.streetAndHomeNumber}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e)}
          required
        />
      </div>
      <div className="input-wrapper">
        <Form.Label>Kod pocztowy</Form.Label>
        <Form.Control
          className="form-control-alternative"
          name="postCode"
          type="text"
          isValid={isValidationActive && !(!receiverData.postCode || (5 !== receiverData.postCode.length && 6 !== receiverData.postCode.length))}
          isInvalid={isValidationActive && (!receiverData.postCode || (5 !== receiverData.postCode.length && 6 !== receiverData.postCode.length))}
          placeholder="Kod pocztowy"
          value={receiverData.postCode}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e)}
          required
        />
      </div>
      <div className="input-wrapper">
        <Form.Label>Miasto</Form.Label>
        <Form.Control
          className="form-control-alternative"
          name="city"
          type="text"
          isValid={isValidationActive && !((!receiverData.city || 0 === receiverData.city.length) || ((receiverData.receiverType === ReceiverTypeEnum.Client && deliveryPanel.deliveryType === DeliveryTypeEnum.Delivery) && !['Wrocław', 'Wroclaw', "WROCŁAW", "WROCLAW", 'wrocław', 'wroclaw'].some(x => x === receiverData.city)))}
          isInvalid={isValidationActive && ((!receiverData.city || 0 === receiverData.city.length) || ((receiverData.receiverType === ReceiverTypeEnum.Client && deliveryPanel.deliveryType === DeliveryTypeEnum.Delivery) && !['Wrocław', 'Wroclaw', "WROCŁAW", "WROCLAW", 'wrocław', 'wroclaw'].some(x => x === receiverData.city)))}
          placeholder="Miasto"
          value={receiverData.city}
          readOnly={true}
          required
        />
      </div>
      <div className="input-wrapper">
        <Form.Label>Telefon odbiorcy</Form.Label>
        <Form.Control
          className="form-control-alternative"
          name="phone"
          type="text"
          isValid={isValidationActive && !(!receiverData.phone || receiverData.phone.length < 6 || receiverData.phone.length > 17)}
          isInvalid={isValidationActive && (!receiverData.phone || receiverData.phone.length < 6 || receiverData.phone.length > 17)}
          placeholder="Telefon odbiorcy"
          value={receiverData.phone}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e)}
          required
        />
      </div>
      <div className="input-wrapper">
        <Form.Label>Email odbiorcy</Form.Label>
        <Form.Control
          className="form-control-alternative"
          name="email"
          type="email"
          isValid={isValidationActive && !(!receiverData.email || 0 === receiverData.email.length || !validateEmail(receiverData.email))}
          isInvalid={isValidationActive && (!receiverData.email || 0 === receiverData.email.length || !validateEmail(receiverData.email))}
          placeholder="Email odbiorcy"
          value={receiverData.email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e)}
          required
        />
      </div>
    </form>
  )
}

export default CartDataReceiverForm;