export class RoutePath {
  [key: string]: any;

  // static EMBED_PANEL: string = '/a/panel/embed';
  // static PYTANIA_PANEL: string = '/a/panel/inputs';
  // static PRODUKTY_PANEL: string = '/a/panel/outputs';
  // static KALIBRACJA_PANEL: string = '/a/panel/calibration';
  // static PROJECTSETTINGS_PANEL: string = '/a/panel/project-settings';

  static LEADS_PANEL: string = '/a/panel/leads';
  static ORDERS_PANEL: string = '/a/panel/orders';
  static HOME_PANEL: string = '/a/panel';

  // static SUBSCRIPTION_SETTINGS: string = '/a/settings/subscription';
  // static PERSONAL_DATA_SETTINGS: string = '/a/settings/personal-data';
  // static CONTACT_SETTINGS: string = '/a/settings/contact';
  // static REALIZECOUPON_SETTINGS: string = '/a/settings/realize-coupon'
  // static HOME_SETTINGS: string = '/a/settings';
  
  // static HOME_PREVIEW: string = '/a/preview/:paramSuggestlyId';

  static HOME_PAYMENTCHECKOUT: string = '/a/payment-checkout';

  static AUTHORIZED: string = '/a';

  static CONFIRMEMAIL: string = '/confirm-email/:leadId'

  static SIGNUP: string = '/sign-up';
  static SIGNIN: string = '/sign-in';
  static RESETPASSWORD: string = '/reset-password';
  
  // static BLOG: string = '/asystent/jak-wybrac-dobra-kawe';

  static DELIVERYPOINTS: string = '/punkty-odbioru';
  static CART: string = '/koszyk';
  static KREATOR: string = '/kreator';

  static STANDARD: string = '/oferta/torty-standardowe';

  static PRODUCT: string = '/oferta/:offerType/:productName';

  // static STANDARDPRODUCT: string = '/oferta/torty-standardowe/:productName';
  // static OCCASIONALPRODUCT: string = '/oferta/torty-okolicznosciowe/:productName';
  // static BUSINESSPRODUCT: string = '/oferta/torty-dla-firm/:productName';

  static OKOLICZNOSCIOWE: string = '/oferta/torty-okolicznościowe';
  static DLAFIRM: string = '/oferta/torty-dla-firm';
  static OFERTA: string = '/oferta';
  static PAYMENT_STATUS: string = '/status-platnosci/:status';
  

  static RULES: string = '/warunki-uzytkowania';

  static HOME_start: string = '#start';
  static HOME_integracja: string = '#integracja';
  static HOME_cennik: string = '#cennik';
  static HOME_kontakt: string = '#kontakt';

  static HOME: string = '/';
}