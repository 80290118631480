import React from "react";

interface Props {
  mainColor: string,
}

const SugarMassIconComponent: React.FC<Props> = (props: Props) => {
  const { mainColor } = props;

  return (
    <svg width="78" height="52" viewBox="0 0 78 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M74.662 49.3918C53.8123 50.8877 32.9628 51.0685 12.1114 49.9324C9.24435 49.7789 6.37889 49.5981 3.51163 49.3918C1.26013 36.0367 0.951426 22.6799 2.58552 9.32309C2.84474 7.20474 3.15345 5.08458 3.51163 2.96442C27.2284 1.26211 50.9452 1.26211 74.662 2.96442C77.2715 18.4397 77.2715 33.9165 74.662 49.3918Z" fill={mainColor} />
      <path d="M12.1109 49.9325C9.24377 49.7789 6.37832 49.5981 3.51105 49.3918C1.25955 36.0367 1.12123 16.7534 2.75532 3.39661L11.7554 2.39661C11.7554 2.39661 8.32785 38.0101 12.1109 49.9325Z" fill="black" fillOpacity="0.15" />
      <path d="M75.8811 40.4269C75.5724 43.4151 75.1648 46.4034 74.6616 49.3916C53.8119 50.8875 32.9624 51.0683 12.1111 49.9323C9.244 49.7787 6.37854 49.5979 3.51128 49.3916C3.00811 46.4034 2.60045 43.4151 2.29175 40.4269C4.99858 40.6144 7.70541 40.78 10.4122 40.9249C32.2341 42.1017 54.0575 41.9347 75.8811 40.4269Z" fill="#E7A561" />
      <path d="M12.1111 49.9326C9.244 49.779 6.37854 49.5982 3.51128 49.3919C3.00811 46.4037 2.60045 43.4154 2.29175 40.4272C4.99858 40.6147 7.70541 40.7803 10.4122 40.9253C10.7551 44.2357 11.2941 47.3571 12.1111 49.9326V49.9326Z" fill="#E49542" />
      <path fillRule="evenodd" clipRule="evenodd" d="M63.568 0.896939H57.4406H52.6196H49.1551L46.2458 0.896777H31.0353H26.214H12.7516L9.84156 0.89661H6.10456C1.5008 0.896939 0.755127 4.29268 0.755127 8.89661C0.755127 12.2407 0.498743 19.1993 0.498743 19.1993C-0.00622919 29.2894 0.59622 39.5128 2.29606 49.5963C2.39058 50.157 2.85627 50.5803 3.42338 50.621C15.2555 51.4706 27.1711 51.8953 39.0866 51.8953C51.0021 51.8953 62.9176 51.4706 74.7497 50.621C75.3169 50.5803 75.7825 50.157 75.8771 49.5963C76.983 43.0389 77.6307 36.3896 77.8023 29.8328C77.82 29.1522 77.2827 28.586 76.6021 28.5681C75.9239 28.5525 75.3553 29.0875 75.3374 29.7682C75.2549 32.9192 75.0596 36.0924 74.7552 39.2662C51.0805 40.8554 27.0926 40.8554 3.41959 39.2664C2.83621 33.1655 2.65819 27.0473 2.88783 20.9726L2.89349 20.7327L3.25513 5.39661C3.65047 2.23384 10.1452 2.80109 14.4504 3.17712C15.59 3.27665 16.5762 3.36279 17.2553 3.36279H24.9376H57.2551C58.8772 3.36279 72.11 2.3966 74.2551 3.39661C75.9182 4.17192 75.2904 20.7209 75.3032 21.5189C75.3414 22.6347 75.3673 23.7599 75.3782 24.8827C75.3847 25.5595 75.9354 26.1037 76.6107 26.1037H76.6228C77.3037 26.0972 77.8503 25.5399 77.8437 24.8591C77.8296 23.3813 77.8628 17.3561 77.8023 15.8966L77.8437 9.89661L77.7551 4.39661C77.7551 -0.207323 68.1718 0.896939 63.568 0.896939ZM74.4941 41.7553C62.742 42.5346 50.9143 42.9253 39.0864 42.9253C27.2593 42.9253 15.4326 42.5346 3.68244 41.7554C3.92835 43.9178 4.22226 46.0773 4.56992 48.2305C27.4791 49.8297 50.6947 49.8297 73.6039 48.2305C73.9514 46.0789 74.2476 43.9182 74.4941 41.7553Z" fill="#201D0D" />
    </svg>

  )
}

export default SugarMassIconComponent;
